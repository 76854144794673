import React from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";

import SidebarItem from "components/SidebarItem";

import { getFilteredEnrolmentUpdate } from "helpers/enrolment";

export default function EnrolmentItem({
  currentStoreData,
  store,
  ...extraProps
}) {
  const params = useParams();
  const selected =
    currentStoreData && currentStoreData.client_id === store.client_id;

  const {
    dropouts: male_dropouts,
    newcomers: male_newcomers,
  } = getFilteredEnrolmentUpdate({
    enrolmentUpdate: store,
    kinds: ["male"],
  });

  const {
    dropouts: female_dropouts,
    newcomers: female_newcomers,
  } = getFilteredEnrolmentUpdate({
    enrolmentUpdate: store,
    kinds: ["female"],
  });

  return (
    <SidebarItem
      {...extraProps}
      store={store}
      selected={selected}
      id={store.client_id}
      key={store.client_id}
      title={
        <FormattedMessage
          id="EnrolmentItem.updatedEnrolment"
          defaultMessage="Updated enrolment"
        />
      }
      subTitle={
        <>
          <FormattedMessage id="Common.newcomers" defaultMessage="Newcomers" />:{" "}
          {male_newcomers /* + disabled_male_newcomers*/}(m){" "}
          {female_newcomers /* + disabled_female_newcomers*/}(f){" "}
          <FormattedMessage id="Common.dropouts" defaultMessage="Dropouts" />:{" "}
          {male_dropouts /* + disabled_male_dropouts*/}(m){" "}
          {female_dropouts /* + disabled_female_dropouts*/}(f)
        </>
      }
      to={`/school/${params.schoolId}/info/${store.client_id}`}
    ></SidebarItem>
  );
}
