import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

import { ModuleBody, InlineLoading } from "@wfp/ui";

import { checkCol } from "./Utils";
import Table from "components/CountryAdmin/CountryAdminContent/Table/Table";
import Filtering from "components/CountryAdmin/CountryAdminContent/Table/Filtering";

import { RootState } from "data-handler/rootReducer";
import { getCurrentUser } from "data-handler/ducks/auth";

import "./_content.scss";
import { getIsSchoolsFetching } from "data-handler/ducks/schools";

type Adm = {
  id: number;
  name: string;
};

type Authority = {
  id: number;
  name: string;
};

type Country = {
  id: number;
  name: string;
};

type DistributionReport = {
  id: number;
  comet_id: number;
  comet_code: string;
};

type School = {
  id: number;
  name: string;
  adm_1: Adm;
  adm_2: Adm;
  adm_3: Adm;
  adm_4: Adm;
  aggregates_only: boolean;
  authorities: Authority[];
  country: Country;
  created_at: string;
  distribution_reports: DistributionReport[];
};

type Filter = {
  id: string;
  value: string;
};

type CompactSchoolsProps = {
  register: (data: string) => void;
  setValue: (
    key: string,
    data: School[],
    config: { shouldDirty: boolean }
  ) => void;
  selectedSchoolsIds: { current: { [schoolId: string]: School } };
  schools: School[];
  onLoadPageDispatch: (
    size: number,
    idx: number,
    filters: Filter[],
    ordering: string
  ) => void;
  getSchools: (
    state: RootState
  ) => { count: number; ids: number[]; results: { [id: number]: School } };
  showSecondaryButton: boolean;
};

const CompactSchools: React.ComponentType<CompactSchoolsProps> = ({
  register = undefined,
  setValue = undefined,
  selectedSchoolsIds,
  schools = [],
  onLoadPageDispatch,
  getSchools,
  showSecondaryButton = true,
}) => {
  const intl = useIntl();
  const allSchools = useSelector(getSchools);
  const currentUser = useSelector(getCurrentUser);

  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const isFetching = useSelector(getIsSchoolsFetching);
  const [selectedAll, setSelectedAll] = useState(false);
  const [filtersState, setFilters] = useState<Filter[]>([]);

  const usingForm = register && setValue;

  useEffect(() => {
    if (usingForm) {
      register!("schools");
    }
  }, [register, usingForm]);

  const { count = 0, ids = [] } = allSchools;

  const data = schools.concat(
    ids
      .filter((id) => !schools.find((school) => school.id === id))
      .map((id) => allSchools.results[id])
  );

  const filterFields = [
    {
      name: "name",
      label: intl.formatMessage({
        id: "CountryAdmin.name",
        defaultMessage: "Name",
      }),
    },
    {
      name: "adm_4_name",
      label: intl.formatMessage({
        id: "CountryAdmin.adminFour",
        defaultMessage: "Admin 4",
      }),
    },
    {
      name: "adm_3_name",
      label: intl.formatMessage({
        id: "CountryAdmin.adminThree",
        defaultMessage: "Admin 3",
      }),
    },
    {
      name: "adm_2_name",
      label: intl.formatMessage({
        id: "CountryAdmin.adminTwo",
        defaultMessage: "Admin 2",
      }),
    },
    {
      name: "adm_1_name",
      label: intl.formatMessage({
        id: "CountryAdmin.adminOne",
        defaultMessage: "Admin 1",
      }),
    },
  ];

  const filterProps = {
    fields: filterFields,
    onSubmit: (e: { [key: string]: string }) => {
      const filters = Object.keys(e).map((key) => ({ id: key, value: e[key] }));
      setFilters(filters);
      loadPage(pageSize, 1, "", filters);
    },
    defaults: filtersState.reduce((a, x) => ({ ...a, [x.id]: x.value }), {}),
    secondaryButton: {
      onSubmit: (e: { [key: string]: string }) => {
        const filters = Object.keys(e).map((key) => ({
          id: key,
          value: e[key],
        }));

        setFilters(filters);
        onLoadPageDispatch(99999, 1, filters, "");
      },
      name: intl.formatMessage({
        id: "CountryAdmin.selectAll",
        defaultMessage: "Select all",
      }),
      secondaryName: intl.formatMessage({
        id: "CountryAdmin.unselectAll",
        defaultMessage: "Unselect all",
      }),
      secondaryButtonState: {
        setSelectedAll: (e: boolean) => {
          setSelectedAll(e);
        },
        selectedAllState: selectedAll,
      },
      selectedSchoolsIds: selectedSchoolsIds,
    },
    showSecondaryButton: showSecondaryButton,
  };

  const columns = useMemo(() => {
    const allColumns = [
      checkCol,
      {
        Header: intl.formatMessage({
          id: "CountryAdmin.name",
          defaultMessage: "Name",
        }),
        accessor: "name",
      },
      {
        Header: intl.formatMessage({
          id: "CountryAdmin.profile",
          defaultMessage: "Profile",
        }),
        accessor: "profile.name",
      },

      {
        Header: intl.formatMessage({
          id: "CountryAdmin.CometCodes",
          defaultMessage: "Comet Codes",
        }),
        accessor: "distribution_reports",
        Cell: ({
          cell: {
            row: { original: school },
          },
        }: {
          cell: { row: { original: School } };
        }) => {
          const distributionReports = school.distribution_reports || [];
          return distributionReports.map((r) => r.comet_code).join(", ");
        },

        disableSortBy: true,
      },
      {
        Header: intl.formatMessage({
          id: "CountryAdmin.adminFour",
          defaultMessage: "Admin 4",
        }),
        accessor: "adm_4.name",
      },
      {
        Header: intl.formatMessage({
          id: "CountryAdmin.adminThree",
          defaultMessage: "Admin 3",
        }),
        accessor: "adm_3.name",
        disableSortBy: true,
      },
      {
        Header: intl.formatMessage({
          id: "CountryAdmin.adminTwo",
          defaultMessage: "Admin 2",
        }),
        accessor: "adm_2.name",
        disableSortBy: true,
      },
      {
        Header: intl.formatMessage({
          id: "CountryAdmin.adminOne",
          defaultMessage: "Admin 1",
        }),
        accessor: "adm_1.name",
        disableSortBy: true,
      },
    ];
    if (currentUser.is_admin) {
      allColumns.push({
        Header: intl.formatMessage({
          id: "CountryAdmin.country",
          defaultMessage: "Country",
        }),
        accessor: "country.name",
      });
    }
    return allColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadPage = (
    size: number,
    idx: number,
    ordering: string,
    filters = filtersState
  ) => {
    setPageSize(size);
    setPageNum(idx);
    onLoadPageDispatch(size, idx, filters, ordering);
  };

  const setSelected = (ids: { string: true }) => {
    // Take the selectedSchoolsIds from React table and match them to "Data"
    const selectedRowObjectList = Object.keys(ids)
      .map((idx) => data[parseInt(idx)])
      .reduce((a, x) => {
        return { ...a, [x.id]: x };
      }, {});
    // These pages are used to repopulate the tables initial values to
    // populate the previously selected schools

    selectedSchoolsIds.current = {
      ...selectedSchoolsIds.current,
      ...selectedRowObjectList,
    };
    // Get the unselected schools from the current page and remove them from selectedSchoolsIds
    const currentPageUnselectedSchools = data
      .filter((item, index) => {
        return !Object.keys(ids).includes(index.toString());
      })
      .map((item) => item.id);
    currentPageUnselectedSchools.forEach((item) => {
      if (item in selectedSchoolsIds.current) {
        delete selectedSchoolsIds.current[item];
      }
    });

    if (usingForm) {
      // Flatten the pages of schools to one array of schools
      const selectedSchoolArray = Object.keys(selectedSchoolsIds.current).map(
        (key) => selectedSchoolsIds.current[key]
      );
      // Set value of the "schools" registered component
      setValue!("schools", selectedSchoolArray, { shouldDirty: false });
    }
  };

  const getCurrentPageSelectedRows = () => {
    let selectedRows: { [id: number]: boolean } = {};
    data.forEach((item, index) => {
      if (
        Object.keys(selectedSchoolsIds.current).includes(item.id.toString())
      ) {
        selectedRows[index] = true;
      }
    });
    return selectedRows;
  };
  const tableProps = {
    columns,
    data,
    pageNum,
    pageSize,
    totalItems: count,
    loadPage,
    setSelected,
    initialState: {
      selectedRowIds:
        selectedSchoolsIds.current && !selectedAll
          ? getCurrentPageSelectedRows()
          : selectedSchoolsIds.current && selectedAll
          ? ids
          : {},
    },
  };

  return (
    <div className="country-admin-main-content schools-table">
      <div className="compact-schools-table">
        <ModuleBody>
          <div style={isFetching ? { display: "none" } : {}}>
            <Filtering {...filterProps} />
            <Table {...tableProps} />
          </div>
          <div
            className="inlineLoading"
            style={!isFetching ? { display: "none" } : {}}
          >
            <InlineLoading description="Loading..." />
          </div>
        </ModuleBody>
      </div>
    </div>
  );
};

export default CompactSchools;
