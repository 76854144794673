import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import bcryptjs from "bcryptjs";

import { Blockquote, Button, InlineLoading } from "@wfp/ui";

import { Link } from "react-router-dom";

import { getCurrentSchool } from "data-handler/ducks/schools";
import { LOGIN_LOCAL } from "data-handler/ducks/auth";
import { getLocalUserById } from "data-handler/ducks/localAuth";

import PageTitle from "components/PageTitle";
import CodeInput from "components/CodeInput";
import loginBackground from "images/IMG_5995.jpg";
import LoginWrapper from "./LoginWrapper";

import styles from "./login.module.scss";
import "./login.module.scss";
import { getCanUseOfflineMode } from "helpers/users";
import { setIsInitializationRequired } from "data-handler/ducks/initialization";

const LocalLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const auth = useSelector((state) => state.auth);
  const currentSchool = useSelector(getCurrentSchool);
  const [error, setError] = useState(false);

  const localUser = useSelector((state) =>
    getLocalUserById(state, params.username)
  );

  if (auth.user) {
    dispatch({ type: "LOGOUT_LOCAL" });
  }

  const { control, handleSubmit, errors } = useForm();

  const onSubmit = async (values) => {
    if (
      localUser &&
      localUser.passcode &&
      bcryptjs.compareSync(values.password, localUser.passcode)
    ) {
      dispatch({
        type: LOGIN_LOCAL,
        data: {
          ...localUser,
          id: params.username,
        },
      });
      dispatch(setIsInitializationRequired(true));
      /**
       * Redirect:
       *   - school users to the school's page
       *   - non-school users to the home page (so that they can select the school)
       */
      let redirectPath = `/school/${currentSchool?.id}`;
      if (getCanUseOfflineMode(localUser)) redirectPath = "/";
      history.push(redirectPath);
    } else {
      setError(true);
    }
  };

  return (
    <LoginWrapper
      rightSide={
        <img
          alt="School connect users looking at tablet devices"
          className={styles.loginContentImage}
          src={loginBackground}
        />
      }
      showBackLink
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          {error && (
            <>
              <Blockquote warning title="Login failed">
                <FormattedMessage
                  id="Login.pleaseTryAnotherCode"
                  defaultMessage="Please try again or use the online login"
                />
              </Blockquote>
              <br />
            </>
          )}
          <PageTitle>
            <span className={styles.subTitle}>
              {localUser?.other_names} {localUser?.last_name}
            </span>
          </PageTitle>
          <ErrorMessage errors={errors} name="username" />
          <Controller
            as={<CodeInput />}
            name="password"
            labelText={
              <FormattedMessage id="Login.code" defaultMessage="Code" />
            }
            helperText={
              <FormattedMessage
                id="Login.enterCode"
                defaultMessage="Enter your passcode"
              />
            }
            control={control}
          />
          <div className={styles.submitWrapper}>
            <div className={styles.button}>
              <Button type="submit">
                <FormattedMessage id="Login.login" defaultMessage="Login" />
              </Button>
              {
                // TODO: Show InlineLoading when loading
                false && <InlineLoading />
              }
            </div>
            <Link
              to={`/corporateLogin/${params.username}`}
              className={styles.password}
            >
              <FormattedMessage
                id="Login.useOnlineLogin"
                defaultMessage="Use online login"
              />
            </Link>
          </div>
        </div>
      </form>
    </LoginWrapper>
  );
};

export default LocalLogin;
