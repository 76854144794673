import React, { useEffect, useState, useRef } from "react";
import { useParams, useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Controller, FormProvider, useForm } from "react-hook-form";

import {
  Module,
  ModuleBody,
  ModuleHeader,
  ModuleFooter,
  Wrapper,
  TextInput,
  Button,
  InlineLoading,
  Checkbox,
  colors,
} from "@wfp/ui";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";

import { countriesDuck } from "data-handler/ducks/countries";
import { CountryAdminLabel, selectContainerStyles } from "SCConstants";
import {
  requestPutDetailSchoolProfile,
  getSchoolProfileFetching,
  requestDetailSchoolProfile,
  requestPostNewSchoolProfile,
  requestDeleteSchoolProfile,
  getSchoolProfiles,
} from "data-handler/ducks/schoolProfile";
import { regionsDuck } from "data-handler/ducks/regions";
import {
  requestCountryAdminApprovers,
  getIsUserFetching,
  getUserApprovers,
  getUserSchoolGroupAdmins,
} from "data-handler/ducks/users";
import { getCurrentUser } from "data-handler/ducks/auth";
import commoditiesDuck from "data-handler/ducks/commodities";
import SchoolModal from "./SchoolModal";
import ConfirmationModal from "components/CountryAdmin/CountryAdminContent/ConfirmationModal";
import CurrentlySelectedCommodities from "./CurrentlySelectedCommodities";
import AllAvailableCommodities from "./AllAvailableCommodities";

const CountryAdminDetailedSchoolProfileTemplate = ({
  createNewProfile = false,
}) => {
  const dispatch = useDispatch();
  const [confirmationModalState, setConfirmationModalState] = useState(false);
  const [schoolModalState, setSchoolModalState] = useState(false);

  const [modalType, setModalType] = useState();
  const commodities = useSelector(commoditiesDuck.getList);
  const commoditiesFetching = useSelector(commoditiesDuck.isFetching());
  const schoolProfileFetching = useSelector(getSchoolProfileFetching);
  const userFetching = useSelector(getIsUserFetching);
  const allApprovers = useSelector(getUserApprovers);
  const allASchoolGroupAdmins = useSelector(getUserSchoolGroupAdmins);
  const regions = useSelector(regionsDuck.getList);
  const regionsFetching = useSelector(regionsDuck.isFetching());
  const countries = useSelector(countriesDuck.getList);
  const countriesIsFetching = useSelector(countriesDuck.isFetching());
  const currentUser = useSelector(getCurrentUser);
  const storeCommodities = Object.values(useSelector(commoditiesDuck.getIndex));

  // Pagination and filters for the selected commodities table
  const [selectedPageNum, setSelectedPageNum] = useState(1);
  const [selectedPageSize, setSelectedPageSize] = useState(5);
  const [selectedFiltersState, setSelectedFiltersState] = useState([]);

  // Pagination and filters for the selected commodities table
  const [availablePageNum, setAvailablePageNum] = useState(1);
  const [availablePageSize, setAvailablePageSize] = useState(5);
  const [availableFiltersState, setAvailableFiltersState] = useState([]);

  // Use refresh key to force the commodities table
  // to re-render when changing the commodities lists
  const [refreshKey, setRefreshKey] = useState(new Date().valueOf());

  const submitBtnRef = useRef();
  const history = useHistory();

  const { schoolProfileId } = useParams();

  const currentSchoolProfile = useSelector(getSchoolProfiles).results[
    schoolProfileId
  ];

  const methods = useForm();
  const { handleSubmit, register, errors, watch, setValue, control } = methods;
  const selectedCountry = watch("country");

  const updatedCommodities = useRef(currentSchoolProfile?.commodities || []);

  useEffect(() => {
    if (
      currentUser.is_admin &&
      countries.length === 0 &&
      !countriesIsFetching
    ) {
      dispatch(countriesDuck.fetchList());
    }
  }, [currentUser, countries, countriesIsFetching, dispatch]);

  useEffect(() => {
    if (!createNewProfile) {
      if (!currentSchoolProfile) {
        dispatch(requestDetailSchoolProfile(schoolProfileId, history));
      } else if (!selectedCountry) {
        // Set the country value if it's not loaded from the school profile
        setValue("country", {
          value: currentSchoolProfile?.country?.id,
          label: currentSchoolProfile?.country?.name,
          adm0_id: currentSchoolProfile?.country?.adm0_id,
        });
        updatedCommodities.current = currentSchoolProfile?.commodities || [];
        setRefreshKey(new Date().valueOf());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolProfileId, createNewProfile, currentSchoolProfile, dispatch]);

  useEffect(() => {
    if (commodities.length === 0 && !commoditiesFetching) {
      dispatch(commoditiesDuck.fetchList());
    }

    if (!userFetching) {
      dispatch(requestCountryAdminApprovers());
    }

    // Adding commodities creates infinite loop of dispatch.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userFetching]);

  useEffect(() => {
    if (selectedCountry?.adm0_id) {
      if (selectedCountry?.adm0_id !== currentSchoolProfile?.country?.adm0_id) {
        setValue("region", null);
      }
      if (!regionsFetching) {
        dispatch(
          regionsDuck.fetchList("DEFAULT", {
            adm0_id: selectedCountry?.adm0_id,
            limit: 99999,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedCountry]);

  // Get all Available options for the select functions
  // filter out already selected commodities
  let availableCommodities = storeCommodities.filter(
    (item) =>
      !updatedCommodities.current.find((selected) => selected.id === item.id)
  );

  const allAvailableApprovers = allApprovers.map((approver) => ({
    value: approver.id,
    label: approver.user.email,
  }));

  const allAvailableSchoolGroupAdmins = allASchoolGroupAdmins.map(
    (school_group_admin) => ({
      value: school_group_admin.id,
      label: school_group_admin.user.email,
    })
  );

  const allAvailableRegions = regions.map((region) => ({
    value: region.id,
    label: region.name,
  }));

  allAvailableRegions.unshift({
    value: null,
    label: "None",
  });

  const onSubmit = (submittedData) => {
    if (modalType === "delete") {
      // Send delete request
      dispatch(requestDeleteSchoolProfile(schoolProfileId, history));
    } else {
      const data = {
        name: submittedData.name,
        country: submittedData.country?.value,
        region: submittedData?.region?.value
          ? submittedData.region.value
          : null,
        commodities: submittedData.commodities.map((commodity) => commodity.id),
        approvers: submittedData.approvers
          ? submittedData.approvers.map((approver) => approver.value)
          : [],
        school_group_admins: submittedData.schoolGroupAdmins
          ? submittedData.schoolGroupAdmins.map(
              (schoolGroupAdmin) => schoolGroupAdmin.value
            )
          : [],
        enable_cash_purchases: submittedData.enable_cash_purchases || false,
        enable_take_home_rations:
          submittedData.enable_take_home_rations || false,
        enable_student_attendance:
          submittedData.enable_student_attendance || false,
      };
      if (createNewProfile) {
        dispatch(requestPostNewSchoolProfile(data, history));
      } else {
        dispatch(
          requestPutDetailSchoolProfile(data, currentSchoolProfile.id, history)
        );
      }
    }
  };

  if (!createNewProfile && (!currentSchoolProfile || schoolProfileFetching)) {
    return (
      <div className="inlineLoading">
        <InlineLoading />
      </div>
    );
  }

  /* Set style for when errors contain an error set by the register or rule prop within the controller.
    also Recreated the wfp--react-select-container as this was overriding the styles 
     which prevented changing the border colour in the event of an error.
  */
  const invalidStyle = selectContainerStyles.invalid;

  const standardStyleController = (disabled) => {
    return {
      control: (styles) => ({
        ...styles,
        border: "1px solid #8ca4b5",
        opacity: disabled ? "0.5" : "",
      }),
    };
  };

  // In order to submit form data. Hidden button is created within the form.
  // As the primary button within the modal cannot be set to type="submit"
  // When the modal submit button is clicked. This subsequently clicks the designated form submit button.
  const onConfirmationModalSubmit = () => {
    setConfirmationModalState(false);
    if (modalType === "delete") {
      onSubmit();
    } else {
      submitBtnRef.current.click();
    }
  };

  const openConfirmationModal = (type) => {
    setModalType(type);
    setConfirmationModalState(true);
  };

  const confirmationModalProps = {
    confirmationModalState,
    modalType,
    setConfirmationModalState,
    onConfirmationModalSubmit,
    submitMessage: (
      <FormattedMessage
        id="countyAdmin.schoolProfileSubmit"
        defaultMessage="Are you sure you want to {type} the data of {name}?"
        values={{
          name: currentSchoolProfile?.name ? (
            currentSchoolProfile.name
          ) : (
            <FormattedMessage
              id="countyAdmin.newProfile"
              defaultMessage="this new profile"
            />
          ),
          type: modalType === "delete" ? "delete" : "submit",
        }}
      />
    ),
  };

  const schoolModalProps = {
    name: currentSchoolProfile?.name,
    schools: currentSchoolProfile?.schools,
    schoolProfileId,
    setSchoolModalState,
    schoolModalState,
    countryId: selectedCountry?.value,
  };

  // List of inputs for select components
  const inputList = [
    {
      name: "approvers",
      defaultValue: !currentSchoolProfile?.approvers
        ? null
        : currentSchoolProfile?.approvers?.map((approver) => ({
            value: approver.id,
            label: approver.user.email,
          })),
      isMulti: true,
      options: allAvailableApprovers,
    },
    {
      name: "schoolGroupAdmins",
      defaultValue: !currentSchoolProfile?.school_group_admins
        ? null
        : currentSchoolProfile?.school_group_admins?.map(
            (schoolGroupAdmin) => ({
              value: schoolGroupAdmin.id,
              label: schoolGroupAdmin.user.email,
            })
          ),
      isMulti: true,
      options: allAvailableSchoolGroupAdmins,
    },
    {
      name: "country",
      required: true,
      defaultValue: !currentSchoolProfile?.country
        ? createNewProfile && currentUser?.user_role === "country_administrator"
          ? {
              value: currentUser?.country?.id,
              label: currentUser?.country?.name,
              adm0_id: currentUser?.country?.adm0_id,
            }
          : null
        : {
            value: currentSchoolProfile?.country?.id,
            label: currentSchoolProfile?.country?.name,
            adm0_id: currentSchoolProfile?.country?.adm0_id,
          },
      isMulti: false,
      options: countries?.map((country) => ({
        label: country.name,
        value: country.id,
        adm0_id: country.adm0_id,
      })),
      disabled: currentUser?.user_role === "country_administrator",
    },
    {
      name: "region",
      required: false,
      defaultValue: !currentSchoolProfile?.region
        ? null
        : {
            value: currentSchoolProfile?.region.id,
            label: currentSchoolProfile?.region.name,
          },
      isMulti: false,
      options: allAvailableRegions,
      disabled: !selectedCountry,
    },
  ];

  const addCommodityHandler = (newCommodity) => {
    updatedCommodities.current = [...updatedCommodities.current, newCommodity];
    setRefreshKey(new Date().valueOf());
  };

  const removeCommodityHandler = (commodity) => {
    const newCommodities = updatedCommodities.current.filter(
      (item) => item.id !== commodity.id
    );
    updatedCommodities.current = newCommodities;
    setRefreshKey(new Date().valueOf());
  };

  return (
    <>
      <div className="country-admin-main-content-center">
        <Wrapper
          pageWidth="sm"
          spacing="md"
          background="lighter"
          className="table"
        >
          <Module>
            <ModuleHeader className="country-admin-school-profile-form-row">
              <Link
                exact="true"
                to={`/countryAdmin/schoolProfiles/`}
                style={{ paddingRight: "10px" }}
              >
                <FontAwesomeIcon icon={faChevronLeft} size="lg" />
              </Link>
              {currentSchoolProfile?.name}
            </ModuleHeader>
            <div className="country-admin-school-profile-form">
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <ModuleBody>
                    <div className="country-admin-school-profile-form-row">
                      <Controller
                        as={
                          <TextInput
                            id="name_input"
                            labelText={
                              CountryAdminLabel.find((e) => e.name === "name")
                                .label
                            }
                            invalid={errors?.name}
                          />
                        }
                        name={"name"}
                        defaultValue={currentSchoolProfile?.name}
                        inputRef={register({
                          required: true,
                        })}
                      />
                    </div>
                    {!createNewProfile && (
                      <div className="country-admin-school-profile-form-row">
                        <label className="wfp--label">
                          {
                            CountryAdminLabel.find(
                              (e) => e.name === "countryAdmin.lastUpdated"
                            ).label
                          }
                        </label>
                        <label className="wfp--label">
                          {currentSchoolProfile?.date_updated.substring(0, 10)}
                        </label>
                      </div>
                    )}
                    {inputList.map(
                      ({
                        name,
                        defaultValue,
                        options,
                        isMulti,
                        required,
                        disabled = false,
                      }) => (
                        <div
                          key={name}
                          className="country-admin-school-profile-form-row"
                        >
                          <label
                            className="wfp--label"
                            style={{
                              opacity: disabled ? "0.5" : "",
                            }}
                          >
                            {
                              //React-Select does not have a label text property.
                              CountryAdminLabel.find(
                                (e) => e.name === `countryAdmin.${name}`
                              )?.label
                            }
                          </label>
                          <Controller
                            as={
                              <Select
                                styles={
                                  errors[name]
                                    ? invalidStyle
                                    : standardStyleController(disabled)
                                }
                                className="react-select-container"
                                classNamePrefix="react-select"
                                defaultValue={defaultValue}
                                options={options}
                                isDisabled={disabled}
                                isMulti={isMulti}
                                invalid={errors[name]}
                              />
                            }
                            defaultValue={defaultValue}
                            name={name}
                            rules={{ required: required }}
                          />
                        </div>
                      )
                    )}
                    <div
                      className="country-admin-school-profile-form-row"
                      style={{ color: colors["support-01"].hex }}
                    >
                      {
                        // Only display if there are errors.
                        // Unable to use useForm message within "errors" variable as this does not support our translation method
                        Object.keys(errors).length === 0 ? (
                          ""
                        ) : (
                          <FormattedMessage
                            id="countryAdmin.schoolProfileErrors"
                            defaultMessage="Please enter a valid value within the highlighted fields"
                          />
                        )
                      }
                    </div>

                    <div className="country-admin-user-form-row">
                      <label
                        className="wfp--label"
                        htmlFor="enable_cash_purchases"
                      >
                        <FormattedMessage
                          id="enable_cash_purchases"
                          defaultMessage="Enable Cash Purchases"
                        />
                      </label>
                      <Controller
                        render={({ onChange, value }) => (
                          <Checkbox
                            key="enable_cash_purchases"
                            className="checkbox"
                            id="enable_cash_purchases"
                            onChange={(e) => {
                              onChange(e.target.checked);
                            }}
                            checked={value}
                          />
                        )}
                        name={"enable_cash_purchases"}
                        defaultValue={
                          currentSchoolProfile?.enable_cash_purchases
                        }
                        control={control}
                      />
                    </div>
                    <div className="country-admin-user-form-row">
                      <label
                        className="wfp--label"
                        htmlFor="enable_take_home_rations"
                      >
                        <FormattedMessage
                          id="enable_take_home_rations"
                          defaultMessage="Enable Take Home Rations"
                        />
                      </label>
                      <Controller
                        render={({ onChange, value }) => (
                          <Checkbox
                            key="enable_take_home_rations"
                            className="checkbox"
                            id="enable_take_home_rations"
                            onChange={(e) => {
                              onChange(e.target.checked);
                            }}
                            checked={value}
                          />
                        )}
                        name={"enable_take_home_rations"}
                        defaultValue={
                          currentSchoolProfile?.enable_take_home_rations
                        }
                        control={control}
                      />
                    </div>

                    <div className="country-admin-user-form-row">
                      <label
                        className="wfp--label"
                        htmlFor="enable_student_attendance"
                      >
                        <FormattedMessage
                          id="enable_student_attendance"
                          defaultMessage="Enable two attendances"
                        />
                      </label>
                      <Controller
                        render={({ onChange, value }) => (
                          <Checkbox
                            key="enable_student_attendance"
                            className="checkbox"
                            id="enable_student_attendance"
                            onChange={(e) => {
                              onChange(e.target.checked);
                            }}
                            checked={value}
                          />
                        )}
                        name={"enable_student_attendance"}
                        defaultValue={
                          currentSchoolProfile?.enable_student_attendance
                        }
                        control={control}
                      />
                    </div>

                    <ConfirmationModal {...confirmationModalProps} />
                    <SchoolModal {...schoolModalProps} />
                  </ModuleBody>

                  {/* Fake Button to submit form data 
                              Check onConfirmationModalSubmit
                              That function controls this button.                  
                              */}
                  <button
                    type="submit"
                    className="hidden-btn"
                    ref={submitBtnRef}
                  />
                </form>
              </FormProvider>
              <ModuleHeader className="country-admin-school-profile-form-row">
                <FormattedMessage
                  id="countryAdmin.currentCommodities"
                  defaultMessage="Current Commodities"
                />
              </ModuleHeader>
              <ModuleBody>
                <CurrentlySelectedCommodities
                  key={refreshKey}
                  methods={methods}
                  initialSelectedCommodities={
                    updatedCommodities.current.length === 0
                      ? []
                      : updatedCommodities.current
                  }
                  setUpdatedCommodities={removeCommodityHandler}
                  pageNum={selectedPageNum}
                  setPageNum={setSelectedPageNum}
                  pageSize={selectedPageSize}
                  setPageSize={setSelectedPageSize}
                  filtersState={selectedFiltersState}
                  setFilters={setSelectedFiltersState}
                />
              </ModuleBody>

              <ModuleHeader className="country-admin-school-profile-form-row">
                <FormattedMessage
                  id="countryAdmin.allAvailableCommodities"
                  defaultMessage="All Available Commodities"
                />
              </ModuleHeader>
              <ModuleBody>
                <AllAvailableCommodities
                  key={refreshKey}
                  initialAvailableCommodities={
                    availableCommodities.length === 0
                      ? []
                      : availableCommodities
                  }
                  setUpdatedCommodities={addCommodityHandler}
                  pageNum={availablePageNum}
                  setPageNum={setAvailablePageNum}
                  pageSize={availablePageSize}
                  setPageSize={setAvailablePageSize}
                  filtersState={availableFiltersState}
                  setFilters={setAvailableFiltersState}
                />
              </ModuleBody>
            </div>
            <ModuleFooter className="country-admin-buttons">
              <div className="country-admin-delete-button">
                {!createNewProfile &&
                currentSchoolProfile?.schools?.length === 0 ? (
                  <Button
                    style={{
                      marginRight: 15,
                    }}
                    disabled={currentSchoolProfile?.schools?.length !== 0}
                    kind={"danger--primary"}
                    onClick={() => openConfirmationModal("delete")}
                  >
                    <FormattedMessage
                      id="schoolProfile.delete"
                      defaultMessage="Delete"
                    />
                  </Button>
                ) : !createNewProfile ? (
                  <Button
                    style={{
                      marginRight: 15,
                    }}
                    disabled={currentSchoolProfile?.schools?.length === 0}
                    kind={"secondary"}
                    onClick={() => setSchoolModalState(true)}
                  >
                    <FormattedMessage
                      id="schoolProfile.showSchools"
                      defaultMessage="Show Schools"
                    />
                  </Button>
                ) : undefined}
              </div>
              <div className="country-admin-submit-button">
                <Button
                  style={{
                    marginRight: 15,
                  }}
                  kind={"primary"}
                  onClick={() => openConfirmationModal("submit")}
                >
                  <FormattedMessage
                    id="Report.actionSubmit"
                    defaultMessage="Submit"
                  />
                </Button>
              </div>
            </ModuleFooter>
          </Module>
        </Wrapper>
      </div>
    </>
  );
};

export default CountryAdminDetailedSchoolProfileTemplate;
