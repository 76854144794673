import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Controller, FormProvider, useForm } from "react-hook-form";
import Select from "react-select";

import {
  Module,
  ModuleBody,
  ModuleHeader,
  Wrapper,
  InlineLoading,
  TextInput,
  Button,
  Checkbox,
  colors,
} from "@wfp/ui";

import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  getAllUsers,
  getIsUserFetching,
  requestCurrentUser,
  editUser,
} from "data-handler/ducks/users";
import { getCurrentUser } from "data-handler/ducks/auth";
import { countriesDuck } from "data-handler/ducks/countries";
import {
  getAllAdminSchools,
  requestAllAdminSchools,
  getIsSchoolsFetching,
} from "data-handler/ducks/schools";
import {
  requestCountryAuthorityTypes,
  requestEducationAuthorities,
  getCountryAuthorityTypes,
  getEducationAuthoritiesByAuthorityType,
} from "data-handler/ducks/authorityLevels";

import {
  textInputFields,
  selectFields,
  approverDesignations,
  schoolStaffRoles,
  isActiveOptions,
} from "./constants";

import { selectContainerStyles } from "SCConstants";

import ConfirmationModal from "components/CountryAdmin/CountryAdminContent/ConfirmationModal";

import { getIsInternal } from "helpers/users";

import "./_content.scss";
import { getRolesModule } from "data-handler/ducks/roles";
import { generateCustomRoleList } from "helpers/roles";
import { getLanguage } from "data-handler/ducks/language";

const RAW_DATA_EXTRACTION_PERMISSION = "has_raw_data_extraction_permission";

const UserPage = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { prevPath } = props;
  const [confirmationModalState, setConfirmationModalState] = useState(false);

  const submitBtnRef = useRef();

  const { userId } = useParams();

  const methods = useForm();
  const { control, handleSubmit, register, errors, setValue, watch } = methods;

  const fetching = useSelector(getIsUserFetching);
  const adminSchools = Object.values(useSelector(getAllAdminSchools).results);
  const isFetching = useSelector(getIsSchoolsFetching);
  const countries = useSelector(countriesDuck.getList);
  const { roles, countryRoles } = useSelector(getRolesModule);
  const countriesIsFetching = useSelector(countriesDuck.isFetching());
  const currentUser = useSelector(getCurrentUser);
  const allUsers = useSelector(getAllUsers);
  const currentSelectedUser = allUsers[userId];
  const countryAuthorityTypes = useSelector(getCountryAuthorityTypes);
  const educationAuthorities = useSelector(
    getEducationAuthoritiesByAuthorityType
  );
  const language = useSelector(getLanguage);

  const existingUser = currentSelectedUser?.state !== "new";

  const selectedUserRole = watch("user_role");
  const selectedCountry = watch("country");

  // Filter the role options based on the selected user's role
  const filteredRoleOptions = generateCustomRoleList(
    roles,
    countryRoles,
    language,
    currentUser.is_admin
  )
    .filter((role) => {
      if (
        !getIsInternal(currentSelectedUser?.email) &&
        currentSelectedUser?.user_role !== "wfp_viewer"
      ) {
        return role.type !== "wfp_viewer";
      } else {
        return true;
      }
    })
    .map((item) => ({
      value: item.type,
      label: item[`custom_name`] ? item[`custom_name`] : item.role,
    }));

  // Redirect to the allUsers path if needed when the user is accessed via the url
  useEffect(() => {
    if (prevPath.includes("newUsers")) {
      if (currentUser?.user_role === "global_administrator") {
        if (currentSelectedUser && currentSelectedUser.state !== "new") {
          history.push(
            `/countryAdmin/users/allUsers/${currentSelectedUser.id}`
          );
        }
      } else if (currentUser?.user_role === "country_administrator") {
        if (
          currentSelectedUser &&
          (currentSelectedUser.state !== "new" ||
            currentSelectedUser.user_role === "country_administrator")
        ) {
          history.push(
            `/countryAdmin/users/allUsers/${currentSelectedUser.id}`
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, currentSelectedUser]);

  useEffect(() => {
    if (!currentSelectedUser) {
      dispatch(requestCurrentUser(userId, history));
    } else {
      if (!selectedCountry && currentSelectedUser.user_role !== "wfp_viewer") {
        // Set the country value if it's not loaded from the user
        // which will force the form to refresh
        setValue(
          "country",
          currentSelectedUser.country
            ? {
                value: currentSelectedUser.country.id,
                label: currentSelectedUser.country.name,
              }
            : null
        );
      } else if (
        !selectedCountry &&
        currentSelectedUser.user_role === "wfp_viewer"
      ) {
        // Set the country values for the wfp_viewer if it's not loaded from the user
        // which will force the form to refresh
        setValue(
          "country",
          currentSelectedUser?.countries &&
            currentSelectedUser?.countries.length !== 0
            ? currentSelectedUser.countries.map((item) => ({
                value: item.id,
                label: item.name,
              }))
            : null
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedUser, userId, dispatch]);

  // Get the correct data based on the selected user's role
  useEffect(() => {
    if (selectedUserRole) {
      if (
        currentUser.is_admin &&
        countries.length === 0 &&
        !countriesIsFetching
      ) {
        dispatch(countriesDuck.fetchList());
      }
      if (selectedUserRole.value === "approver" && selectedCountry) {
        dispatch(requestCountryAuthorityTypes(selectedCountry.value));
        dispatch(requestEducationAuthorities(selectedCountry.value));
      }
      // Refresh the country field when selecting a role different that wfp_viever
      // for a user that had the wfp_viewer role initially
      if (
        selectedUserRole.value !== "wfp_viewer" &&
        currentSelectedUser?.user_role === "wfp_viewer"
      ) {
        if (currentUser?.user_role === "country_administrator") {
          // Set the value as the admin's country
          setValue(
            "country",
            currentUser?.country
              ? {
                  value: currentUser.country.id,
                  label: currentUser.country.name,
                }
              : null
          );
        } else {
          setValue("country", null);
        }
      }
      // Refresh the country field when selecting the wfp_viever role
      // for a user that didn't have the wfp_viewer role initially
      if (
        selectedUserRole.value === "wfp_viewer" &&
        currentSelectedUser?.user_role !== "wfp_viewer"
      ) {
        if (currentUser?.user_role === "country_administrator") {
          // Set the value as the admin's country
          setValue(
            "country",
            currentUser?.country
              ? [
                  {
                    value: currentUser.country.id,
                    label: currentUser.country.name,
                  },
                ]
              : null
          );
        } else {
          setValue("country", null);
        }
      }
      // Refresh the country field when selecting a role different that wfp_viever
      // for a user that didn't have the wfp_viewer role initially
      if (
        selectedUserRole.value !== "wfp_viewer" &&
        currentSelectedUser?.user_role !== "wfp_viewer"
      ) {
        // Set the value as the admin's country
        setValue(
          "country",
          currentSelectedUser.country
            ? {
                value: currentSelectedUser.country.id,
                label: currentSelectedUser.country.name,
              }
            : null
        );
      }
      // Refresh the country field when selecting the wfp_viever role
      // for a user that had the wfp_viewer role initially
      if (
        selectedUserRole.value === "wfp_viewer" &&
        currentSelectedUser?.user_role === "wfp_viewer"
      ) {
        setValue(
          "country",
          currentSelectedUser?.countries &&
            currentSelectedUser?.countries.length !== 0
            ? currentSelectedUser.countries.map((item) => ({
                value: item.id,
                label: item.name,
              }))
            : null
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserRole, dispatch]);

  // Get the correct data based on the selected user's country
  useEffect(() => {
    if (selectedCountry) {
      if (schoolStaffRoles.includes(selectedUserRole?.value))
        dispatch(
          requestAllAdminSchools(
            99999,
            1,
            "",
            [{ id: "country", value: selectedCountry.value }],
            true
          )
        );
      if (selectedUserRole?.value === "approver") {
        dispatch(requestCountryAuthorityTypes(selectedCountry.value));
        dispatch(requestEducationAuthorities(selectedCountry.value));
      }
      if (selectedCountry.value !== currentSelectedUser?.country?.id) {
        setValue("school", null);
        setValue("authorities", null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry, dispatch]);

  const onSubmit = (data) => {
    let submissionData = {};
    const fields = Object.keys(data);
    for (let index in fields) {
      if (Array.isArray(data[fields[index]])) {
        let ids = [];
        for (let key in data[fields[index]]) {
          ids.push(data[fields[index]][key].value);
        }
        if (
          fields[index] === "country" &&
          selectedUserRole.value === "wfp_viewer"
        ) {
          submissionData["countries"] = ids;
        }
        if (
          fields[index] === "authorities" &&
          selectedUserRole.value === "approver"
        ) {
          submissionData["authorities"] = ids;
        }
        continue;
      }
      if (
        typeof data[fields[index]] === "object" &&
        data[fields[index]] &&
        (data[fields[index]].value || data[fields[index]].value === false)
      ) {
        submissionData[fields[index]] = data[fields[index]].value;
      } else if (
        data[fields[index]] &&
        typeof data[fields[index]] !== "object"
      ) {
        submissionData[fields[index]] = data[fields[index]];
      }
    }
    if (submissionData["email"] === currentSelectedUser?.email) {
      delete submissionData["email"];
    }
    submissionData["role"] = submissionData["user_role"];
    submissionData["raw_data_extraction_permission"] =
      data["raw_data_extraction_permission"];
    dispatch(editUser(submissionData, userId, history, prevPath));
  };

  const getAuthorityName = (id) => {
    // Return the name of the country authority type related to the id param
    const authorityType = countryAuthorityTypes.find((item) => item.id === id);
    return authorityType?.name;
  };

  const groupedEducationAuthorities = Object.keys(educationAuthorities).map(
    (i) => ({
      label: (
        <FormattedMessage
          id="CountryAdmin.authorityLabel"
          defaultMessage="{name}"
          values={{
            name: getAuthorityName(
              educationAuthorities[i][0]?.country_authority_type
            ),
          }}
        />
      ),
      options: educationAuthorities[i].map((item) => ({
        label: item.name,
        value: item.id,
      })),
    })
  );

  const checkBoxFormFields = [
    {
      type: "checkbox",
      id: RAW_DATA_EXTRACTION_PERMISSION,
      name: RAW_DATA_EXTRACTION_PERMISSION,
      labelText: (
        <FormattedMessage
          id="user.dataExtractionPermissionLabel"
          defaultMessage="Data Extraction Permission"
        />
      ),
      defaultValue: currentSelectedUser
        ? currentSelectedUser[RAW_DATA_EXTRACTION_PERMISSION]
        : false,
    },
  ];

  const textInputFormFields = textInputFields.map(
    ({ id, name, labelText, required, disabled }) => {
      return {
        type: "text",
        id: id,
        name: name,
        labelText: labelText,
        defaultValue: currentSelectedUser ? currentSelectedUser[name] : "",
        required: required,
        disabled:
          currentUser?.user_role === "country_administrator" &&
          (currentSelectedUser?.user_role === "country_administrator" ||
            currentSelectedUser?.user_role === "global_administrator")
            ? true
            : disabled,
        errors: errors[name],
      };
    }
  );

  const selectOptions = {
    user_role: {
      options: filteredRoleOptions,
      defaultValue: currentSelectedUser?.user_role
        ? {
            value: currentSelectedUser.user_role,
            label: filteredRoleOptions.find(
              (item) => item.value === currentSelectedUser.user_role
            ).label,
          }
        : null,
      required: true,
      disabled: false,
      isMulti: false,
    },
    country: {
      options: countries?.map((country) => ({
        label: country.name,
        value: country.id,
      })),
      defaultValue:
        currentSelectedUser?.user_role === "wfp_viewer"
          ? currentSelectedUser?.countries &&
            currentSelectedUser?.countries.length !== 0
            ? currentSelectedUser.countries.map((item) => ({
                value: item.id,
                label: item.name,
              }))
            : null
          : currentSelectedUser?.country
          ? {
              value: currentSelectedUser.country.id,
              label: currentSelectedUser.country.name,
            }
          : null,
      required: currentUser.is_admin ? true : false,
      disabled: currentUser.is_admin ? false : true,
      isMulti: selectedUserRole?.value === "wfp_viewer" ? true : false,
    },
    school: {
      options: adminSchools?.map((school) => ({
        label: school.name,
        value: school.id,
      })),
      defaultValue: currentSelectedUser?.school
        ? {
            value: currentSelectedUser.school.id,
            label: currentSelectedUser.school.name,
          }
        : null,
      required: true,
      disabled: selectedCountry && !isFetching ? false : true,
      isMulti: false,
    },
    designation: {
      options: approverDesignations,
      defaultValue: currentSelectedUser?.designation
        ? {
            value: approverDesignations.find(
              (item) => item.value === currentSelectedUser.designation
            ).value,
            label: approverDesignations.find(
              (item) => item.value === currentSelectedUser.designation
            ).label,
          }
        : null,
      required: !getIsInternal(currentSelectedUser?.email) ? true : false,
      disabled: selectedCountry ? false : true,
      isMulti: false,
    },
    authorities: {
      options: groupedEducationAuthorities,
      defaultValue:
        currentSelectedUser?.authorities &&
        currentSelectedUser?.authorities.length !== 0
          ? currentSelectedUser.authorities.map((item) => ({
              value: item.id,
              label: item.name,
            }))
          : null,
      required: false,
      disabled: selectedCountry ? false : true,
      isMulti: true,
    },
  };

  const selectFormFields = selectFields.map(
    ({ id, name, labelText, roles }) => {
      return {
        type: "select",
        id: id,
        name: name,
        labelText: labelText,
        defaultValue: selectOptions[name].defaultValue,
        options: selectOptions[name].options,
        isMulti: selectOptions[name].isMulti,
        required: selectOptions[name].required,
        disabled:
          currentUser?.user_role === "country_administrator" &&
          (currentSelectedUser?.user_role === "country_administrator" ||
            currentSelectedUser?.user_role === "global_administrator")
            ? true
            : selectOptions[name].disabled,
        errors: errors[name],
        roles: roles,
      };
    }
  );

  const formFields = [
    ...textInputFormFields,
    ...selectFormFields,
    // Add the is_active field if the user is not in the new state
    existingUser
      ? {
          type: "select",
          id: "active",
          name: "active",
          labelText: (
            <FormattedMessage
              id="countryAdmin.active"
              defaultMessage="Active"
            />
          ),
          defaultValue:
            currentSelectedUser?.state === "approved"
              ? isActiveOptions.find((item) => item.value === true)
              : isActiveOptions.find((item) => item.value === false),
          options: isActiveOptions,
          isMulti: false,
          required: true,
          disabled:
            currentUser?.user_role === "country_administrator" &&
            (currentSelectedUser?.user_role === "country_administrator" ||
              currentSelectedUser?.user_role === "global_administrator")
              ? true
              : false,
          errors: errors.active,
          roles: [
            "global_administrator",
            "country_administrator",
            "principal",
            "teacher",
            "store_keeper",
            "approver",
            "wfp_viewer",
            "external_viewer",
            "school_group_administrator",
          ],
        }
      : {},
    ...checkBoxFormFields,
  ];

  const standardStyleController = (disabled) => {
    return {
      control: (styles) => ({
        ...styles,
        border: "1px solid rgba(140, 164, 181, 1)",
        opacity: disabled ? "0.5" : "",
      }),
    };
  };

  const invalidStyle = { borderColor: "#c5192d" };

  const openConfirmationModal = () => {
    setConfirmationModalState(true);
  };

  // In order to submit form data. Hidden button is created within the form.
  // As the primary button within the modal cannot be set to type="submit"
  // When the modal submit button is clicked. This subsequently clicks the designated form submit button.
  const onConfirmationModalSubmit = () => {
    setConfirmationModalState(false);
    submitBtnRef.current.click();
  };

  if (fetching) {
    return (
      <div className="inlineLoading">
        <InlineLoading />
      </div>
    );
  }

  return (
    <div className="country-admin-user-page">
      <Wrapper
        pageWidth="sm"
        spacing="md"
        background="lighter"
        className="table"
      >
        <Module>
          <ModuleHeader className="country-admin-user-form-row">
            <Link exact="true" to={prevPath} style={{ paddingRight: "10px" }}>
              <FontAwesomeIcon icon={faChevronLeft} size="lg" />
            </Link>
            {currentSelectedUser
              ? `${currentSelectedUser.other_names} ${currentSelectedUser.last_name}`
              : ""}
          </ModuleHeader>
          <ModuleBody>
            <div className="country-admin-user-form">
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  {formFields.map((item) => {
                    switch (item.type) {
                      case "text":
                        return (
                          <div
                            key={item.id}
                            className="country-admin-school-form-row"
                          >
                            <TextInput
                              name={item.name}
                              id={item.id}
                              style={item.errors ? invalidStyle : {}}
                              labelText={item.labelText}
                              defaultValue={item.defaultValue}
                              inputRef={register({ required: item.required })}
                              disabled={item.disabled}
                            />
                          </div>
                        );
                      case "select":
                        const selectedRole = selectedUserRole
                          ? selectedUserRole.value
                          : currentSelectedUser?.user_role;
                        return (
                          item.roles.includes(selectedRole) && (
                            <div
                              key={item.id}
                              className="country-admin-user-form-row"
                            >
                              <label
                                className="wfp--label"
                                style={{
                                  opacity: item.disabled ? "0.5" : "",
                                }}
                              >
                                {item.labelText}
                              </label>
                              <Controller
                                render={({ value, onChange }) => (
                                  <Select
                                    styles={
                                      item.errors
                                        ? selectContainerStyles.invalid
                                        : standardStyleController(item.disabled)
                                    }
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    options={item.options}
                                    isMulti={item.isMulti}
                                    isDisabled={item.disabled}
                                    value={value}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                )}
                                id={item.id}
                                name={item.name}
                                defaultValue={item.defaultValue}
                                rules={{ required: item.required }}
                                control={control}
                              />
                            </div>
                          )
                        );
                      case "checkbox":
                        return (
                          <div
                            key={item.id}
                            className="country-admin-user-form-row"
                          >
                            <label
                              className="wfp--label"
                              style={{
                                opacity: item.disabled ? "0.5" : "",
                              }}
                            >
                              {item.labelText}
                            </label>
                            <Controller
                              render={({ onChange, value }) => (
                                <div style={{ marginTop: 25 }}>
                                  <Checkbox
                                    id={item.name}
                                    checked={value}
                                    onChange={(e) => onChange(e.target.checked)}
                                  />
                                </div>
                              )}
                              id={item.id}
                              name={item.name}
                              defaultValue={item.defaultValue}
                              control={control}
                            />
                          </div>
                        );
                      default:
                        return null;
                    }
                  })}
                  <div
                    className="country-admin-school-form-row"
                    style={{ color: colors["support-01"].hex }}
                  >
                    {
                      // Only display if there are errors.
                      // Unable to use useForm message within "errors" variable as this does not support our translation method
                      Object.keys(errors).length === 0 ? (
                        ""
                      ) : (
                        <FormattedMessage
                          id="countryAdmin.schoolProfileErrors"
                          defaultMessage="Please enter a valid value within the highlighted fields"
                        />
                      )
                    }
                  </div>
                  <ConfirmationModal
                    confirmationModalState={confirmationModalState}
                    modalType={"submit"}
                    setConfirmationModalState={setConfirmationModalState}
                    onConfirmationModalSubmit={onConfirmationModalSubmit}
                    submitMessage={
                      <FormattedMessage
                        id="countyAdmin.schoolProfileSubmit"
                        defaultMessage="Are you sure you want to {type} the data of {name}?"
                        values={{
                          name: currentSelectedUser ? (
                            `${currentSelectedUser.other_names} ${currentSelectedUser.last_name}`
                          ) : (
                            <FormattedMessage
                              id="countyAdmin.newUser"
                              defaultMessage="this new user"
                            />
                          ),
                          type: "submit",
                        }}
                      />
                    }
                  />
                  {
                    /* Do not allow country administrators to edit other country administrators */
                    !(
                      currentUser?.user_role === "country_administrator" &&
                      (currentSelectedUser?.user_role ===
                        "country_administrator" ||
                        currentSelectedUser?.user_role ===
                          "global_administrator")
                    ) && (
                      <div className="country-admin-submit-button">
                        <Button
                          style={{
                            marginRight: 15,
                          }}
                          kind="primary"
                          onClick={() => openConfirmationModal()}
                        >
                          <FormattedMessage
                            id="Report.actionSubmit"
                            defaultMessage="Submit"
                          />
                        </Button>
                        {/* Fake Button to submit form data 
                          Check onConfirmationModalSubmit
                          That function controls this button.                  
                        */}
                        <button
                          type="submit"
                          className="hidden-btn"
                          ref={submitBtnRef}
                        />
                      </div>
                    )
                  }
                </form>
              </FormProvider>
            </div>
          </ModuleBody>
        </Module>
      </Wrapper>
    </div>
  );
};

export default UserPage;
