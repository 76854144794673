import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import SidebarItem from "components/SidebarItem";
import CommodityShowSmall from "components/CommodityShowSmall";
import incidentCausesDuck from "data-handler/ducks/incidentCauses";
export default function IncidentItem({
  currentStoreData,
  store,
  ...extraProps
}) {
  const params = useParams();
  const incidentCauses = useSelector(incidentCausesDuck.getList);
  const causes = store?.causes?.map((cause) => {
    return incidentCauses.find((e) => e.id === cause);
  });

  const title = (
    <>
      {causes?.map((cause) => {
        return (
          <span key={cause?.id} className="reason-list__item">
            {cause?.name}
          </span>
        );
      })}
      {store?.causes?.length === 0 && !store.other_cause && (
        <FormattedMessage id="Common.noReason" defaultMessage="No reason" />
      )}
    </>
  );

  return (
    <SidebarItem
      {...extraProps}
      store={store}
      selected={
        currentStoreData && currentStoreData.client_id === store.client_id
      }
      id={store.client_id}
      key={store.client_id}
      title={title}
      subTitle={store.other_cause}
      to={`/school/${params.schoolId}/deliveries/${store.client_id}`}
      type={store.type}
    >
      <CommodityShowSmall commodities={store?.commodities} />
    </SidebarItem>
  );
}
