import { AxiosError } from "axios";
import { RootState } from "data-handler/rootReducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const IS_INITIALIZATION_REQUIRED_SET =
  "schoolconnect/initialization/IS_INITIALIZATION_REQUIRED_SET";
export const INITIALIZER_IS_FETCHING_SET =
  "schoolconnect/initialization/INITIALIZER_IS_FETCHING_SET";
export const INITIALIZER_HAS_ERRORS_SET =
  "schoolconnect/initialization/INITIALIZER_HAS_ERRORS_SET";
export const INITIALIZER_DATA_SET =
  "schoolconnect/initialization/INITIALIZER_DATA_SET";

export const SCHOOL_INITIALIZER_IS_FETCHING_SET =
  "schoolconnect/initialization/SCHOOL_INITIALIZER_IS_FETCHING_SET";
export const SCHOOL_INITIALIZER_HAS_ERRORS_SET =
  "schoolconnect/initialization/SCHOOL_INITIALIZER_HAS_ERRORS_SET";
export const SCHOOL_INITIALIZER_DATA_SET =
  "schoolconnect/initialization/SCHOOL_INITIALIZER_DATA_SET";

export type FetchingData = {
  name: string;
  isFetching: boolean;
  error: AxiosError | null;
};
type State = {
  isInitializationRequired: boolean;
  initializerIsFetching: boolean;
  initializerHasErrors: boolean;
  initializerData: FetchingData[];
  schoolInitializerIsFetching: boolean;
  schoolInitializerHasErrors: boolean;
  schoolInitializerData: FetchingData[];
};

type SetIsInitializationRequiredAction = {
  type: typeof IS_INITIALIZATION_REQUIRED_SET;
  data: { isInitializationRequired: boolean };
};

type SetInitializerIsFetchingAction = {
  type: typeof INITIALIZER_IS_FETCHING_SET;
  data: { isFetching: boolean };
};

type SetInitializerHasErrorsAction = {
  type: typeof INITIALIZER_HAS_ERRORS_SET;
  data: { hasErrors: boolean };
};

type SetInitializerDataAction = {
  type: typeof INITIALIZER_DATA_SET;
  data: { fetchingData: FetchingData[] };
};

type SetSchoolInitializerIsFetchingAction = {
  type: typeof SCHOOL_INITIALIZER_IS_FETCHING_SET;
  data: { isFetching: boolean };
};

type SetSchoolInitializerHasErrorsAction = {
  type: typeof SCHOOL_INITIALIZER_HAS_ERRORS_SET;
  data: { hasErrors: boolean };
};

type SetSchoolInitializerDataAction = {
  type: typeof SCHOOL_INITIALIZER_DATA_SET;
  data: { fetchingData: FetchingData[] };
};
type Action =
  | SetIsInitializationRequiredAction
  | SetInitializerIsFetchingAction
  | SetInitializerHasErrorsAction
  | SetInitializerDataAction
  | SetSchoolInitializerIsFetchingAction
  | SetSchoolInitializerHasErrorsAction
  | SetSchoolInitializerDataAction;

const initialState: State = {
  isInitializationRequired: false,
  initializerIsFetching: false,
  initializerHasErrors: false,
  initializerData: [],
  schoolInitializerIsFetching: false,
  schoolInitializerHasErrors: false,
  schoolInitializerData: [],
};

const reducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case IS_INITIALIZATION_REQUIRED_SET:
      return {
        ...state,
        isInitializationRequired: action.data.isInitializationRequired,
      };
    case INITIALIZER_IS_FETCHING_SET:
      return {
        ...state,
        initializerIsFetching: action.data.isFetching,
      };
    case INITIALIZER_HAS_ERRORS_SET:
      return {
        ...state,
        initializerHasErrors: action.data.hasErrors,
      };
    case INITIALIZER_DATA_SET:
      return {
        ...state,
        initializerData: action.data.fetchingData,
      };
    case SCHOOL_INITIALIZER_IS_FETCHING_SET:
      return {
        ...state,
        schoolInitializerIsFetching: action.data.isFetching,
      };
    case SCHOOL_INITIALIZER_HAS_ERRORS_SET:
      return {
        ...state,
        schoolInitializerHasErrors: action.data.hasErrors,
      };
    case SCHOOL_INITIALIZER_DATA_SET:
      return {
        ...state,
        schoolInitializerData: action.data.fetchingData,
      };
    default:
      return state;
  }
};

const persistedReducer = persistReducer(
  {
    key: "initialization",
    storage,
    blacklist: [
      "initializerData",
      "initializerIsFetching",
      "initializerHasErrors",
      "schoolInitializerData",
      "schoolInitializerIsFetching",
      "schoolInitializerHasErrors",
    ],
  },
  reducer
);

export default persistedReducer;

export const setIsInitializationRequired = (
  isInitializationRequired: boolean
): SetIsInitializationRequiredAction => ({
  type: IS_INITIALIZATION_REQUIRED_SET,
  data: { isInitializationRequired },
});

export const setInitializerIsFetching = (
  isFetching: boolean
): SetInitializerIsFetchingAction => ({
  type: INITIALIZER_IS_FETCHING_SET,
  data: { isFetching },
});

export const setInitializerHasErrors = (
  hasErrors: boolean
): SetInitializerHasErrorsAction => ({
  type: INITIALIZER_HAS_ERRORS_SET,
  data: { hasErrors },
});

export const setInitializerData = (
  fetchingData: FetchingData[]
): SetInitializerDataAction => ({
  type: INITIALIZER_DATA_SET,
  data: { fetchingData },
});

export const setSchoolInitializerIsFetching = (
  isFetching: boolean
): SetSchoolInitializerIsFetchingAction => ({
  type: SCHOOL_INITIALIZER_IS_FETCHING_SET,
  data: { isFetching },
});

export const setSchoolInitializerHasErrors = (
  hasErrors: boolean
): SetSchoolInitializerHasErrorsAction => ({
  type: SCHOOL_INITIALIZER_HAS_ERRORS_SET,
  data: { hasErrors },
});

export const setSchoolInitializerData = (
  fetchingData: FetchingData[]
): SetSchoolInitializerDataAction => ({
  type: SCHOOL_INITIALIZER_DATA_SET,
  data: { fetchingData },
});

export const getIsInitializationRequired = (state: RootState): boolean =>
  state.initialization.isInitializationRequired;

export const getInitializerData = (state: RootState): FetchingData[] =>
  state.initialization.initializerData;

export const getInitializerIsFetching = (state: RootState): boolean =>
  state.initialization.initializerIsFetching;

export const getInitializerHasErrors = (state: RootState): boolean =>
  state.initialization.initializerHasErrors;

export const getSchoolInitializerData = (state: RootState): FetchingData[] =>
  state.initialization.schoolInitializerData;

export const getSchoolInitializerIsFetching = (state: RootState): boolean =>
  state.initialization.schoolInitializerIsFetching;

export const getSchoolInitializerHasErrors = (state: RootState): boolean =>
  state.initialization.schoolInitializerHasErrors;
