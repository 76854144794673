import { combineReducers } from "redux";
import { createMigrate, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import auth from "./ducks/auth";
import commodities from "./ducks/commodities";
import date from "./ducks/date";
import incidentCausesDuck from "./ducks/incidentCauses";
import deliveryIssuesDuck from "./ducks/deliveryIssues";
import distributionSitesDuck from "./ducks/distributionSites";
import distributionReports from "./ducks/distributionReports";
import schoolsImports from "./ducks/schoolsImports";
import batchNumbers from "./ducks/batchNumbers";
import language from "./ducks/language";
import reportsDuck from "./ducks/reports";
import schools from "./ducks/schools";
import foodSources from "./ducks/foodSources";
import initialization from "./ducks/initialization";
import reportSignatures from "./ducks/reportSignatures";
import stores from "./ducks/stores";
import users from "./ducks/users";
import localAuth from "./ducks/localAuth";
import { countriesDuck } from "./ducks/countries";
import { regionsDuck } from "./ducks/regions";
import migrations from "./migrations";
import schoolProfile from "./ducks/schoolProfile";
import toast from "./ducks/toast";
import authorityLevels from "./ducks/authorityLevels";
import adminLevels from "./ducks/adminLevels";
import ageGroups from "./ducks/ageGroups";
import countryProfile from "./ducks/countryProfile";
import deliveries from "./ducks/deliveries";
import roles from "./ducks/roles";
import links from "./ducks/links";
import statements from "./ducks/statements";
import vendors from "./ducks/vendors";
import purchaseOtherCosts from "./ducks/purchaseOtherCosts";

const rootReducer = combineReducers({
  auth,
  adminLevels,
  ageGroups,
  authorityLevels,
  "batch-numbers": batchNumbers,
  commodities: commodities._reducer,
  countries: countriesDuck._reducer,
  countryProfile,
  date,
  deliveries,
  "delivery-issues": deliveryIssuesDuck._reducer,
  distributionReports,
  "distribution-sites": distributionSitesDuck._reducer,
  foodSources,
  "incident-causes": incidentCausesDuck._reducer,
  initialization,
  language,
  links,
  localAuth,
  purchaseOtherCosts,
  regions: regionsDuck._reducer,
  reportSignatures,
  reports: reportsDuck._reducer,
  roles,
  schoolProfile,
  schools,
  schoolsImports,
  statements,
  stores,
  toast,
  users,
  vendors,
});

export type RootState = ReturnType<typeof rootReducer>;

const persistedRootReducer = persistReducer(
  {
    /**
     * Blacklist all reducers which already use persistReducer, otherwise:
     * - their individual configs may not be respected, depending on rehydration order
     * - localStorage space is wasted by duplicate data
     */
    blacklist: [
      "auth",
      "batch-numbers",
      "commodities",
      "countries",
      "country-schools",
      "delivery-issues",
      "distribution-sites",
      "distributionReports",
      "foodSources",
      "incident-causes",
      "links",
      "localAuth",
      "regions",
      "reports",
      "roles",
      "schoolProfile",
      "schools",
      "stores",
      "vendors",
    ],
    key: "root",
    migrate: createMigrate(migrations, { debug: true }),
    storage,
    timeout: 500,
    version: Object.keys(migrations).length,
  },
  rootReducer
);

export default persistedRootReducer;
