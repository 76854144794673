import React, { useEffect, useState, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { Modal, Button, InlineLoading, Toggle } from "@wfp/ui";
import Select from "react-select";

import {
  requestSchoolsForCsvTemplate,
  getSchoolsForCsvTemplate,
  clearSchoolsForCsvTemplate,
  getIsSchoolsFetching,
} from "data-handler/ducks/schools";
import { countriesDuck } from "data-handler/ducks/countries";
import { getCurrentUser } from "data-handler/ducks/auth";
import {
  importCsvFile,
  downloadCsvTemplate,
} from "data-handler/ducks/schoolsImports";

import FileUploader from "components/CountryAdmin/CountryAdminContent/FileUploader";
import CompactSchools from "components/CountryAdmin/CountryAdminContent/Table/CompactSchools";

import "./_content.scss";
import useFileGeneration from "../useFileGeneration";

const CsvImportModal = ({ csvImportModalOpen, setCsvImportModalOpen }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [refreshKey, setRefreshKey] = useState(new Date().valueOf());
  const [compactSchoolsKey, setCompactSchoolsKey] = useState(
    new Date().valueOf()
  );
  const [csvFile, setCsvFile] = useState();
  const [modalPage, setModalPage] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const isFetching = useSelector(getIsSchoolsFetching);
  const countries = useSelector(countriesDuck.getList);
  const countriesIsFetching = useSelector(countriesDuck.isFetching());
  const currentUser = useSelector(getCurrentUser);
  const selectedSchoolsIds = useRef();

  const countryDefaultValue =
    currentUser?.user_role === "country_administrator"
      ? {
          value: currentUser?.country?.id,
          label: currentUser?.country?.name,
        }
      : {
          value: null,
          label: intl.formatMessage({
            id: "countryAdmin.selectCountry",
            defaultMessage: "Select country",
          }),
        };

  const [countryPageOne, setCountryPageOne] = useState(countryDefaultValue);
  const [countryPageTwo, setCountryPageTwo] = useState(countryDefaultValue);
  const [createToggle, setCreateToggle] = useState(true);

  const {
    status: fileGenerationStatus,
    initiateFileGeneration,
  } = useFileGeneration();

  useEffect(() => {
    if (countries.length === 0 && !countriesIsFetching) {
      dispatch(countriesDuck.fetchList());
    }
  }, [countries, countriesIsFetching, dispatch]);

  useEffect(() => {
    if (
      countryPageTwo?.value === countryDefaultValue?.value &&
      csvImportModalOpen
    ) {
      dispatch(clearSchoolsForCsvTemplate());
      selectedSchoolsIds.current = {};
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryPageTwo]);

  const submitCsvFile = () => {
    if (csvFile && countryPageOne && countryPageOne.value) {
      if (createToggle) {
        dispatch(importCsvFile(csvFile, countryPageOne.value, false));
      } else {
        dispatch(importCsvFile(csvFile, countryPageOne.value, true));
      }
    }
    setCsvImportModalOpen(false);
    setRefreshKey(new Date().valueOf());
    setCsvFile(undefined);
    setCountryPageOne(countryDefaultValue);
    setCountryPageTwo(countryDefaultValue);
  };

  const onLoadPageDispatch = (size, idx, filters, ordering) => {
    if (!currentUser.is_admin && countryPageTwo?.value === null) return;

    const allFilters = [...filters];
    if (countryPageTwo?.value !== null) {
      allFilters.push({ id: "country", value: countryPageTwo.value });
    }
    dispatch(requestSchoolsForCsvTemplate(size, idx, ordering, allFilters));
  };

  const standardStyleController = (disabled) => {
    return {
      control: (styles) => ({
        ...styles,
        border: "1px solid rgba(140, 164, 181, 1)",
        opacity: disabled ? "0.5" : "",
      }),
    };
  };

  const generateUpdateTemplateFile = () => {
    if (Object.keys(selectedSchoolsIds?.current || {}).length === 0) {
      setErrorMessage(
        intl.formatMessage({
          id: "modal.selectSchoolsToGenerate",
          defaultMessage:
            "* You need to select at least one school to be able to generate the template",
        })
      );
    } else {
      // Get the ids of the schools
      const schoolsIds = Object.keys(selectedSchoolsIds.current).map((id) =>
        parseInt(id)
      );
      initiateFileGeneration(
        `${process.env.REACT_APP_API_URL}/school-imports/generate-update-template/`,
        schoolsIds
      );
      setErrorMessage("");
    }
  };

  return (
    <Modal
      className="csv-import-modal"
      open={csvImportModalOpen}
      modalHeading={
        modalPage === 1
          ? intl.formatMessage({
              id: "modal.importCsvFile",
              defaultMessage: "Add or edit schools via CSV file",
            })
          : intl.formatMessage({
              id: "modal.generateUpdateCsvFile",
              defaultMessage: "Generate update CSV file",
            })
      }
      modalFooter={
        // Custom footer for the Generate Update Template page
        modalPage === 1
          ? null
          : () => (
              <div className="second-page-footer-buttons">
                <Button
                  className="wfp--btn--secondary"
                  onClick={() => {
                    setErrorMessage("");
                    setModalPage(1);
                    if (currentUser.is_admin) {
                      setCountryPageTwo(countryDefaultValue);
                    }
                  }}
                >
                  {
                    <FormattedMessage
                      id="ModalExtended.cancel"
                      defaultMessage="Cancel"
                    />
                  }
                </Button>
                <Button
                  onClick={generateUpdateTemplateFile}
                  disabled={fileGenerationStatus === "generating"}
                >
                  {fileGenerationStatus === "generating" ? (
                    <InlineLoading />
                  ) : (
                    <FormattedMessage
                      id="modal.generatefile"
                      defaultMessage="Generate file"
                    />
                  )}
                </Button>
              </div>
            )
      }
      primaryButtonText={
        modalPage === 1
          ? intl.formatMessage({
              id: "Report.actionSubmit",
              defaultMessage: "Submit",
            })
          : ""
      }
      primaryButtonDisabled={
        modalPage === 1 ? !csvFile || !countryPageOne?.value : false
      }
      secondaryButtonText={intl.formatMessage({
        id: "ModalExtended.cancel",
        defaultMessage: "Cancel",
      })}
      onRequestSubmit={
        modalPage === 1 ? submitCsvFile : generateUpdateTemplateFile
      }
      onSecondarySubmit={
        modalPage === 1
          ? () => {
              setRefreshKey(new Date().valueOf());
              setCsvImportModalOpen(false);
              setCsvFile(undefined);
              setCountryPageOne(countryDefaultValue);
              setCountryPageTwo(countryDefaultValue);
            }
          : () => {}
      }
      onRequestClose={
        modalPage === 1
          ? () => {
              setRefreshKey(new Date().valueOf());
              setCsvImportModalOpen(false);
              setCsvFile(undefined);
              setCountryPageOne(countryDefaultValue);
              setCountryPageTwo(countryDefaultValue);
            }
          : () => {
              setErrorMessage("");
              setModalPage(1);
              if (currentUser.is_admin) {
                setCountryPageTwo(countryDefaultValue);
              }
            }
      }
      shouldSubmitOnEnter={
        modalPage === 1
          ? csvFile && countryPageOne?.value
            ? true
            : false
          : true
      }
    >
      {modalPage === 1 && (
        <div className="modal-page-one">
          <div className="modal-row">
            <label
              htmlfor="downLoadCSVTemplates"
              className="wfp--label row-label"
            >
              <FormattedMessage
                id="modal.downloadCSVTemplates"
                defaultMessage="Step 1: Download the relevant CSV files"
              />
            </label>
            <div className="modal-row-buttons">
              <Button
                className="wfp--btn--secondary"
                onClick={() => {
                  // Dispatch the download
                  dispatch(downloadCsvTemplate());
                }}
              >
                {
                  <FormattedMessage
                    id="modal.createCSVTemplate"
                    defaultMessage="Add new schools"
                  />
                }
              </Button>
              <Button
                className="wfp--btn--secondary"
                onClick={() => {
                  setModalPage(2);
                }}
              >
                {
                  <FormattedMessage
                    id="modal.updateCSVTemplate"
                    defaultMessage="Update schools"
                  />
                }
              </Button>
            </div>
          </div>
          <div className="modal-row">
            <label htmlfor="UploadFileField" className="wfp--label row-label">
              <FormattedMessage
                id="modal.uploadFileLabel"
                defaultMessage="Step 2: Upload your edited CSV file"
              />
              <Toggle
                className="create-update-toggle"
                toggled={createToggle === false}
                labelA={intl.formatMessage({
                  id: "modal.create",
                  defaultMessage: "New schools",
                })}
                labelB={intl.formatMessage({
                  id: "modal.update",
                  defaultMessage: "Update schools",
                })}
                onToggle={(value) => {
                  setCreateToggle(!createToggle);
                }}
                id="create-update-toggle"
              />
            </label>
            <FileUploader
              onChange={(file) => {
                if (file) {
                  setCsvFile(file);
                }
              }}
              buttonKind="secondary"
              accept=".csv"
              id="csv-import"
              key={refreshKey}
              filename={csvFile?.name}
            />
            <div className="country-select">
              <Select
                styles={standardStyleController(
                  currentUser?.user_role === "country_administrator"
                )}
                className="react-select-container"
                classNamePrefix="react-select"
                value={countryPageOne}
                options={countries?.map((country) => ({
                  label: country.name,
                  value: country.id,
                }))}
                isDisabled={currentUser?.user_role === "country_administrator"}
                onChange={(value) => {
                  setCountryPageOne(value);
                }}
              />
            </div>
          </div>
        </div>
      )}
      {modalPage === 2 && (
        <div className="modal-page-two">
          <div className="modal-row same-line">
            <label
              htmlFor="selectSchoolsCountry"
              className="wfp--label row-label"
            >
              <FormattedMessage
                id="modal.selectSchoolsCountry"
                defaultMessage="Select schools' country"
              />
            </label>
            <div className="country-select">
              <Select
                styles={standardStyleController(
                  currentUser?.user_role === "country_administrator"
                )}
                className="react-select-container"
                classNamePrefix="react-select"
                options={countries?.map((country) => ({
                  label: country.name,
                  value: country.id,
                }))}
                value={countryPageTwo}
                isDisabled={
                  currentUser?.user_role === "country_administrator" ||
                  isFetching
                }
                onChange={(value) => {
                  setCompactSchoolsKey(new Date().valueOf());
                  if (value.value !== countryPageTwo.value) {
                    selectedSchoolsIds.current = {};
                  }
                  setCountryPageTwo(value);
                }}
              />
            </div>
          </div>
          <CompactSchools
            key={compactSchoolsKey}
            selectedSchoolsIds={selectedSchoolsIds}
            onLoadPageDispatch={onLoadPageDispatch}
            getSchools={getSchoolsForCsvTemplate}
            showSecondaryButton={false}
          />
          {errorMessage && (
            <p className="generate-file-error-message">{errorMessage}</p>
          )}
        </div>
      )}
    </Modal>
  );
};

export default CsvImportModal;
