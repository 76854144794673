import React from "react";
import moment from "moment";

import { FormattedMessage } from "react-intl";
import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
  listObjectsToListIds,
  listToObject,
} from "data-handler/ducks/paginationTools";
import {
  getAllDeliveries,
  getAllPurchases,
  getAllStores,
} from "data-handler/ducks/stores";
import { errorToast, successToast } from "data-handler/ducks/toast";
import {
  deliveryCategory,
  purchaseDetailCategory,
  takeHomeRationCategory,
  toastFormattedMessages,
} from "SCConstants";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { camelToSnakeCase } from "./utils";

import {
  REQUEST_COMMODITY_SOURCES_FOR_SCHOOL,
  SUCCESS_COMMODITY_SOURCES_FOR_SCHOOL,
  FAILURE_COMMODITY_SOURCES_FOR_SCHOOL,
  REQUEST_COMMODITY_SOURCES_FOR_USER,
  SUCCESS_COMMODITY_SOURCES_FOR_USER,
  FAILURE_COMMODITY_SOURCES_FOR_USER,
  REQUEST_ALL_COMMODITY_SOURCES,
  SUCCESS_ALL_COMMODITY_SOURCES,
  FAILURE_ALL_COMMODITY_SOURCES,
  SET_COMMODITY_SOURCES_FOR_SCHOOL_FROM_CACHE,
} from "./schools2";

export {
  requestCommoditySourcesForSchool,
  requestCommoditySourcesForUser,
  setCommoditySourcesFromSchoolCache,
  getCommoditySources,
  getCommoditySourcesError,
  getCommoditySourcesFetching,
  getCommoditySourcesForUser,
  getCommoditySourcesForUserError,
  getCommoditySourcesForUserFetching,
  requestAllCommoditySources,
  getAllCommoditySources,
  getAllCommoditySourcesFetching,
  getAllCommoditySourcesError,
} from "./schools2";

export const REQUEST_COMPACT_SCHOOLS =
  "schoolconnect/schools/REQUEST_COMPACT_SCHOOLS";
export const SUCCESS_COMPACT_SCHOOLS =
  "schoolconnect/schools/SUCCESS_COMPACT_SCHOOLS";
export const FAILURE_COMPACT_SCHOOLS =
  "schoolconnect/schools/FAILURE_COMPACT_SCHOOLS";

export const REQUEST_EXTENDED_SCHOOL =
  "schoolconnect/schools/REQUEST_EXTENDED_SCHOOL";
export const REQUEST_EXTENDED_SCHOOLS =
  "schoolconnect/schools/REQUEST_EXTENDED_SCHOOLS";
export const SUCCESS_EXTENDED_SCHOOLS =
  "schoolconnect/schools/SUCCESS_EXTENDED_SCHOOLS";
export const FAILURE_EXTENDED_SCHOOLS =
  "schoolconnect/schools/FAILURE_EXTENDED_SCHOOLS";

export const REQUEST_MOVE = "schoolconnect/schools/REQUEST_MOVE";
export const SUCCESS_MOVE = "schoolconnect/schools/SUCCESS_MOVE";
export const FAILURE_MOVE = "schoolconnect/schools/FAILURE_MOVE";

export const REQUEST_SINGLE = "schoolconnect/schools/REQUEST_SINGLE";
export const SUCCESS_SINGLE = "schoolconnect/schools/SUCCESS_SINGLE";
export const FAILURE_SINGLE = "schoolconnect/schools/FAILURE_SINGLE";

export const ALL_ADMIN_SCHOOLS_REQUEST =
  "schoolconnect/schools/ALL_ADMIN_SCHOOLS_REQUEST";
export const ALL_ADMIN_SCHOOLS_SUCCESS =
  "schoolconnect/schools/ALL_ADMIN_SCHOOLS_SUCCESS";
export const ALL_ADMIN_SCHOOLS_FAILURE =
  "schoolconnect/schools/ALL_ADMIN_SCHOOLS_FAILURE";

export const CURRENT_ADMIN_SCHOOL_REQUEST =
  "schoolconnect/schools/CURRENT_ADMIN_SCHOOL_REQUEST";
export const CURRENT_ADMIN_SCHOOL_SUCCESS =
  "schoolconnect/schools/CURRENT_ADMIN_SCHOOL_SUCCESS";
export const CURRENT_ADMIN_SCHOOL_FAILURE =
  "schoolconnect/schools/CURRENT_ADMIN_SCHOOL_FAILURE";

export const CREATE_SCHOOL_REQUEST =
  "schoolconnect/schools/CREATE_SCHOOL_REQUEST";
export const CREATE_SCHOOL_SUCCESS =
  "schoolconnect/schools/CREATE_SCHOOL_SUCCESS";
export const CREATE_SCHOOL_FAILURE =
  "schoolconnect/schools/CREATE_SCHOOL_FAILURE";

export const EDIT_SCHOOL_REQUEST = "schoolconnect/schools/EDIT_SCHOOL_REQUEST";
export const EDIT_SCHOOL_SUCCESS = "schoolconnect/schools/EDIT_SCHOOL_SUCCESS";
export const EDIT_SCHOOL_FAILURE = "schoolconnect/schools/EDIT_SCHOOL_FAILURE";

export const ACTION_SCHOOL_REQUEST =
  "schoolconnect/schools/ACTION_SCHOOL_REQUEST";
export const ACTION_SCHOOL_SUCCESS =
  "schoolconnect/schools/ACTION_SCHOOL_SUCCESS";
export const ACTION_SCHOOL_FAILURE =
  "schoolconnect/schools/ACTION_SCHOOL_FAILURE";

export const PENDING_ADMIN_SCHOOLS_REQUEST =
  "schoolconnect/schools/PENDNG_ADMIN_SCHOOLS_REQUEST";
export const PENDING_ADMIN_SCHOOLS_SUCCESS =
  "schoolconnect/schools/PENDNG_ADMIN_SCHOOLS_SUCCESS";
export const PENDING_ADMIN_SCHOOLS_FAILURE =
  "schoolconnect/schools/PENDNG_ADMIN_SCHOOLS_FAILURE";

export const SET_DISTRIBUTION_REPORT_REQUEST =
  "schoolconnect/schools/SET_DISTRIBUTION_REPORT_REQUEST";
export const SET_DISTRIBUTION_REPORT_SUCCESS =
  "schoolconnect/schools/SET_DISTRIBUTION_REPORT_SUCCESS";
export const SET_DISTRIBUTION_REPORT_FAILURE =
  "schoolconnect/schools/SET_DISTRIBUTION_REPORT_FAILURE";

export const DISTRIBUTION_REPORT_SCHOOLS_REQUEST =
  "schoolconnect/schools/DISTRIBUTION_REPORT_SCHOOLS_REQUEST";
export const DISTRIBUTION_REPORT_SCHOOLS_SUCCESS =
  "schoolconnect/schools/DISTRIBUTION_REPORT_SCHOOLS_SUCCESS";
export const DISTRIBUTION_REPORT_SCHOOLS_FAILURE =
  "schoolconnect/schools/DISTRIBUTION_REPORT_SCHOOLS_FAILURE";

export const SCHOOLS_FOR_CSV_TEMPLATE_REQUEST =
  "schoolconnect/schools/SCHOOLS_FOR_CSV_TEMPLATE_REQUEST";
export const SCHOOLS_FOR_CSV_TEMPLATE_SUCCESS =
  "schoolconnect/schools/SCHOOLS_FOR_CSV_TEMPLATE_SUCCESS";
export const SCHOOLS_FOR_CSV_TEMPLATE_FAILURE =
  "schoolconnect/schools/SCHOOLS_FOR_CSV_TEMPLATE_FAILURE";

export const CLEAR_SCHOOLS_FOR_CSV_TEMPLATE =
  "schoolconnect/schools/CLEAR_SCHOOLS_FOR_CSV_TEMPLATE";

export const CLEAR_CURRENT_SCHOOL_STATE =
  "schoolconnect/schools/CLEAR_CURRENT_SCHOOL_STATE";

export const SET_USER_CACHED_SCHOOL =
  "schoolconnect/schools/SET_USER_CACHED_SCHOOL";

const initialState = {
  currentSchool: undefined,
  error: null,
  fetching: false,
  // TODO remove schoolsList
  schoolsList: [],
  allAdminSchools: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  pendingAdminSchools: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  currentAdminSchool: undefined,
  distributionReportSchools: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  schoolsForCsvTemplate: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  // list of compact schools
  //compactSchools: {
  //  error: null,
  //  fetching: false,
  //  data: {
  //    count: 0,
  //    next: null,
  //    previous: null,
  //    results: [],
  //  },
  //},
  compactSchoolsError: null,
  compactSchoolsFetching: false,
  compactSchoolsData: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  // list of compact schools
  //extendedSchools: {
  //  error: null,
  //  fetching: false,
  //  data: {
  //    count: 0,
  //    next: null,
  //    previous: null,
  //    results: [],
  //  },
  //},
  extendedSchoolsError: null,
  extendedSchoolsFetching: false,
  extendedSchoolsData: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  //commoditySources: { error: null, fetching: false, data: {} },
  commoditySourcesError: null,
  commoditySourcesFetching: false,
  commoditySourcesData: {},
  //userCommoditySources: { error: null, fetching: false, data: {} },
  userCommoditySourcesError: null,
  userCommoditySourcesFetching: false,
  userCommoditySourcesData: {},
  //allCommoditySources: { error: null, fetching: false, data: [] },
  allCommoditySourcesError: null,
  allCommoditySourcesFetching: false,
  allCommoditySourcesData: [],
};

export function schoolsReducer(state = initialState, action) {
  switch (action.type) {
    case CLEAR_CURRENT_SCHOOL_STATE:
      return {
        ...state,
        currentSchool: initialState.currentSchool,
      };
    case REQUEST_SINGLE:
      return { ...state, fetching: true, error: null };
    case SUCCESS_SINGLE:
      return {
        ...state,
        currentSchool: action.currentSchool,
        error: null,
        fetching: false,
      };
    case FAILURE_SINGLE:
      return {
        ...state,
        fetching: false,
        error: action.error,
      };
    case REQUEST_COMPACT_SCHOOLS:
      return {
        ...state,
        compactSchoolsError: null,
        compactSchoolsFetching: true,
      };
    case SUCCESS_COMPACT_SCHOOLS:
      return {
        ...state,
        compactSchoolsError: null,
        compactSchoolsFetching: false,
        compactSchoolsData: action.data,
      };
    case FAILURE_COMPACT_SCHOOLS:
      return {
        ...state,
        compactSchoolsError: action.error,
        compactSchoolsFetching: false,
      };
    case REQUEST_EXTENDED_SCHOOLS:
      return {
        ...state,
        extendedSchoolsError: null,
        extendedSchoolsFetching: true,
      };
    case SUCCESS_EXTENDED_SCHOOLS:
      return {
        ...state,
        extendedSchoolsError: null,
        extendedSchoolsFetching: false,
        extendedSchoolsData: action.data,
      };
    case FAILURE_EXTENDED_SCHOOLS:
      return {
        ...state,
        extendedSchoolsError: action.error,
        extendedSchoolsFetching: false,
      };

    case SCHOOLS_FOR_CSV_TEMPLATE_REQUEST:
    case ALL_ADMIN_SCHOOLS_REQUEST:
      return { ...state, fetching: true, error: null };
    case ALL_ADMIN_SCHOOLS_SUCCESS:
      return {
        ...state,
        allAdminSchools: action.data,
        fetching: false,
        error: null,
      };
    case SCHOOLS_FOR_CSV_TEMPLATE_FAILURE:
    case ALL_ADMIN_SCHOOLS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    case PENDING_ADMIN_SCHOOLS_REQUEST:
      return { ...state, fetching: true, error: null };
    case PENDING_ADMIN_SCHOOLS_SUCCESS:
      return {
        ...state,
        pendingAdminSchools: action.data,
        fetching: false,
        error: null,
      };
    case PENDING_ADMIN_SCHOOLS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };

    case REQUEST_COMMODITY_SOURCES_FOR_SCHOOL:
      return {
        ...state,
        commoditySourcesError: null,
        commoditySourcesFetching: true,
      };

    case REQUEST_COMMODITY_SOURCES_FOR_USER:
      return {
        ...state,
        userCommoditySourcesError: null,
        userCommoditySourcesFetching: true,
      };

    case SUCCESS_COMMODITY_SOURCES_FOR_SCHOOL:
      return {
        ...state,
        commoditySourcesError: null,
        commoditySourcesFetching: false,
        commoditySourcesData: action.data,
      };

    case SET_COMMODITY_SOURCES_FOR_SCHOOL_FROM_CACHE:
      const commoditySources = state.userCommoditySourcesData[action.schoolId];
      return {
        ...state,
        commoditySourcesError: null,
        commoditySourcesFetching: false,
        commoditySourcesData: commoditySources,
      };

    case SUCCESS_COMMODITY_SOURCES_FOR_USER:
      return {
        ...state,
        userCommoditySourcesError: null,
        userCommoditySourcesFetching: false,
        userCommoditySourcesData: action.data,
      };

    case FAILURE_COMMODITY_SOURCES_FOR_SCHOOL:
      return {
        ...state,
        commoditySourcesError: action.error,
        commoditySourcesFetching: false,
      };

    case FAILURE_COMMODITY_SOURCES_FOR_USER:
      return {
        ...state,
        userCommoditySourcesError: action.error,
        userCommoditySourcesFetching: false,
      };

    case REQUEST_ALL_COMMODITY_SOURCES:
      return {
        ...state,
        allCommoditySourcesError: null,
        allCommoditySourcesFetching: true,
      };

    case SUCCESS_ALL_COMMODITY_SOURCES:
      return {
        ...state,
        allCommoditySourcesError: null,
        allCommoditySourcesFetching: false,
        allCommoditySourcesData: action.data,
      };

    case FAILURE_ALL_COMMODITY_SOURCES:
      return {
        ...state,
        allCommoditySourcesError: action.error,
        allCommoditySourcesFetching: false,
      };

    case CURRENT_ADMIN_SCHOOL_REQUEST:
      return { ...state, fetching: true, error: null };

    case CURRENT_ADMIN_SCHOOL_SUCCESS:
      const { currentSchool } = action;
      const currentSchoolObject = { [currentSchool.id]: currentSchool };
      // Append the new user to the correct field
      const updateObject =
        currentSchool.state === "new"
          ? {
              pendingAdminSchools: {
                ...state.pendingAdminSchools,
                results: {
                  ...state.pendingAdminSchools.results,
                  ...currentSchoolObject,
                },
              },
            }
          : {
              allAdminSchools: {
                ...state.allAdminSchools,
                results: {
                  ...state.allAdminSchools.results,
                  ...currentSchoolObject,
                },
              },
            };
      return {
        ...state,
        ...updateObject,
        fetching: false,
        error: null,
      };
    case CURRENT_ADMIN_SCHOOL_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    case CREATE_SCHOOL_REQUEST:
      return { ...state, fetching: true, error: null };
    case CREATE_SCHOOL_SUCCESS:
      return { ...state, fetching: false, error: null };
    case CREATE_SCHOOL_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    case EDIT_SCHOOL_REQUEST:
      return { ...state, fetching: true, error: null };
    case EDIT_SCHOOL_SUCCESS:
      return { ...state, fetching: false, error: null };
    case EDIT_SCHOOL_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    case ACTION_SCHOOL_REQUEST:
      return { ...state, fetching: true, error: null };
    case ACTION_SCHOOL_SUCCESS:
      return { ...state, fetching: false, error: null };
    case ACTION_SCHOOL_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    case SET_DISTRIBUTION_REPORT_REQUEST:
      return { ...state, fetching: true, error: null };
    case SET_DISTRIBUTION_REPORT_SUCCESS:
      return {
        ...state,
        error: null,
        fetching: false,
      };
    case SET_DISTRIBUTION_REPORT_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    case DISTRIBUTION_REPORT_SCHOOLS_REQUEST:
      return { ...state, fetching: true, error: null };
    case DISTRIBUTION_REPORT_SCHOOLS_SUCCESS:
      return {
        ...state,
        distributionReportSchools: action.data,
        fetching: false,
        error: null,
      };
    case DISTRIBUTION_REPORT_SCHOOLS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    case SCHOOLS_FOR_CSV_TEMPLATE_SUCCESS:
      return {
        ...state,
        schoolsForCsvTemplate: action.data,
        fetching: false,
        error: null,
      };
    case CLEAR_SCHOOLS_FOR_CSV_TEMPLATE:
      return {
        ...state,
        schoolsForCsvTemplate: initialState.schoolsForCsvTemplate,
      };
    case SET_USER_CACHED_SCHOOL:
      return {
        ...state,
        currentSchool: state.extendedSchoolsData.results.find(
          (school) => action.schoolId === school.id
        ),
      };
    default:
      return state;
  }
}

// Selectors
//export const getAllSchools = (state) => state.schools.schoolsList;
export const getCompactSchools = (state) => {
  const resultCount = state.schools.compactSchoolsData?.count;
  if (resultCount === 0) {
    return [];
  } else {
    return state.schools.compactSchoolsData?.results;
  }
};

export const getCompactSchoolsById = (schoolId) => (state) =>
  state.schools.compactSchoolsData.results.find(
    (school) => schoolId === school.id
  );

export const getUserHasAccessToSchool = (schoolId) => (state) =>
  !!getCompactSchoolsById(schoolId);
export const getCompactSchoolsFetching = (state) =>
  state.schools.compactSchoolsFetching;
export const getCompactSchoolsError = (state) =>
  state.schools.compactSchoolsError;
export const getCompactSchoolsCount = (state) =>
  state.schools.compactSchoolsData?.count || 0;

export const getExtendedSchools = (state) => {
  return state.schools.extendedSchoolsData?.results;
};

export const getExtendedSchoolsById = (schoolId) => (state) =>
  state.schools.extendedSchoolsData.results.find(
    (school) => schoolId === school.id
  );
export const getExtendedSchoolsFetching = (state) =>
  state.schools.extendedSchoolsFetching;
export const getExtendedSchoolsError = (state) =>
  state.schools.extendedSchoolsError;
export const getExtendedSchoolsCount = (state) =>
  state.schools.extendedSchoolsData?.count || 0;

export const getCurrentSchool = (state) => state.schools.currentSchool;
export const getIsSchoolsFetching = (state) => state.schools.fetching;
export const getSchoolsError = (state) => state.schools.error;
export const getCurrentSchoolProfile = (state) =>
  getCurrentSchool(state).profile;
export const getCurrentSchoolProfileCommodities = (state) =>
  getCurrentSchool(state).profile.commodities;
export const getCurrentSchoolProfileWfpCommodities = (state) =>
  state.schools.currentSchool.profile.commodities.filter(
    (commodity) => commodity.is_wfp
  );
export const getCurrentSchoolProfileOtherCommodities = (state) =>
  state.schools.currentSchool.profile.commodities.filter(
    (commodity) => !commodity.is_wfp
  );
export const getAllAdminSchools = (state) => state.schools.allAdminSchools;
export const getPendingAdminSchools = (state) =>
  state.schools.pendingAdminSchools;

export const getAllAndPendingSchools = (state) => ({
  ...state.schools.pendingAdminSchools.results,
  ...state.schools.allAdminSchools.results,
});

export const getSchoolsForCsvTemplate = (state) =>
  state.schools.schoolsForCsvTemplate;

export const getDistributionReportSchools = (state) =>
  state.schools.distributionReportSchools;

// Action creators
export const clearCurrentSchoolState = () => ({
  type: CLEAR_CURRENT_SCHOOL_STATE,
});

export const requestCompactSchools = (data) => ({
  type: REQUEST_COMPACT_SCHOOLS,
  data,
});

export const requestExtendedSchools = (data) => ({
  type: REQUEST_EXTENDED_SCHOOLS,
  data,
});

export const requestExtendedSchool = (data) => ({
  type: REQUEST_EXTENDED_SCHOOLS,
  data,
});

export const requestCurrentSchool = (data) => ({
  type: REQUEST_SINGLE,
  data,
});

export const requestAllAdminSchools = (
  pageSize,
  pageIndex,
  ordering,
  filters,
  compact = null
) => ({
  type: ALL_ADMIN_SCHOOLS_REQUEST,
  pageSize,
  pageIndex,
  ordering,
  filters,
  compact,
});

export const requestPendingAdminSchools = (
  pageSize,
  pageIndex,
  ordering,
  filters
) => ({
  type: PENDING_ADMIN_SCHOOLS_REQUEST,
  pageSize,
  pageIndex,
  ordering,
  filters,
});

export const requestMoveSchoolToNewProfile = (data) => ({
  type: REQUEST_MOVE,
  data,
});

export const requestCurrentAdminSchool = (data, history) => ({
  type: CURRENT_ADMIN_SCHOOL_REQUEST,
  data,
  history,
});

export const createSchool = (data, history) => ({
  type: CREATE_SCHOOL_REQUEST,
  data,
  history,
});

export const editSchool = (data, id, history, prevPath) => ({
  type: EDIT_SCHOOL_REQUEST,
  data,
  id,
  history,
  prevPath,
});

export const actionSchool = (data, stateAction) => ({
  type: ACTION_SCHOOL_REQUEST,
  data,
  stateAction,
});

export const setSchoolDistributionReport = (data) => ({
  type: SET_DISTRIBUTION_REPORT_REQUEST,
  data,
});

export const requestDistributionReportSchools = (
  data,
  page_size,
  page,
  filters
) => ({
  type: DISTRIBUTION_REPORT_SCHOOLS_REQUEST,
  data,
  page_size,
  page,
  filters,
});

export const requestSchoolsForCsvTemplate = (
  pageSize,
  pageIndex,
  ordering,
  filters
) => ({
  type: SCHOOLS_FOR_CSV_TEMPLATE_REQUEST,
  pageSize,
  pageIndex,
  ordering,
  filters,
});

export const clearSchoolsForCsvTemplate = () => ({
  type: CLEAR_SCHOOLS_FOR_CSV_TEMPLATE,
});

export const setUserCachedSchool = (schoolId) => ({
  type: SET_USER_CACHED_SCHOOL,
  schoolId,
});

export function* compactSchoolsSagaWatcher() {
  yield takeLatest(REQUEST_COMPACT_SCHOOLS, workerGetCompactSchoolsSaga);
}

export function* extendedSchoolsSagaWatcher() {
  yield takeLatest(REQUEST_EXTENDED_SCHOOLS, workerGetExtendedSchoolsSaga);
}

export function* extendedSchoolSagaWatcher() {
  yield takeLatest(REQUEST_EXTENDED_SCHOOL, workerGetExtendedSchoolSaga);
}
export function* singleSchoolSagaWatcher() {
  yield takeLatest(REQUEST_SINGLE, workerGetSchoolSaga);
}

export function* adminAllSchoolsSagaWatcher() {
  yield takeLatest(ALL_ADMIN_SCHOOLS_REQUEST, adminAllSchoolsSagaWorker);
}

export function* adminPendingSchoolsSagaWatcher() {
  yield takeLatest(
    PENDING_ADMIN_SCHOOLS_REQUEST,
    adminPendingSchoolsSagaWorker
  );
}

export function* moveSchoolToNewProfileSagaWatcher() {
  yield takeLatest(REQUEST_MOVE, workerPostMoveSchoolToNewProfile);
}

export function* currentAdminSchoolSagaWatcher() {
  yield takeLatest(CURRENT_ADMIN_SCHOOL_REQUEST, currentAdminSchoolSagaWorker);
}

export function* createSchoolSagaWatcher() {
  yield takeLatest(CREATE_SCHOOL_REQUEST, createSchoolSagaWorker);
}

export function* editSchoolSagaWatcher() {
  yield takeLatest(EDIT_SCHOOL_REQUEST, editSchoolSagaWorker);
}

export function* actionSchoolSagaWatcher() {
  yield takeLatest(ACTION_SCHOOL_REQUEST, actionSchoolSagaWorker);
}

export function* setSchoolDistributionReportSagaWatcher() {
  yield takeLatest(
    SET_DISTRIBUTION_REPORT_REQUEST,
    setSchoolDistributionReportSagaWorker
  );
}

export function* distributionReportSchoolSagaWatcher() {
  yield takeLatest(
    DISTRIBUTION_REPORT_SCHOOLS_REQUEST,
    distributionReportSchoolsSagaWorker
  );
}

export function* schoolsForCsvTemplateSagaWatcher() {
  yield takeLatest(
    SCHOOLS_FOR_CSV_TEMPLATE_REQUEST,
    schoolsForCsvTemplateSagaWorker
  );
}

function fetchSchools(compact = true, limit = 99999) {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/schools/`,
    params: {
      compact,
      limit,
    },
  });
}

function* workerGetCompactSchoolsSaga() {
  try {
    const response = yield call(fetchSchools, true);
    yield put({ type: SUCCESS_COMPACT_SCHOOLS, data: response.data });
  } catch (error) {
    yield put({ type: FAILURE_COMPACT_SCHOOLS, error });
  }
}

function* workerGetExtendedSchoolsSaga() {
  try {
    const response = yield call(fetchSchools, false);
    yield put({ type: SUCCESS_EXTENDED_SCHOOLS, data: response.data });
  } catch (error) {
    yield put({ type: FAILURE_EXTENDED_SCHOOLS, error });
  }
}

function* workerGetExtendedSchoolSaga() {
  try {
    const response = yield call(fetchSchools, false);
    yield put({ type: SUCCESS_EXTENDED_SCHOOLS, data: response.data });
  } catch (error) {
    yield put({ type: FAILURE_EXTENDED_SCHOOLS, error });
  }
}

export function fetchSchoolsPaginated(search, limit, offset, compact = true) {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/schools/`,
    params: {
      search,
      compact: compact,
      offset: offset,
      limit: limit,
    },
  });
}

function fetchGetSchool(schoolId) {
  const url = `${process.env.REACT_APP_API_URL}/schools/${schoolId.data}/`;
  return axios({
    method: "GET",
    url: url,
  });
}

function* workerGetSchoolSaga(schoolId) {
  try {
    const response = yield call(fetchGetSchool, schoolId);
    const currentSchool = response.data;
    yield put({ type: SUCCESS_SINGLE, currentSchool });
  } catch (error) {
    yield put({ type: FAILURE_SINGLE, error });
  }
}

function fetchPendingAdminSchools(action) {
  const page_size = action.pageSize;
  const page = action.pageIndex * page_size - page_size;
  const filters = action.filters;
  const ordering = action.ordering.replace(/\./g, "_");

  const url =
    `${process.env.REACT_APP_API_URL}/schools/pending-schools/` +
    `?limit=${page_size}&offset=${page}` +
    (ordering ? `&ordering=${ordering}` : "") +
    (filters && filters.length
      ? filters
          .map((filter) => {
            const id = filter.id;
            const value = filter.value;
            if (value && value !== "") {
              return `&${id}=${value}`;
            }
            return "";
          })
          .join("")
      : "");

  return axios({
    method: "GET",
    url: url,
  });
}

function* adminPendingSchoolsSagaWorker(action) {
  try {
    const response = yield call(fetchPendingAdminSchools, action);
    const results = listToObject(response.data.results, "id");
    const ids = listObjectsToListIds(response.data.results, "id");

    const data = {
      ...response.data,
      results,
      ids,
    };

    yield put({ type: PENDING_ADMIN_SCHOOLS_SUCCESS, data });
  } catch (error) {
    yield put({ type: PENDING_ADMIN_SCHOOLS_FAILURE, error });
  }
}

function fetchAllAdminSchools(action) {
  const page_size = action.pageSize;
  const page = action.pageIndex * page_size - page_size;
  const filters = action.filters;
  const ordering = action.ordering.replace(/\./g, "_");
  const compact = action.compact;

  const url =
    `${process.env.REACT_APP_API_URL}/schools/all-schools/` +
    `?limit=${page_size}&offset=${page}` +
    (ordering ? `&ordering=${ordering}` : "") +
    (filters && filters.length
      ? filters
          .map((filter) => {
            const id = filter.id;
            const value = filter.value;
            if (value && value !== "") {
              return `&${id}=${value}`;
            }
            return "";
          })
          .join("")
      : "");

  const compact_params = compact ? { compact: true } : {};

  return axios({
    method: "GET",
    url: url,
    params: compact_params,
  });
}

function* adminAllSchoolsSagaWorker(action) {
  try {
    const response = yield call(fetchAllAdminSchools, action);
    const results = listToObject(response.data.results, "id");
    const ids = listObjectsToListIds(response.data.results, "id");

    const data = {
      ...response.data,
      results,
      ids,
    };

    yield put({ type: ALL_ADMIN_SCHOOLS_SUCCESS, data });
  } catch (error) {
    yield put({ type: ALL_ADMIN_SCHOOLS_FAILURE, error });
  }
}

function fetchPostMoveSchoolToNewProfile(action) {
  const url = `${process.env.REACT_APP_API_URL}/schools/update-school-profile/`;
  return axios({
    method: "POST",
    url: url,
    data: action.data,
  });
}

function* workerPostMoveSchoolToNewProfile(payload) {
  try {
    const response = yield call(fetchPostMoveSchoolToNewProfile, payload);
    const responseData = response.data;
    yield put({ type: SUCCESS_MOVE, responseData });
    yield put(successToast());
    localStorage.removeItem("persist:schoolProfile");
    window.location.reload();
  } catch (error) {
    yield put({ type: FAILURE_MOVE, error });
    yield put(errorToast());
  }
}

function* currentAdminSchoolSagaWorker(action) {
  try {
    const response = yield call(fetchGetSchool, action);
    const currentSchool = response.data;
    yield put({ type: CURRENT_ADMIN_SCHOOL_SUCCESS, currentSchool });
  } catch (error) {
    const { history } = action;
    history.push(`/countryAdmin/schools`);
    yield put({ type: CURRENT_ADMIN_SCHOOL_FAILURE, error });
  }
}

function postNewSchool(action) {
  const url = `${process.env.REACT_APP_API_URL}/schools/`;

  return axios(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(action.data),
  });
}

function* createSchoolSagaWorker(action) {
  try {
    const { history } = action;
    yield call(postNewSchool, action);

    yield put({ type: CREATE_SCHOOL_SUCCESS });
    yield put(successToast());
    history.push("/countryAdmin/schools/newSchools");
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: CREATE_SCHOOL_FAILURE, error });
    yield put(
      errorToast(
        toastFormattedMessages.find((e) => e.name === "toast.infomationError")
          .label
      )
    );
  }
}

function putSchool(action) {
  const url = `${process.env.REACT_APP_API_URL}/schools/${action.id}/`;

  return axios(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(action.data),
  });
}

function* editSchoolSagaWorker(action) {
  try {
    const { history, prevPath } = action;
    yield call(putSchool, action);

    yield put({ type: EDIT_SCHOOL_SUCCESS });
    yield put(successToast());
    history.push(prevPath);
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: EDIT_SCHOOL_FAILURE, error });
    // Check for message
    yield put(
      errorToast(
        toastFormattedMessages.find((e) => e.name === "toast.infomationError")
          .label
      )
    );
  }
}

function putActionSchool(action) {
  const { data, stateAction } = action;
  const perform = camelToSnakeCase(stateAction);
  const url = `${process.env.REACT_APP_API_URL}/schools/perform/${perform}/`;

  return axios(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  });
}

function* actionSchoolSagaWorker(action) {
  try {
    yield call(putActionSchool, action);
    yield put({ type: ACTION_SCHOOL_SUCCESS });
    yield put(successToast());
    window.location.reload();
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: ACTION_SCHOOL_FAILURE, error });
    const label = toastFormattedMessages.find(
      (e) => e.name === "toast.infomationError"
    ).label;
    yield put(errorToast(label));
  }
}

function postSetSchoolDistributionReport(action) {
  const url = `${process.env.REACT_APP_API_URL}/schools/set-distribution-report/`;
  return axios({
    method: "POST",
    url: url,
    data: action.data,
  });
}

function* setSchoolDistributionReportSagaWorker(payload) {
  try {
    const response = yield call(postSetSchoolDistributionReport, payload);
    const responseData = response.data;
    yield put({ type: SET_DISTRIBUTION_REPORT_SUCCESS, responseData });
    yield put(successToast());
    window.location.reload();
  } catch (error) {
    const errorObject = error?.response?.data?.errors?.message;
    const errorMessage = errorObject ? errorObject[0] : undefined;
    let invalidSchools = [];
    if (errorMessage) {
      invalidSchools = error?.response?.data?.errors?.schools?.join(", ");
    }

    const label =
      errorMessage === "clashing_period" ? (
        <FormattedMessage
          id="toast.clashingPeriod"
          defaultMessage="The following schools ({schools}) already have a distribution report within that period. None of the selected schools were updated"
          values={{ schools: invalidSchools }}
        />
      ) : errorMessage === "invalid_country_schools" ? (
        <FormattedMessage
          id="toast.invalidCountry"
          defaultMessage="The following schools ({schools}) do not match the country admin's country. None of the selected schools were updated"
          values={{ schools: invalidSchools }}
        />
      ) : undefined;
    yield put({ type: SET_DISTRIBUTION_REPORT_FAILURE, error });
    yield put(errorToast(label));
  }
}

function getDistributionReportSchoolsRequest(action) {
  const data = action.data;
  const page_size = action.page_size;
  const page = action.page * page_size - page_size;
  const filters = action.filters;
  const activity_code = data?.activity_code;

  const startDate = data?.start_date
    ? moment(data?.start_date).format("YYYY-MM-DD")
    : "";

  const endDate = data?.end_date
    ? moment(data?.end_date).format("YYYY-MM-DD")
    : "";

  const url =
    `${process.env.REACT_APP_API_URL}/schools/` +
    `?limit=${page_size}&offset=${page}` +
    (filters && filters.length
      ? filters
          .map((filter) => {
            const id = filter.id;
            const value = filter.value;
            if (value && value !== "") {
              return `&${id}=${value}`;
            }
            return "";
          })
          .join("")
      : "");

  return axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      distribution_report_date_after: startDate,
      distribution_report_date_before: endDate,
      activity_code: activity_code,
    },
  });
}

function* distributionReportSchoolsSagaWorker(action) {
  try {
    const response = yield call(getDistributionReportSchoolsRequest, action);
    const results = listToObject(response.data.results, "id");
    const ids = listObjectsToListIds(response.data.results, "id");

    const data = {
      ...response.data,
      results,
      ids,
    };
    yield put({ type: DISTRIBUTION_REPORT_SCHOOLS_SUCCESS, data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: DISTRIBUTION_REPORT_SCHOOLS_FAILURE, error });
  }
}

function* schoolsForCsvTemplateSagaWorker(action) {
  try {
    action.compact = true;
    const response = yield call(fetchAllAdminSchools, action);
    const results = listToObject(response.data.results, "id");
    const ids = listObjectsToListIds(response.data.results, "id");

    const data = {
      ...response.data,
      results,
      ids,
    };

    yield put({ type: SCHOOLS_FOR_CSV_TEMPLATE_SUCCESS, data });
  } catch (error) {
    yield put({ type: SCHOOLS_FOR_CSV_TEMPLATE_FAILURE, error });
  }
}

export const getCommoditiesListWithHistory = (
  state,
  commoditiesList,
  category
) => {
  return commoditiesList.map((commodity) => ({
    ...commodity,
    lastDeliveryDate: getLatestDeliveryByCommodity(
      getAllDeliveries(state),
      commodity.id
    )?.delivered_at,
    lastTakeHomeRationsDate: getLatestTakeHomeRationByCommodity(
      getAllDeliveries(state),
      commodity.id
    )?.delivered_at,
    lastPurchaseDate: getLatestPurchaseByCommodity(
      getAllPurchases(state),
      commodity.id
    )?.purchased_at,
    lastIncidentDate: getLatestIncidentByCommodity(
      getAllStores(state),
      commodity.id,
      category
    )?.occurred_at,
    lastConsumptionDate: getLatestConsumptionByCommodity(
      getAllStores(state),
      commodity.id,
      category
    )?.occurred_on,
  }));
};

/** Returns commodities (linked to school) enriched with
 * `lastDeliveryDate/lastPurchaseDate/lastIncidentDate/lastConsumptionDate` attributes
 */
export const getCurrentSchoolProfileCommoditiesWithHistory = (
  state,
  category
) => {
  const wfpCommoditiesList = getCurrentSchoolProfileWfpCommodities(state);
  const nonWfpCommoditiesList = getCurrentSchoolProfileOtherCommodities(state);
  const allCommoditiesList = wfpCommoditiesList.concat(nonWfpCommoditiesList);
  return getCommoditiesListWithHistory(state, allCommoditiesList, category);
};

/** Returns the latest delivery for that commodity (or undefined) */
export const getLatestDeliveryByCommodity = (stores, commodityId) => {
  const latestDelivery = stores
    .filter(
      (store) =>
        store.model === "delivery" && store.category === deliveryCategory
    )
    .sort((a, b) => new Date(b.delivered_at) - new Date(a.delivered_at))
    .find((delivery) =>
      delivery.commodities.find(
        (commodity) => commodity.commodity === commodityId
      )
    );
  return latestDelivery;
};

/** Returns the latest delivery for that commodity (or undefined) */
export const getLatestTakeHomeRationByCommodity = (stores, commodityId) => {
  const getLatestTakeHomeRation = stores
    .filter(
      (store) =>
        store.model === "delivery" && store.category === takeHomeRationCategory
    )
    .sort((a, b) => new Date(b.delivered_at) - new Date(a.delivered_at))
    .find((delivery) =>
      delivery.commodities.find(
        (commodity) => commodity.commodity === commodityId
      )
    );
  return getLatestTakeHomeRation;
};
/** Returns the latest purchase for that commodity (or undefined) */
export const getLatestPurchaseByCommodity = (stores, commodityId) => {
  const getLatestPurchase = stores
    .filter(
      (store) =>
        store.model === "purchasedetail" &&
        store.category === purchaseDetailCategory
    )
    .sort((a, b) => new Date(b.purchased_at) - new Date(a.purchased_at))
    .find((purchase) =>
      purchase.commodities.find(
        (commodity) =>
          commodity.commodity === commodityId &&
          commodity.category === purchaseDetailCategory
      )
    );

  return getLatestPurchase;
};

/** Returns the latest incident for that commodity (or undefined) */
export const getLatestIncidentByCommodity = (stores, commodityId, category) => {
  const latestIncident = stores
    .filter((store) => store.model === "incident")
    .sort((a, b) => new Date(b.occurred_at) - new Date(a.occurred_at))
    .find((incident) =>
      incident.commodities?.find(
        (commodity) =>
          commodity.commodity === commodityId && commodity.category === category
      )
    );
  return latestIncident;
};

/** Returns the latest consumption for that commodity (or undefined) */
export const getLatestConsumptionByCommodity = (
  stores,
  commodityId,
  category
) => {
  const latestConsumption = stores
    .filter((store) => store.model === "attendance")
    .sort((a, b) => new Date(b.occurred_on) - new Date(a.occurred_on))
    .find((attendance) =>
      attendance.consumption?.commodities?.find(
        (commodity) =>
          commodity.commodity === commodityId && commodity.category === category
      )
    );
  return latestConsumption;
};

const persistedReducer = persistReducer(
  {
    key: "schools",
    storage,
    blacklist: [
      "error",
      "fetching",
      "currentSchool",
      "currentAdminSchool",
      "commoditySourcesError",
      "commoditySourcesFetching",
      "allCommoditySourcesError",
      "allCommoditySourcesFetching",
      "userCommoditySourcesError",
      "userCommoditySourcesFetching",
      "compactSchoolsError",
      "compactSchoolsFetching",
      "extendedSchoolsError",
      "extendedSchoolsFetching",
    ],
  },
  schoolsReducer
);

export default persistedReducer;
