import { useRouteMatch } from "react-router-dom";

type School = {
  id: number;
};

type SchoolMatch = {
  params: {
    schoolId: string;
  };
};

export const buildSchoolUrl = (school: School) => {
  //let url = `/school/${schoolId}
};

export const useSchoolIdFromURL = () => {
  const schoolMatch: SchoolMatch | null = useRouteMatch("/school/:schoolId?");
  const schoolIdFromURL: number | undefined = schoolMatch
    ? parseInt(schoolMatch.params.schoolId)
    : undefined;
  return schoolIdFromURL;
};
