import React from "react";
import { FormattedMessage } from "react-intl";

import SidebarItem from "components/SidebarItem";

export default function OfflineUserItem({
  currentStoreData,
  store,
  ...extraProps
}) {
  const isSelected =
    currentStoreData && currentStoreData.client_id === store.client_id;

  const title = (
    <FormattedMessage id="YearItem.offlineUser" defaultMessage="Offline user" />
  );

  return (
    <SidebarItem
      {...extraProps}
      store={store}
      selected={isSelected}
      id={store.client_id}
      key={store.client_id}
      title={title}
      subTitle={
        <>
          {store.first_name} {store.last_name}
        </>
      }
    ></SidebarItem>
  );
}
