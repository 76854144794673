import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { NavLink, useParams, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faUser } from "@fortawesome/free-solid-svg-icons";

import { Button } from "@wfp/ui";

import SidebarHeader from "components/SidebarHeader";
import ItemSwitcher from "components/ItemSwitcher";
import Gate from "components/Gate";
import {
  getStoreByClientId,
  getCurrentSchoolYear,
  getAllEnrolmentUpdates,
  getAllSchoolYears,
  syncableSortFnDecDateTime,
  getUnsyncedStores,
  getCurrentOrLatestSchoolYear,
  getIsStoresFetching,
} from "data-handler/ducks/stores";
import { getCurrentUser } from "data-handler/ducks/auth";
import { UserPermissionRoles } from "SCConstants";
import { getIsPrincipal, getIsCountryAdmin } from "helpers/users";

import "./_attendance.scss";
import { getIsSchoolGroupAdmin } from "../../helpers/users";

const InfoSidebar = () => {
  const history = useHistory();
  const params = useParams();
  const currentSchoolYear = useSelector(getCurrentSchoolYear);
  const latestSchoolYear = useSelector(getCurrentOrLatestSchoolYear);
  const currentStoreData = useSelector(getStoreByClientId(params.item));
  const unsyncedStores = useSelector(getUnsyncedStores);
  const isFetching = useSelector(getIsStoresFetching);

  const allEnrolmentUpdates = useSelector(getAllEnrolmentUpdates);
  const allSchoolYears = useSelector(getAllSchoolYears);
  const sidebarStores = [...allEnrolmentUpdates, ...allSchoolYears];

  const sortedSidebarStores = useMemo(
    () => sidebarStores.sort(syncableSortFnDecDateTime),
    [sidebarStores]
  );
  const currentUser = useSelector(getCurrentUser);

  return (
    <div className="info-sidebar">
      {(getIsPrincipal(currentUser) ||
        getIsCountryAdmin(currentUser) ||
        getIsSchoolGroupAdmin(currentUser)) && (
        <SidebarHeader>
          <NavLink to={`/school/${params.schoolId}/info/user`}>
            <Button iconReverse icon={<FontAwesomeIcon icon={faUser} />}>
              <FormattedMessage
                id="sideBar.user_management"
                defaultMessage="User management"
              />
            </Button>
          </NavLink>
        </SidebarHeader>
      )}
      <SidebarHeader>
        <Gate forbidRoles={[UserPermissionRoles.is_viewer]}>
          <Button
            kind="accent"
            iconReverse
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => {
              history.push(
                `/school/${params.schoolId}/info/undefined/new-enrolment`
              );
            }}
            // Prevent adding enrolment updates without a current school year or without a previous school year
            disabled={!latestSchoolYear}
          >
            <span className="info-mobile">
              <FormattedMessage
                id="sideBar.enrolment"
                defaultMessage="Enrolment"
              />
            </span>
            <span className="info-desktop">
              <FormattedMessage
                id="sideBar.add_enrolment_update"
                defaultMessage="Add enrolment update"
              />
            </span>
          </Button>
        </Gate>

        <NavLink
          to={`/school/${params.schoolId}/info/${
            currentStoreData ? currentStoreData.client_id : undefined
          }/new-year`}
        >
          <Gate forbidRoles={[UserPermissionRoles.is_viewer]}>
            <Button
              className="disabled-button"
              kind="secondary"
              disabled={
                !!(currentSchoolYear || unsyncedStores.length > 0 || isFetching)
              }
            >
              <FormattedMessage
                id="sideBar.new_school_year"
                defaultMessage="New school year"
              />
              {currentSchoolYear ? (
                <span className="tooltiptext">
                  <FormattedMessage
                    id="sideBar.newSchoolYearDisabled"
                    defaultMessage="You can create a new school year, when the current school year is finished."
                  />
                </span>
              ) : isFetching ? (
                <span className="tooltiptext">
                  <FormattedMessage
                    id="sideBar.newSchoolYearDisabledFetchingData"
                    defaultMessage="You can only create/edit a new school year after all the data is loaded."
                  />
                </span>
              ) : unsyncedStores.length > 0 ? (
                <span className="tooltiptext">
                  <FormattedMessage
                    id="sideBar.newSchoolYearDisabledUnsyncedData"
                    defaultMessage="You can only create a new school year after all the data is synced."
                  />
                </span>
              ) : null}
            </Button>
          </Gate>
        </NavLink>
      </SidebarHeader>
      <>
        {sortedSidebarStores.map((store) => (
          <ItemSwitcher store={store} key={store.client_id} />
        ))}
      </>
    </div>
  );
};

export default InfoSidebar;
