import axios, { AxiosError, AxiosResponse } from "axios";
import { call, put, takeLatest } from "@redux-saga/core/effects";
import storage from "redux-persist/lib/storage";

import { persistReducer } from "redux-persist";
import { RootState } from "data-handler/rootReducer";
export const FOOD_SOURCES_REQUEST = "schoolconnect/foodsources/REQUEST";
export const FOOD_SOURCES_SUCCESS = "schoolconnect/foodsources/SUCCESS";
export const FOOD_SOURCES_FAILURE = "schoolconnect/foodsources/FAILURE";

export type FoodSource = {
  id: number;
  country: number;
  source: string;
};

type FoodSourcesData = {
  count: number;
  next: null | string;
  previous: null | string;
  results: FoodSource[];
};

type State = {
  foodSourcesFetching: boolean;
  foodSourcesError: any;
  foodSourcesData: FoodSourcesData;
};

type FoodSourcesRequestAction = {
  type: typeof FOOD_SOURCES_REQUEST;
  countryId: number;
};

type FoodSourcesSuccessAction = {
  type: typeof FOOD_SOURCES_SUCCESS;
  data: FoodSourcesData;
};

type FoodSourcesFailureAction = {
  type: typeof FOOD_SOURCES_FAILURE;
  error: AxiosError;
};

type Action =
  | FoodSourcesRequestAction
  | FoodSourcesSuccessAction
  | FoodSourcesFailureAction;

const EMPTY_FOOD_SOURCE_DATA: FoodSourcesData = {
  count: 0,
  next: null,
  previous: null,
  results: [],
};

const initialState: State = {
  foodSourcesFetching: false,
  foodSourcesError: null,
  foodSourcesData: EMPTY_FOOD_SOURCE_DATA,
};

const reducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case FOOD_SOURCES_REQUEST:
      return {
        ...state,
        foodSourcesFetching: true,
        foodSourcesError: null,
      };
    case FOOD_SOURCES_SUCCESS:
      return {
        ...state,
        foodSourcesFetching: false,
        foodSourcesError: null,
        foodSourcesData: action.data,
      };
    case FOOD_SOURCES_FAILURE:
      return {
        ...state,
        foodSourcesFetching: false,
        foodSourcesError: action.error,
      };
    default:
      return state;
  }
};

const persistedReducer = persistReducer(
  {
    key: "foodSources",
    storage,
    blacklist: ["foodSourcesFetching", "foodSourcesError"],
  },
  reducer
);

export default persistedReducer;

function fetchFoodSources(action: FoodSourcesRequestAction) {
  const { countryId } = action;
  const url = `${process.env.REACT_APP_API_URL}/food-sources/${
    countryId ? `?country=${countryId}` : ""
  }`;
  return axios({
    method: "GET",
    url: url,
  });
}

export function* workerFoodSourcesSaga(action: FoodSourcesRequestAction) {
  try {
    const response: AxiosResponse = yield call(fetchFoodSources, action);
    const data = {
      ...response.data,
    };
    yield put({ type: FOOD_SOURCES_SUCCESS, data });
  } catch (error) {
    yield put({ type: FOOD_SOURCES_FAILURE, error });
  }
}

export function* foodSourcesSagaWatcher() {
  yield takeLatest(FOOD_SOURCES_REQUEST, workerFoodSourcesSaga);
}

export const requestFoodSources = (countryId: number) => ({
  type: FOOD_SOURCES_REQUEST,
  countryId,
});

export const getFoodSources = (state: RootState): FoodSource[] =>
  state.foodSources.foodSourcesData.results;

export const getSourceById = (id: number) => (state: RootState) =>
  getFoodSources(state).find((foodSource) => foodSource.id === id);

export const getIsFoodSourcesFetching = (state: RootState): boolean =>
  state.foodSources.foodSourcesFetching;

export const getFoodSourcesError = (state: RootState): AxiosError =>
  state.foodSources.foodSourcesError;
