import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Sentry from "@sentry/react";
import { getCurrentUser, getLoggedInOffline } from "data-handler/ducks/auth";
import {
  getAllCommoditySourcesError,
  getAllCommoditySourcesFetching,
  getCommoditySourcesForUserError,
  getCommoditySourcesForUserFetching,
  getCompactSchoolsError,
  getCompactSchoolsFetching,
  getExtendedSchoolsError,
  getExtendedSchoolsFetching,
  requestAllCommoditySources,
  requestCommoditySourcesForUser,
  requestCompactSchools,
  requestExtendedSchools,
} from "data-handler/ducks/schools";
import {
  getRolesError,
  getIsRolesFetching,
  requestRoles,
} from "data-handler/ducks/roles";
import { getCanUseOfflineMode, getIsInternal } from "helpers/users";
import {
  requestUserStores,
  getIsStoresFetching,
  getStoresError,
} from "data-handler/ducks/stores";
import commoditiesDuck from "data-handler/ducks/commodities";
import {
  requestLinks,
  getLinksFetching,
  getLinksError,
} from "data-handler/ducks/links";

import {
  getIsVendorCategoriesFetching,
  getVendorCategoriesError,
  requestVendorCategories,
} from "data-handler/ducks/vendors";
import incidentCausesDuck from "data-handler/ducks/incidentCauses";
import deliveryIssuesDuck from "data-handler/ducks/deliveryIssues";

import {
  FetchingData,
  getIsInitializationRequired,
  setIsInitializationRequired,
  setInitializerData,
  setInitializerHasErrors,
  setInitializerIsFetching,
} from "data-handler/ducks/initialization";

const logout = () => window.location.replace("/logout");

type InitializerProps = {};

/**
 * If requireInitialization is triggered, initialize the data before rendering the children.
 */
const Initializer: React.ComponentType<InitializerProps> = ({ children }) => {
  const dispatch = useDispatch();

  const [isFetchingStarted, setFetchingStarted] = useState<boolean>(false);

  const currentUser = useSelector(getCurrentUser);
  const canUseOfflineMode = getCanUseOfflineMode(currentUser);
  const isOffline = useSelector(getLoggedInOffline);
  const isInitializationRequired = useSelector(getIsInitializationRequired);

  const isInternal = getIsInternal(currentUser?.email);
  const isLinksFetching = useSelector(getLinksFetching);

  // Get fetching related data
  const isCompactSchoolsFetching = useSelector(getCompactSchoolsFetching);
  const isExtendedSchoolsFetching = useSelector(getExtendedSchoolsFetching);
  const isRolesFetching = useSelector(getIsRolesFetching);
  const isCommoditiesFetching: boolean = useSelector(
    commoditiesDuck.isFetching()
  );
  const isCommoditySourcesForUserFetching = useSelector(
    getCommoditySourcesForUserFetching
  );
  const isAllCommoditySourcesFetching = useSelector(
    getAllCommoditySourcesFetching
  );
  const isStoresFetching = useSelector(getIsStoresFetching);
  const isVendorsCategoriesFetching = useSelector(
    getIsVendorCategoriesFetching
  );
  const isIncidentCausesFetching = useSelector(incidentCausesDuck.isFetching());
  const isDeliveryIssuesFetching = useSelector(deliveryIssuesDuck.isFetching());
  const storesError = useSelector(getStoresError);
  const compactSchoolsError = useSelector(getCompactSchoolsError);
  const extendedSchoolsError = useSelector(getExtendedSchoolsError);
  const rolesError = useSelector(getRolesError);
  const commoditiesError = useSelector(commoditiesDuck.getError);
  const commoditySourcesForUserError = useSelector(
    getCommoditySourcesForUserError
  );
  const allCommoditySourcesError = useSelector(getAllCommoditySourcesError);
  const linksError = useSelector(getLinksError);
  const vendorsCategoriesError = useSelector(getVendorCategoriesError);
  const incidentCausesError = useSelector(incidentCausesDuck.getError);
  const deliveryIssuesError = useSelector(deliveryIssuesDuck.getError);

  useEffect(() => {
    if (isInitializationRequired) {
      const fetchingData: FetchingData[] = [
        { name: "Stores", isFetching: isStoresFetching, error: storesError },
        {
          name: "Compact Schools",
          isFetching: isCompactSchoolsFetching,
          error: compactSchoolsError,
        },
        {
          name: "Extended Schools",
          isFetching: isExtendedSchoolsFetching,
          error: extendedSchoolsError,
        },
        { name: "Roles", isFetching: isRolesFetching, error: rolesError },
        {
          name: "Commodities",
          isFetching: isCommoditiesFetching,
          error: commoditiesError,
        },
        {
          name: "Commodity Sources For User",
          isFetching: isCommoditySourcesForUserFetching,
          error: commoditySourcesForUserError,
        },
        {
          name: "All Commodity Sources",
          isFetching: isAllCommoditySourcesFetching,
          error: allCommoditySourcesError,
        },
        { name: "Links", isFetching: isLinksFetching, error: linksError },
        {
          name: "Vendors Categories",
          isFetching: isVendorsCategoriesFetching,
          error: vendorsCategoriesError,
        },
        {
          name: "Incident Causes",
          isFetching: isIncidentCausesFetching,
          error: incidentCausesError,
        },
        {
          name: "Delivery Issues",
          isFetching: isDeliveryIssuesFetching,
          error: deliveryIssuesError,
        },
      ];

      const isFetching = fetchingData.some((p) => p.isFetching);
      const hasErrors = fetchingData.some((p) => p.error);
      const has401 = fetchingData.some(
        (p) => p.error?.response?.status === 401
      );

      if (has401) {
        logout();
      }

      dispatch(setInitializerData(fetchingData));
      dispatch(setInitializerIsFetching(isFetching));
      dispatch(setInitializerHasErrors(hasErrors));

      if (isInitializationRequired && isFetchingStarted && !isFetching) {
        if (hasErrors) {
          // Fetching is complete but there are errors
          Sentry.configureScope((scope) => {
            scope.setExtra("error_location", "Initializer/index.tsx");

            for (const data of fetchingData) {
              scope.setExtra(data.name, JSON.stringify(data));
            }
            scope.setLevel(Sentry.Severity.Warning);
            Sentry.captureMessage("Initialization completed with errors.");
          });
        } else {
          // Fetching is complete and there is no errors.
          dispatch(setIsInitializationRequired(false));
        }
      }
    }
  }, [
    allCommoditySourcesError,
    commoditiesError,
    commoditySourcesForUserError,
    compactSchoolsError,
    deliveryIssuesError,
    dispatch,
    extendedSchoolsError,
    incidentCausesError,
    isAllCommoditySourcesFetching,
    isCommoditiesFetching,
    isCommoditySourcesForUserFetching,
    isCompactSchoolsFetching,
    isDeliveryIssuesFetching,
    isExtendedSchoolsFetching,
    isFetchingStarted,
    isIncidentCausesFetching,
    isLinksFetching,
    isRolesFetching,
    isStoresFetching,
    isVendorsCategoriesFetching,
    linksError,
    isInitializationRequired,
    rolesError,
    storesError,
    vendorsCategoriesError,
  ]);

  useEffect(() => {
    if (!isInitializationRequired || isFetchingStarted) return;
    // If user is not currently running in offline mode
    // and requireInitialization was triggered,
    // Initialize data we need for offline mode to work
    if (isOffline) {
      dispatch(setIsInitializationRequired(false));
    } else {
      setFetchingStarted(true);
      // Fetch Roles as both Country Admin and Schools Require it.
      dispatch(requestRoles());
      // getAllCommodities
      dispatch(requestAllCommoditySources());
      dispatch(requestCommoditySourcesForUser());
      dispatch(requestLinks({ type: "dashboard", internal: isInternal }));
      dispatch(requestVendorCategories());
      dispatch(incidentCausesDuck.fetchList());
      dispatch(deliveryIssuesDuck.fetchList());
      dispatch(commoditiesDuck.fetchList());

      // Fetch all info needed for the offline capability for admin users
      // that is not used in this particular page.
      if (canUseOfflineMode) {
        // Fetch all schools on opening modal
        dispatch(requestCompactSchools());
        dispatch(requestExtendedSchools());
        // This will download all Stores user has access to and
        // save it to state.stores.userStores
        // Later we use this in setUserCachedStores function.
        dispatch(requestUserStores());
      }
    }
  }, [
    dispatch,
    canUseOfflineMode,
    isFetchingStarted,
    isInitializationRequired,
    isInternal,
    isOffline,
  ]);
  return <>{children}</>;
};

export default Initializer;
