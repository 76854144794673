import { getRolesModule } from "data-handler/ducks/roles";
import { useSelector } from "react-redux";
import { CountryAdminLabel } from "SCConstants";

type RoleType =
  | "global_administrator"
  | "country_administrator"
  | "principal"
  | "teacher"
  | "store_keeper"
  | "approver"
  | "wfp_viewer"
  | "external_viewer"
  | "school_group_administrator";

type Role = {
  id: number;
  type: RoleType;
  default_name: string;
};

type CountryRole = {
  role: Role;
  country: number;
  custom_name: string;
};

/**
 * Do not pass current User Role if global Administrator should not be an item in the list
 * Example: Global admin should not be customised therefore should not be in the list and no is_admin boolean is passed.
 */
export const generateCustomRoleList = (
  roles: Role[],
  roleMapping: CountryRole[],
  _language: string,
  isUserAdmin: boolean = false
) => {
  return roles
    ?.filter((item) =>
      isUserAdmin ? true : item.type !== "global_administrator"
    )
    ?.map((item) => {
      const roleMappingObject = roleMapping?.find((e) => e.role.id === item.id);
      return {
        role: item.default_name,
        custom_name: roleMappingObject?.custom_name,
        id: item.id,
        type: item.type,
      };
    });
};

/**
 * This function returns the correct label for a user role for the row within the user table
 */
export const GetRoleLabel = (user: { user_role: string }) => {
  const {
    roles,
    countryRoles,
  }: { roles: Role[]; countryRoles: CountryRole[] } = useSelector(
    getRolesModule
  );

  if (user?.user_role) {
    const role = countryRoles.find((item) => item.role.type === user.user_role);
    const defaultName = roles.find((item) => item.type === user.user_role)
      ?.default_name;
    const roleLabel =
      role?.custom_name && role?.custom_name !== ""
        ? role?.custom_name
        : defaultName;

    return roleLabel;
  } else {
    return CountryAdminLabel.find(
      (item) => item.name === "countryAdmin.noRole"
    )!.label;
  }
};
