import React from "react";
import { FormattedMessage } from "react-intl";
import { NavLink, useParams } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";

import { Button, Module, ModuleBody, List, ListItem } from "@wfp/ui";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faQuestion } from "@fortawesome/free-solid-svg-icons";

import Empty from "components/Empty";
import MainContent from "components/MainContent";
import TableExtended from "components/TableExtended";
import LevelTitle from "components/LevelTitle";
import SubTitle from "components/SubTitle";
import CommodityShow from "components/CommodityShow";
import UneditableYear from "components/YearShow/uneditableYear";
import { getModelDateFormat } from "components/UneditableMessage";

import { studentKinds } from "SCConstants";

import Gate from "../Gate";
import { UserPermissionRoles } from "../../SCConstants";
import { getLanguage } from "data-handler/ducks/language";

import {
  getSchoolYearByDate,
  getIsStoresFetching,
  getCurrentOrLatestSchoolYear,
} from "data-handler/ducks/stores";

import { getInitialStockDeliveryByDate } from "helpers/stock";

import "./styles.scss";
import styles from "./styles.module.scss";

const YearShow = ({ currentStoreData }) => {
  const params = useParams();
  const editUrl = `/school/${params.schoolId}/info/${params.item}/year`;
  const language = useSelector(getLanguage);
  const isFetching = useSelector(getIsStoresFetching);

  moment.locale(language);
  let weekdays = moment.weekdays();
  weekdays.push(weekdays.shift());

  const startDateTitle = (
    <FormattedMessage id="yearShow.start_date" defaultMessage="Start date" />
  );
  const endDateTitle = (
    <FormattedMessage id="yearShow.end_date" defaultMessage="End date" />
  );

  const schoolDaysTitle = (
    <FormattedMessage id="yearShow.school_days" defaultMessage="School days" />
  );

  const classesTitle = (
    <FormattedMessage id="yearShow.classes" defaultMessage="Classes" />
  );

  const selectedSchoolYear = useSelector(
    getSchoolYearByDate(
      getModelDateFormat(currentStoreData) || moment().format("YYYY-MM-DD")
    )
  );

  const currentOrLatestSchoolYear = useSelector(getCurrentOrLatestSchoolYear);

  const initialStockDelivery = useSelector((state) =>
    getInitialStockDeliveryByDate(state, currentStoreData?.starts_on)
  );

  // Combine the quantity of the same commodities in the opening stock
  const commoditiesOpeningStockObject = initialStockDelivery?.commodities
    .filter((deliveryCommodity) => (deliveryCommodity.is_carry_over = true))
    .reduce((acc, cur) => {
      let { commodity, ...remaining } = cur;
      return {
        ...acc,
        [commodity]: {
          ...remaining,
          quantity: (acc[commodity]?.quantity || 0) + remaining.quantity,
        },
      };
    }, {});

  const commoditiesOpeningStock = commoditiesOpeningStockObject
    ? Object.keys(commoditiesOpeningStockObject).map((commodity) => ({
        commodity,
        ...commoditiesOpeningStockObject[commodity],
      }))
    : [];

  if (!currentStoreData) {
    return (
      <Empty
        kind="section"
        icon={<FontAwesomeIcon icon={faQuestion} size="1x" />}
        title="Incidents"
        button={
          <NavLink to={editUrl}>
            {" "}
            <Button
              kind="accent"
              icon={<FontAwesomeIcon icon={faChevronRight} />}
            >
              <FormattedMessage
                id="yearShow.provide_incidents"
                defaultMessage="Provide incidents"
              />
            </Button>{" "}
          </NavLink>
        }
      >
        <FormattedMessage
          id="yearShow.no_school_found"
          defaultMessage="No school year found"
        />
      </Empty>
    );
  }

  const classesTimeTitle =
    currentStoreData?.has_morning_classes &&
    currentStoreData?.has_afternoon_classes ? (
      <FormattedMessage
        id="yearShow.morningAndAfternoonClasses"
        defaultMessage="Morning and afternoon classes"
      />
    ) : currentStoreData?.has_morning_classes ? (
      <FormattedMessage
        id="YearEdit.morning_classes"
        defaultMessage="Morning classes"
      />
    ) : currentStoreData?.has_afternoon_classes ? (
      <FormattedMessage
        id="YearEdit.afternoon_classes"
        defaultMessage="Afternoon classes"
      />
    ) : (
      ""
    );

  const classesStudentsTitle = currentStoreData?.has_same_students_in_both_shifts ? (
    <FormattedMessage
      id="YearEdit.same_students_in_afternoon_as_morning"
      defaultMessage="Same students in afternoon as in morning classes"
    />
  ) : (
    <FormattedMessage
      id="yearShow.different_students_in_afternoon_as_morning"
      defaultMessage="Different students in afternoon as in morning classes"
    />
  );

  return (
    <MainContent
      subTitle={`${moment(currentStoreData.starts_on).format(
        "DD.MM.YYYY"
      )} – ${moment(currentStoreData.ends_on).format("DD.MM.YYYY")}`}
      className="school-year-show"
      right={
        <Gate
          isForbidden={selectedSchoolYear !== currentOrLatestSchoolYear}
          forbidRoles={[UserPermissionRoles.is_viewer]}
        >
          <NavLink to={editUrl}>
            <Button
              className="disabled-button-school-year-show"
              kind="secondary"
              icon={<FontAwesomeIcon icon={faChevronRight} />}
              disabled={isFetching}
            >
              <FormattedMessage
                id="yearShow.edit_school_year"
                defaultMessage="Edit school year"
              />
              {isFetching && (
                <span className="tooltiptext">
                  <FormattedMessage
                    id="sideBar.newSchoolYearDisabledFetchingData"
                    defaultMessage="You can only create/edit a new school year after all the data is loaded."
                  />
                </span>
              )}
            </Button>
          </NavLink>
        </Gate>
      }
    >
      <UneditableYear
        currentStoreData={{
          model: "year",
          starts_on: currentStoreData?.starts_on,
        }}
      />
      <Module noMargin>
        <ModuleBody>
          <>
            <List kind="details" colon>
              <ListItem title={startDateTitle}>
                {currentStoreData.starts_on ? (
                  moment(currentStoreData.starts_on).format("dddd, DD.MM.YYYY")
                ) : (
                  <FormattedMessage
                    id="yearShow.no_date_found"
                    defaultMessage="No date found"
                  />
                )}
              </ListItem>

              <ListItem title={endDateTitle}>
                {currentStoreData?.ends_on ? (
                  moment(currentStoreData?.ends_on).format("dddd, DD.MM.YYYY")
                ) : (
                  <FormattedMessage
                    id="yearShow.no_date_found"
                    defaultMessage="No date found"
                  />
                )}
              </ListItem>

              <ListItem title={schoolDaysTitle} className={styles.weekdayList}>
                {weekdays.map(
                  (weekday, i) =>
                    currentStoreData.weekdays[i] && <span>{weekday}</span>
                )}
              </ListItem>

              <ListItem title={classesTitle}>
                <li>{classesTimeTitle}</li>
                <li>{classesStudentsTitle}</li>
              </ListItem>
            </List>
          </>
        </ModuleBody>
      </Module>

      <SubTitle>
        <FormattedMessage
          id="yearShow.initial_enrolment"
          defaultMessage="Initial enrolment"
        />
      </SubTitle>
      <Module noMargin>
        <ModuleBody noPadding>
          <TableExtended>
            <thead>
              <tr>
                <th>{/* First cell is empty */}</th>
                {studentKinds.map((kind) => (
                  <th>{kind.labelTrans}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentStoreData.levels
                .sort((a, b) => (a.level < b.level ? -1 : 1))
                .map((level, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <LevelTitle level={level} />
                      </td>
                      {studentKinds.map((kind, i) => (
                        <td key={i}>{level[`initial_${kind.value}`]}</td>
                      ))}
                    </tr>
                  );
                })}
            </tbody>
          </TableExtended>
        </ModuleBody>
      </Module>
      {commoditiesOpeningStock?.length > 0 ? (
        <div>
          <SubTitle>
            <FormattedMessage
              id="yearShow.openingStock"
              defaultMessage="Opening stock"
            />
          </SubTitle>
          <CommodityShow
            model={currentStoreData.model}
            commodities={commoditiesOpeningStock}
          />
        </div>
      ) : (
        ""
      )}
    </MainContent>
  );
};

export default YearShow;
