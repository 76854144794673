import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import {
  Button,
  MainNavigation,
  MainNavigationItem,
  User,
  InlineLoading,
  InfoBar,
} from "@wfp/ui";

import { getCurrentUser } from "data-handler/ducks/auth";
import {
  getUnsyncedStores,
  getIsStoresFetching,
} from "data-handler/ducks/stores";
import { getCurrentSchool } from "data-handler/ducks/schools";

import UserNavigation from "components/UserNavigation";
import TabLink from "components/TabLink";

import classNames from "classnames";

import "./_content.scss";

class MainNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.child = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.location && this.props.location) {
      this.closeMenu();
    }
  }

  closeMenu = () => {
    this.child.current.onChangeSub("close");
  };

  render() {
    const { currentUser, showMenu, match } = this.props;
    const isViewer =
      currentUser?.is_external_viewer || currentUser?.is_wfp_viewer;

    if (!match) {
      return null;
    }

    const contentClasses = classNames("content", {
      content__active: !showMenu,
    });

    return (
      <div className={contentClasses}>
        {process.env.REACT_APP_ENV &&
          process.env.REACT_APP_ENV !== "production" && (
            <div
              className={`environment-indicator ${process.env.REACT_APP_ENV}`}
            >
              {process.env.REACT_APP_ENV}
            </div>
          )}
        <MainNavigation
          className="content__header"
          pageWidth="full"
          logo={
            !currentUser ? null : (
              <>
                <NavLink
                  exact
                  to={"?select=school"}
                  renderListElement={TabLink}
                >
                  <Button className="country-header">
                    {currentUser.is_admin ? (
                      <FormattedMessage
                        id="CountryAdmin.administrator"
                        defaultMessage="Administrator"
                      />
                    ) : !currentUser.country.name ? (
                      <InlineLoading />
                    ) : (
                      <> {currentUser.country.name}</>
                    )}
                  </Button>
                </NavLink>
              </>
            )
          }
          ref={this.child}
        >
          {() => {
            return (
              <>
                <MainNavigationItem>
                  <NavLink exact to={"/countryAdmin"}>
                    <FormattedMessage
                      id="CountryAdmin.countryProfile"
                      defaultMessage="Country Profile"
                    />
                  </NavLink>
                </MainNavigationItem>
                <MainNavigationItem>
                  <NavLink exact to={"/countryAdmin/distributionReport/"}>
                    <FormattedMessage
                      id="CountryAdmin.distributionReport"
                      defaultMessage="Distribution Report"
                    />
                  </NavLink>
                </MainNavigationItem>
                <MainNavigationItem>
                  <NavLink to={"/countryAdmin/users/"}>
                    <FormattedMessage
                      id="CountryAdmin.users"
                      defaultMessage="Users"
                    />
                  </NavLink>
                </MainNavigationItem>
                <MainNavigationItem>
                  <NavLink to={"/countryAdmin/schools/"}>
                    <FormattedMessage
                      id="CountryAdmin.schools"
                      defaultMessage="Schools"
                    />
                  </NavLink>
                </MainNavigationItem>
                <MainNavigationItem>
                  <NavLink to={"/countryAdmin/schoolProfiles/"}>
                    <FormattedMessage
                      id="CountryAdmin.schoolProfiles"
                      defaultMessage="School Profiles"
                    />
                  </NavLink>
                </MainNavigationItem>
                <MainNavigationItem>
                  <NavLink to={"/countryAdmin/vendors/"}>
                    <FormattedMessage
                      id="CountryAdmin.vendors"
                      defaultMessage="Vendors"
                    />
                  </NavLink>
                </MainNavigationItem>
                <MainNavigationItem
                  className="wfp--main-navigation__user"
                  subNavigation={<UserNavigation />}
                >
                  <User
                    ellipsis
                    name={currentUser ? currentUser.last_name : "no name"}
                    missingImage="letter"
                  />
                </MainNavigationItem>
              </>
            );
          }}
        </MainNavigation>
        {isViewer ? (
          <InfoBar>
            <FormattedMessage
              id="menu.WFPReadOnlyMessage"
              defaultMessage="You are logged in read-only mode. To change it, please contact your administrator."
            />
          </InfoBar>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const currentSchool = getCurrentSchool(state);
  const currentSchoolName =
    (currentSchool && currentSchool.name) || "Select school";
  return {
    currentSchoolName,
    fetchingStores: getIsStoresFetching(state),
    currentUser: getCurrentUser(state),
    showMenu: state.showMenu, // TODO: this is always undefined, what does it do?
    language: state.language,
    unsyncedItems: getUnsyncedStores(state),
  };
};

export default connect(mapStateToProps)(MainNav);
