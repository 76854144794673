import { getCurrentUser } from "data-handler/ducks/auth";
import { getCurrentSchool } from "data-handler/ducks/schools";
import {
  POST_SUCCESS,
  RESET_SYNC,
  SUCCESS,
  UPDATE,
} from "data-handler/ducks/stores";

const customMiddleware = (store: any) => (next: any) => (action: any) => {
  switch (action.type) {
    case UPDATE:
    case RESET_SYNC:
    case POST_SUCCESS:
      // Add the current school and user to the action's payload
      return next({
        ...action,
        school: getCurrentSchool(store.getState()),
        user: getCurrentUser(store.getState()),
      });
    case SUCCESS:
      // Add the current user to the action's payload
      return next({
        ...action,
        user: getCurrentUser(store.getState()),
      });
    default:
      return next(action);
  }
};

export default customMiddleware;
