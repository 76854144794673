import { call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { errorToast, successToast } from "data-handler/ducks/toast";

const GET_COUNTRY_ROLE_MAPPING_REQUEST = "GET_COUNTRY_ROLE_MAPPING_REQUEST";
const GET_COUNTRY_ROLE_MAPPING_SUCCESS = "GET_COUNTRY_ROLE_MAPPING_SUCCESS";
const POST_COUNTRY_ROLE_MAPPING_REQUEST = "POST_COUNTRY_ROLE_MAPPING_REQUEST";
const POST_COUNTRY_ROLE_MAPPING_SUCCESS = "POST_COUNTRY_ROLE_MAPPING_SUCCESS";
const GET_ROLES_REQUEST = "GET_ROLES_REQUEST";
const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
const FAILURE = "FAILURE";

const initialState = {
  fetching: false,
  error: null,
  errorResponse: null,
  countryRoles: [],
  roles: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case POST_COUNTRY_ROLE_MAPPING_REQUEST:
    case GET_ROLES_REQUEST:
    case GET_COUNTRY_ROLE_MAPPING_REQUEST:
      return { ...state, fetching: true, error: null };
    case GET_COUNTRY_ROLE_MAPPING_SUCCESS:
      return {
        ...state,
        countryRoles: action.data,
        fetching: false,
        error: null,
      };
    case GET_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.data,
        fetching: false,
        error: null,
      };
    case POST_COUNTRY_ROLE_MAPPING_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
      };
    case FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    default:
      return state;
  }
}

const persistedReducer = persistReducer(
  {
    key: "roles",
    storage,
    blacklist: ["error", "errorResponse", "fetching"],
  },
  reducer
);

export default persistedReducer;

// Action
export const requestCountryRoleMapping = (country) => ({
  type: GET_COUNTRY_ROLE_MAPPING_REQUEST,
  country,
});

export const requestRoles = () => ({
  type: GET_ROLES_REQUEST,
});

export const postCountryRoleMapping = (data) => ({
  type: POST_COUNTRY_ROLE_MAPPING_REQUEST,
  data,
});

// Saga
export function* getCountryAdminRoleMappingSagaWatcher() {
  yield takeEvery(GET_COUNTRY_ROLE_MAPPING_REQUEST, roleMappingSagaWorker);
}

export function* getCountryAdminRolesSagaWatcher() {
  yield takeEvery(GET_ROLES_REQUEST, getRolesSagaWorker);
}

export function* postCountryAdminRolesSagaWatcher() {
  yield takeEvery(POST_COUNTRY_ROLE_MAPPING_REQUEST, postRolesSagaWorker);
}

function fetchRoles(action) {
  const url = `${process.env.REACT_APP_API_URL}/roles/`;

  return axios({
    method: "GET",
    url: url,
  });
}

function* getRolesSagaWorker(payload) {
  try {
    const response = yield call(fetchRoles, payload);

    yield put({
      type: GET_ROLES_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: FAILURE, error });
  }
}

function fetchCountryRoleMapping(action) {
  const country = action.country;

  const url =
    `${process.env.REACT_APP_API_URL}/role-mapping/` +
    (country ? `?country=${country}` : "");

  return axios({
    method: "GET",
    url: url,
  });
}

function* roleMappingSagaWorker(payload) {
  try {
    const response = yield call(fetchCountryRoleMapping, payload);

    yield put({
      type: GET_COUNTRY_ROLE_MAPPING_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: FAILURE, error });
  }
}

function postRoles(payload) {
  const url = `${process.env.REACT_APP_API_URL}/role-mapping/`;

  return axios({
    method: "POST",
    url: url,
    data: payload.data,
  });
}

function* postRolesSagaWorker(payload) {
  try {
    yield call(postRoles, payload.data);

    yield put({
      type: POST_COUNTRY_ROLE_MAPPING_SUCCESS,
    });
    yield put(requestCountryRoleMapping(payload.data.country));
    yield put(successToast());
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: FAILURE, error });
    yield put(errorToast());
  }
}

// Selectors
export const getCountryRoleMappings = (state) => state.roles.countryRoles;
// TODO Remove getRolesModule and create specific selectors for each sections.
export const getRolesModule = (state) => state.roles;
export const getIsRolesFetching = (state) => state.roles.fetching;
export const getRolesError = (state) => state.roles.error;
