import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { listObjectsToListIds, listToObject } from "./paginationTools";
import { successToast, errorToast } from "data-handler/ducks/toast";
import { toastFormattedMessages } from "SCConstants";
import { camelToSnakeCase } from "./utils";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const ADMIN_VENDORS_REQUEST = "schoolconnect/vendors/VENDORS_REQUEST";
export const ADMIN_VENDORS_SUCCESS = "schoolconnect/vendors/VENDORS_SUCCESS";
export const ADMIN_VENDORS_FAILURE = "schoolconnect/vendors/VENDORS_FAILURE";

export const ALL_APPROVED_VENDORS_REQUEST =
  "schoolconnect/vendors/ALL_APPROVED_VENDORS_REQUEST";
export const ALL_APPROVED_VENDORS_SUCCESS =
  "schoolconnect/vendors/ALL_APPROVED_VENDORS_SUCCESS";
export const ALL_APPROVED_VENDORS_FAILURE =
  "schoolconnect/vendors/ALL_APPROVED_VENDORS_FAILURE";

export const VENDOR_CATEGORY_REQUEST =
  "schoolconnect/vendors/VENDOR_CATEGORY_REQUEST";
export const VENDOR_CATEGORY_SUCCESS =
  "schoolconnect/vendors/VENDOR_CATEGORY_SUCCESS";
export const VENDOR_CATEGORY_FAILURE =
  "schoolconnect/vendors/VENDOR_CATEGORY_FAILURE";

export const PERFORM_VENDOR_ACTION_REQUEST =
  "schoolconnect/vendors/PERFORM_VENDOR_ACTION_REQUEST";
export const PERFORM_VENDOR_ACTION_SUCCESS =
  "schoolconnect/vendors/PERFORM_VENDOR_ACTION_SUCCESS";
export const PERFORM_VENDOR_ACTION_FAILURE =
  "schoolconnect/vendors/PERFORM_VENDOR_ACTION_FAILURE";

const initialState = {
  fetching: false,
  error: null,
  errorResponse: null,
  index: {},
  allApprovedVendorsList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  adminVendorsList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  vendorCategoryList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case ALL_APPROVED_VENDORS_REQUEST:
    case ADMIN_VENDORS_REQUEST:
      return { ...state, fetching: true, error: null };
    case ALL_APPROVED_VENDORS_FAILURE:
    case ADMIN_VENDORS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    case ALL_APPROVED_VENDORS_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        allApprovedVendorsList: action.data,
      };
    case ADMIN_VENDORS_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        adminVendorsList: action.data,
      };
    case VENDOR_CATEGORY_REQUEST:
      return { ...state, fetching: true, error: null };
    case VENDOR_CATEGORY_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
        errorResponse: action.error.response,
      };
    case VENDOR_CATEGORY_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        vendorCategoryList: action.data,
      };
    default:
      return state;
  }
}

const persistedReducer = persistReducer(
  {
    key: "vendors",
    storage,
    blacklist: ["error", "errorResponse", "fetching"],
  },
  reducer
);

export default persistedReducer;

export const getAdminVendors = (state) => state.vendors.adminVendorsList;
export const getAllApprovedVendors = (state) =>
  state.vendors.allApprovedVendorsList;
export const getVendorCategories = (state) => state.vendors.vendorCategoryList;
export const getIsVendorCategoriesFetching = (state) => state.vendors.fetching;
export const getVendorCategoriesError = (state) => state.vendors.error;
export const requestAdminVendors = (pageSize, pageIndex, ordering, filters) => {
  return {
    type: ADMIN_VENDORS_REQUEST,
    pageSize,
    pageIndex,
    ordering,
    filters,
  };
};

export const requestVendorCategories = () => ({
  type: VENDOR_CATEGORY_REQUEST,
});

export const requestAllApprovedVendors = (countryId) => ({
  type: ALL_APPROVED_VENDORS_REQUEST,
  countryId,
});

export const performVendorAction = (actionLabel, vendorId) => ({
  type: PERFORM_VENDOR_ACTION_REQUEST,
  actionLabel,
  vendorId,
});
export function* adminVendorsSagaWatcher() {
  yield takeLatest(ADMIN_VENDORS_REQUEST, adminVendorsSagaWorker);
}

export function* allApprovedVendorsSagaWatcher() {
  yield takeLatest(ALL_APPROVED_VENDORS_REQUEST, allApproveVendorsSagaWorker);
}
export function* vendorCategoriesSagaWatcher() {
  yield takeLatest(VENDOR_CATEGORY_REQUEST, vendorCategoriesSagaWorker);
}

export function* performVendorActionSagaWatcher() {
  yield takeLatest(
    PERFORM_VENDOR_ACTION_REQUEST,
    performVendorActionSagaWorker
  );
}

function fetchVendors(action) {
  const page_size = action.pageSize;
  const page = action.pageIndex * page_size - page_size;
  const filters = action.filters;
  const ordering = action.ordering.replace(/\./g, "_");
  const { countryId } = action;
  const url =
    `${process.env.REACT_APP_API_URL}/vendors/` +
    `?limit=${page_size}&offset=${page}` +
    (ordering ? `&ordering=${ordering}` : "") +
    (filters && filters.length
      ? filters
          .map((filter) => {
            const id = filter.id;
            const value = filter.value;
            if (value && value !== "") {
              return `&${id}=${value}`;
            }
            return "";
          })
          .join("")
      : "");
  const params = {
    country: countryId,
  };
  return axios({
    method: "GET",
    url: url,
    params,
  });
}

function fetchAllApprovedVendors(action) {
  const { countryId } = action;
  const url = `${process.env.REACT_APP_API_URL}/vendors/`;
  const params = {
    country: countryId,
    state: "admin_approved",
  };
  return axios({
    method: "GET",
    url: url,
    params,
  });
}

function* adminVendorsSagaWorker(action) {
  try {
    const response = yield call(fetchVendors, action);
    const results = listToObject(response.data.results, "id");
    const ids = listObjectsToListIds(response.data.results, "id");

    const data = {
      ...response.data,
      results,
      ids,
    };
    yield put({ type: ADMIN_VENDORS_SUCCESS, data });
  } catch (error) {
    yield put({ type: ADMIN_VENDORS_FAILURE, error });
  }
}
function* allApproveVendorsSagaWorker(action) {
  try {
    const response = yield call(fetchAllApprovedVendors, action);
    const data = {
      ...response.data,
    };
    yield put({ type: ALL_APPROVED_VENDORS_SUCCESS, data });
  } catch (error) {
    yield put({ type: ALL_APPROVED_VENDORS_FAILURE, error });
  }
}

function fetchVendorCategories() {
  const url = `${process.env.REACT_APP_API_URL}/vendor-categories/`;
  const params = {
    limit: 99999,
  };
  return axios({
    method: "GET",
    url: url,
    params,
  });
}

function* vendorCategoriesSagaWorker(action) {
  try {
    const response = yield call(fetchVendorCategories, action);
    const results = listToObject(response.data.results, "id");
    const ids = listObjectsToListIds(response.data.results, "id");

    const data = {
      ...response.data,
      results,
      ids,
    };
    yield put({ type: VENDOR_CATEGORY_SUCCESS, data });
  } catch (error) {
    yield put({ type: VENDOR_CATEGORY_FAILURE, error });
  }
}

function putVendorAction(action) {
  const { actionLabel, vendorId } = action;
  const perform = camelToSnakeCase(actionLabel);
  const url = `${process.env.REACT_APP_API_URL}/vendors/${vendorId}/perform/${perform}/`;
  return axios({
    method: "PUT",
    url: url,
  });
}

function* performVendorActionSagaWorker(action) {
  try {
    const response = yield call(putVendorAction, action);
    const data = response.data;

    yield put({
      type: PERFORM_VENDOR_ACTION_SUCCESS,
      data,
    });
    yield put(
      successToast(
        toastFormattedMessages.find(
          (e) => e.name === `toast.${action.actionLabel}Success`
        ).label
      )
    );
    window.location.reload();
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: PERFORM_VENDOR_ACTION_FAILURE,
      error,
    });
    yield put(
      errorToast(
        toastFormattedMessages.find(
          (e) => e.name === `toast.${action.actionLabel}Error`
        ).label
      )
    );
  }
}
