import axios from "axios";
import React from "react";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faBug } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import {
  Button,
  SubNavigationFilter,
  SubNavigation,
  SubNavigationList,
  SubNavigationGroup,
  SubNavigationContent,
  Link,
  SubNavigationTitle,
  SubNavigationItem,
  SubNavigationHeader,
} from "@wfp/ui";

import { changeLanguage } from "data-handler/ducks/language";
import commoditiesDuck from "data-handler/ducks/commodities";
import deliveryIssuesDuck from "data-handler/ducks/deliveryIssues";
import incidentCausesDuck from "data-handler/ducks/incidentCauses";

import styles from "./styles.module.scss";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import {
  getCurrentUser,
  setViewLayout,
  getIsSchoolStaff,
  getLoggedInOffline,
} from "data-handler/ducks/auth";
import {
  requestCountryRoleMapping,
  requestRoles,
} from "data-handler/ducks/roles";
import {
  getCurrentSchool,
  requestCurrentSchool,
} from "data-handler/ducks/schools";
import { getSelectedCountry } from "data-handler/ducks/countryProfile";
import { requestLevelGroupMappings } from "data-handler/ducks/ageGroups";
import { requestVendorCategories } from "data-handler/ducks/vendors";
import { withSCSizes } from "helpers/mapSizesToProps";
import { getIsAdmin, getIsCountryAdmin } from "helpers/users";

import { languagesOptions } from "SCConstants";
import { useSchoolIdFromURL } from "helpers/schools";

/**
 * Change language and refetch those things that are translated by the BE
 *
 * TODO: this is hacky and not DRY as it duplicates code `Content.js` is in charge of
 */
const changeLanguageAndRefetch = (
  language,
  dispatch,
  schoolIdFromURL,
  countryId
) => {
  dispatch(changeLanguage(language, true));
  axios.defaults.headers.common["Accept-Language"] = language;
  dispatch(requestCurrentSchool(schoolIdFromURL));
  dispatch(commoditiesDuck.fetchList());
  dispatch(incidentCausesDuck.fetchList());
  dispatch(deliveryIssuesDuck.fetchList());
  dispatch(requestVendorCategories());
  dispatch(requestCountryRoleMapping(countryId));
  dispatch(requestLevelGroupMappings(countryId));
  dispatch(requestRoles());
};

const UserNavigation = ({ isMobile }) => {
  const isOffline = useSelector(getLoggedInOffline);
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const countryAdminMatch = useRouteMatch("/countryAdmin/");

  const schoolIdFromURL = useSchoolIdFromURL();
  const currentSchool = useSelector(getCurrentSchool);
  const selectedCountry = useSelector(getSelectedCountry);
  const isSchoolStaff = useSelector(getIsSchoolStaff);
  const updatePasscodeButtonCondition =
    getIsAdmin(currentUser) || getIsCountryAdmin(currentUser) || isSchoolStaff;
  const countryId =
    currentUser?.user_role === "country_administrator"
      ? currentUser?.country?.id
      : countryAdminMatch
      ? selectedCountry?.id
      : currentSchool?.country?.id;

  // TODO: remove, without currentUser there's no UserNavigation either
  if (!currentUser) return null;

  const mobileViewLabel = (
    <FormattedMessage
      id="UserNavigation.ResizeMobile"
      defaultMessage="Change to Mobile View"
    />
  );

  const webViewLabel = (
    <FormattedMessage
      id="UserNavigation.ResizeWeb"
      defaultMessage="Change to Web View"
    />
  );

  const customMarginBottom = updatePasscodeButtonCondition ? "0.75rem" : "0rem";

  return (
    <div>
      <SubNavigation>
        <SubNavigationHeader>
          <SubNavigationTitle>
            <div className="" style={{ marginBottom: customMarginBottom }}>
              <FormattedMessage id="menu.welcome" defaultMessage="Welcome" />{" "}
              {currentUser.last_name} {currentUser.other_names}!
            </div>
          </SubNavigationTitle>
          <SubNavigationFilter>
            <div className={styles.filter}>
              <NavLink exact to={`/logout`}>
                <Button
                  small
                  icon={<FontAwesomeIcon icon={faArrowLeft} />}
                  style={{ marginBottom: customMarginBottom }}
                >
                  <FormattedMessage
                    id="UserNavigation.logoutButtonLabel"
                    defaultMessage="Logout"
                  />
                </Button>
              </NavLink>
              <br />
              {
                // Hide the update passcode button for non-school users
                updatePasscodeButtonCondition && (
                  <NavLink exact to={`?select=updateLocalPasscode`}>
                    <Button kind="primary" small>
                      <FormattedMessage
                        id="UserNavigation.updatePasscodeButtonLabel"
                        defaultMessage="Update passcode"
                      />
                    </Button>
                  </NavLink>
                )
              }
            </div>
          </SubNavigationFilter>
        </SubNavigationHeader>
        <SubNavigationContent>
          {!isOffline && (
            <SubNavigationList>
              <SubNavigationGroup>
                {languagesOptions.map((language) => (
                  <SubNavigationItem key={language.value}>
                    <Link
                      onClick={() => {
                        changeLanguageAndRefetch(
                          language.value,
                          dispatch,
                          schoolIdFromURL,
                          countryId
                        );
                      }}
                    >
                      {language.label} <i>({language.labelTranslated})</i>
                    </Link>
                  </SubNavigationItem>
                ))}
              </SubNavigationGroup>
            </SubNavigationList>
          )}
          <SubNavigationList>
            <SubNavigationGroup>
              <br />
              <NavLink exact to={`?select=debug`}>
                <Button
                  kind="accent"
                  small
                  icon={<FontAwesomeIcon icon={faBug} />}
                >
                  <FormattedMessage
                    id="UserNavigation.supportButtonLabel"
                    defaultMessage="Support"
                  />
                </Button>
              </NavLink>
            </SubNavigationGroup>
            <SubNavigationGroup>
              <br />
              <NavLink exact to={`/termsAndConditions`}>
                <Button
                  kind="accent"
                  small
                  icon={<FontAwesomeIcon icon={faBug} />}
                >
                  <FormattedMessage
                    id="UserNavigationTerms.termsAndConditionsButtonLabel"
                    defaultMessage="Terms and Conditions"
                  />
                </Button>
              </NavLink>
            </SubNavigationGroup>
            <SubNavigationGroup>
              <br />
              <NavLink exact to={`/privacyStatement`}>
                <Button
                  kind="accent"
                  small
                  icon={<FontAwesomeIcon icon={faBug} />}
                >
                  <FormattedMessage
                    id="UserNavigationPrivacy.privacyStatementButtonLabel"
                    defaultMessage="Privacy Statement"
                  />
                </Button>
              </NavLink>
            </SubNavigationGroup>
            <SubNavigationGroup>
              <br />
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ paddingRight: "1rem" }}>
                  <Button
                    kind="secondary"
                    small
                    onClick={() => {
                      dispatch(setViewLayout(!isMobile ? "mobile" : "web"));
                    }}
                  >
                    {!isMobile ? mobileViewLabel : webViewLabel}
                  </Button>
                </div>
                <Button
                  kind="secondary"
                  small
                  onClick={() => dispatch(setViewLayout("auto"))}
                >
                  <FormattedMessage
                    id="UserNavigation.autoResize"
                    defaultMessage="Auto Resize"
                  />
                </Button>
              </div>
            </SubNavigationGroup>
          </SubNavigationList>
        </SubNavigationContent>
      </SubNavigation>
    </div>
  );
};

export default withSCSizes(UserNavigation);
