import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSync,
  faInfoCircle,
  faCaretDown,
  faWifi,
  faBan,
} from "@fortawesome/free-solid-svg-icons";

import {
  Button,
  MainNavigation,
  MainNavigationItem,
  User,
  InlineLoading,
  InfoBar,
  Icon,
} from "@wfp/ui";

import { getCurrentUser } from "data-handler/ducks/auth";
import {
  getUnsyncedStores,
  getIsStoresFetching,
} from "data-handler/ducks/stores";
import { getCurrentSchool } from "data-handler/ducks/schools";
import UserNavigation from "components/UserNavigation";
import TabLink from "components/TabLink";
import OnlineOffline from "components/OnlineOffline";

import classNames from "classnames";
import "./_content.scss";

class MainNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.child = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.location && this.props.location) {
      this.closeMenu();
    }
  }

  closeMenu = () => {
    this.child.current.onChangeSub("close");
  };

  render() {
    const {
      currentSchoolName,
      currentUser,
      fetchingStores,
      showMenu,
      match,
      unsyncedItems,
    } = this.props;

    if (!match) {
      return null;
    }
    const schoolId = match.params.schoolId;
    const isViewer =
      currentUser?.is_external_viewer || currentUser?.is_wfp_viewer;

    const contentClasses = classNames("content", {
      content__active: !showMenu,
    });

    return (
      <div className={contentClasses}>
        {process.env.REACT_APP_ENV &&
          process.env.REACT_APP_ENV !== "production" && (
            <div
              className={`environment-indicator ${process.env.REACT_APP_ENV}`}
            >
              {process.env.REACT_APP_ENV}
            </div>
          )}
        <MainNavigation
          className="content__header"
          pageWidth="full"
          logo={
            (currentUser && currentUser.is_admin) ||
            (currentUser && currentUser.is_approver) ||
            (currentUser && currentUser.is_school_group_admin) ||
            (currentUser && currentUser.is_country_admin) ||
            (currentUser && currentUser.is_external_viewer) ? (
              <>
                <NavLink
                  exact
                  to={`?select=school`}
                  renderListElement={TabLink}
                >
                  <Button
                    className="school-select"
                    kind="inverse"
                    icon={<FontAwesomeIcon icon={faCaretDown} />}
                  >
                    {fetchingStores ? (
                      <InlineLoading />
                    ) : (
                      <> {currentSchoolName}</>
                    )}
                  </Button>
                </NavLink>
              </>
            ) : null
          }
          ref={this.child}
        >
          {() => {
            return (
              <>
                <MainNavigationItem>
                  <NavLink
                    to={`/school/${schoolId}/stock/overview`}
                    renderListElement={TabLink}
                  >
                    <FormattedMessage
                      id="menu.overview"
                      defaultMessage="Overview"
                    />
                  </NavLink>
                </MainNavigationItem>
                <MainNavigationItem>
                  <NavLink
                    to={`/school/${schoolId}/attendance`}
                    renderListElement={TabLink}
                  >
                    <FormattedMessage
                      id="menu.attendance"
                      defaultMessage="Attendance & Meals"
                    />
                  </NavLink>
                </MainNavigationItem>
                <MainNavigationItem>
                  <NavLink
                    to={`/school/${schoolId}/deliveries`}
                    renderListElement={TabLink}
                  >
                    <FormattedMessage
                      id="menu.deliveries"
                      defaultMessage="Deliveries"
                    />
                  </NavLink>
                </MainNavigationItem>
                {!currentUser.isViewer || currentUser.is_admin ? (
                  <MainNavigationItem>
                    <NavLink
                      to={`/school/${schoolId}/info`}
                      renderListElement={TabLink}
                    >
                      <FormattedMessage
                        id="menu.school"
                        defaultMessage="School admin"
                      />
                    </NavLink>
                  </MainNavigationItem>
                ) : null}
                <MainNavigationItem>
                  <NavLink
                    to={`/school/${schoolId}/report`}
                    renderListElement={TabLink}
                  >
                    <FormattedMessage
                      id="menu.reports"
                      defaultMessage="Reports"
                    />
                  </NavLink>
                </MainNavigationItem>
                <MainNavigationItem>
                  <OnlineOffline
                    onlineChildren={
                      <NavLink
                        className="content__sync-btn__wrapper"
                        exact
                        to={`?select=sync`}
                        renderListElement={TabLink}
                      >
                        <Button
                          className="content__sync-btn"
                          small
                          kind="accent"
                          disabled={unsyncedItems.length === 0}
                          icon={<FontAwesomeIcon icon={faSync} />}
                        >
                          <FormattedMessage
                            id="menu.syncdata"
                            defaultMessage="Sync"
                          />
                        </Button>
                      </NavLink>
                    }
                    offlineChildren={
                      <Button
                        className="content__sync-btn"
                        kind="danger--primary"
                        disabled
                        icon={<FontAwesomeIcon icon={faInfoCircle} />}
                      >
                        <FormattedMessage
                          id="menu.syncNotAvailable"
                          defaultMessage="Offline - Data sync not available"
                        />
                      </Button>
                    }
                  />
                </MainNavigationItem>
                <OnlineOffline
                  onlineChildren={
                    <Icon
                      icon={<FontAwesomeIcon icon={faWifi} fontSize="1rem" />}
                    />
                  }
                  offlineChildren={
                    <Icon
                      icon={<FontAwesomeIcon icon={faBan} />}
                      className="content__header__offline-icon"
                    />
                  }
                />
                <MainNavigationItem
                  className="wfp--main-navigation__user"
                  subNavigation={<UserNavigation />}
                >
                  <User
                    ellipsis
                    name={currentUser ? currentUser.last_name : "no name"}
                    missingImage="letter"
                  />
                </MainNavigationItem>
              </>
            );
          }}
        </MainNavigation>
        {isViewer ? (
          <InfoBar>
            <FormattedMessage
              id="menu.WFPReadOnlyMessage"
              defaultMessage="You are logged in read-only mode. To change it, please contact your administrator."
            />
          </InfoBar>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const currentSchool = getCurrentSchool(state);
  const currentSchoolName =
    (currentSchool && currentSchool.name) || "Select school";
  return {
    currentSchoolName,
    fetchingStores: getIsStoresFetching(state),
    currentUser: getCurrentUser(state),
    showMenu: state.showMenu, // TODO: this is always undefined, what does it do?
    language: state.language,
    unsyncedItems: getUnsyncedStores(state),
  };
};

export default connect(mapStateToProps)(MainNav);
