export function camelToSnakeCase(string: string) {
  return string.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}

export function downloadBlob(blob: Blob, filename = "sample.csv") {
  const url = URL.createObjectURL(blob);

  const element = document.createElement("a");

  element.href = url;
  element.download = filename;

  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}
