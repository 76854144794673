import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import {
  Module,
  ModuleBody,
  ModuleFooter,
  ModuleHeader,
  Wrapper,
  Button,
  InlineLoading,
} from "@wfp/ui";

import { schoolsCheckCol } from "./TableUtils";
import { valueLinkCell } from "components/CountryAdmin/CountryAdminContent/Table/Utils";
import Table from "components/CountryAdmin/CountryAdminContent/Table/Table";
import Filtering from "components/CountryAdmin/CountryAdminContent/Table/Filtering";
import { CountryAdminLabel } from "SCConstants";

import {
  actionSchool,
  getAllAdminSchools,
  getIsSchoolsFetching,
  requestAllAdminSchools,
  requestMoveSchoolToNewProfile,
  setSchoolDistributionReport,
} from "data-handler/ducks/schools";
import { getCurrentUser } from "data-handler/ducks/auth";
import { requestSchoolProfilesNonPaginated } from "data-handler/ducks/schoolProfile";
import { requestPartnerships } from "data-handler/ducks/distributionReports";

import "../_content.scss";
import SetSchoolProfileModal from "./setSchoolProfileModal";
import SetSchoolStateModal from "./setSchoolStateModal";
import SetDistributionReportModal from "./setDistributionReportModal";
import CsvImportModal from "../CsvImportModal";
import ConfirmationModal from "../../../ConfirmationModal";
import useFileGeneration from "../useFileGeneration";

export const isCheckboxDisabled = (data) => {
  let isDisabled;
  for (let i = 0; i < data.length; i++) {
    for (let k = 0; k < data.length; k++) {
      if (
        data[i]?.country?.name !== data[k]?.country?.name ||
        data[i]?.state !== data[k]?.state
      ) {
        isDisabled = true;
      }
    }
  }
  return isDisabled;
};
const AllSchools = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();
  const allAdminSchools = useSelector(getAllAdminSchools);
  const isFetching = useSelector(getIsSchoolsFetching);
  const currentUser = useSelector(getCurrentUser);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filtersState, setFilters] = useState([
    { id: "state", value: "active" },
  ]);
  const [schoolProfileOpen, setSchoolProfileModalOpen] = useState(false);
  const [schoolStateOpen, setSchoolStateModalOpen] = useState(false);
  const [distributionReportOpen, setDistributionReportOpen] = useState(false);
  const [csvImportModalOpen, setCsvImportModalOpen] = useState(false);
  const [newSchoolProfileId, setNewSchoolProfileId] = useState();
  const [selectedSchoolState, setSelectedSchoolState] = useState();
  const [selectedAll, setSelectedAll] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [schoolToPerformAction, setSchoolToPerformAction] = useState(null);
  const [confirmationModalState, setConfirmationModalState] = useState(false);

  const {
    status: fileGenerationStatus,
    initiateFileGeneration,
  } = useFileGeneration();

  const { count = 0, ids = [] } = allAdminSchools;

  const data = ids.map((id) => allAdminSchools.results[id]);
  const checkboxIsDisabled = isCheckboxDisabled(data);

  const lengthOfDataEnabled = data.map(
    (i, e) => i?.country?.name === e?.country?.name || i?.state === e?.state
  )?.length;

  const stateOptions = [
    {
      name: "",
      label: intl.formatMessage({
        id: "CountryAdmin.AllSchools",
        defaultMessage: "All Schools",
      }),
    },
    {
      name: "new",
      label: intl.formatMessage({
        id: "countryAdmin.New",
        defaultMessage: "New",
      }),
    },
    {
      name: "active",
      label: intl.formatMessage({
        id: "countryAdmin.active",
        defaultMessage: "Active",
      }),
    },
    {
      name: "inactive",
      label: intl.formatMessage({
        id: "countryAdmin.inactive",
        defaultMessage: "Inactive",
      }),
    },
  ];

  const filterFields = [
    {
      name: "name",
      label: intl.formatMessage({
        id: "CountryAdmin.name",
        defaultMessage: "Name",
      }),
    },
    {
      name: "profile_name",
      label: intl.formatMessage({
        id: "CountryAdmin.profile",
        defaultMessage: "Profile",
      }),
    },
    {
      name: "adm_4_name",
      label: intl.formatMessage({
        id: "CountryAdmin.adminFour",
        defaultMessage: "Admin 4",
      }),
    },
    {
      name: "adm_3_name",
      label: intl.formatMessage({
        id: "CountryAdmin.adminThree",
        defaultMessage: "Admin 3",
      }),
    },
    {
      name: "adm_2_name",
      label: intl.formatMessage({
        id: "CountryAdmin.adminTwo",
        defaultMessage: "Admin 2",
      }),
    },
    {
      name: "adm_1_name",
      label: intl.formatMessage({
        id: "CountryAdmin.adminOne",
        defaultMessage: "Admin 1",
      }),
    },
    {
      name: "state",
      label: intl.formatMessage({
        id: "countryAdmin.State",
        defaultMessage: "State",
      }),
      type: "select",
      options: stateOptions,
    },
  ];

  if (currentUser.is_admin) {
    filterFields.push({
      name: "country_name",
      label: intl.formatMessage({
        id: "CountryAdmin.country",
        defaultMessage: "Country",
      }),
    });
  }

  const filterProps = {
    fields: filterFields,
    onSubmit: (e) => {
      const filters = Object.keys(e).map((key) => ({ id: key, value: e[key] }));
      setFilters(filters);
      loadPage(pageSize, 1, "", filters);
    },
    defaults: filtersState.reduce((a, x) => ({ ...a, [x.id]: x.value }), {}),
    secondaryButton: {
      onSubmit: (e) => {
        const filters = Object.keys(e).map((key) => ({
          id: key,
          value: e[key],
        }));

        setFilters(filters);
        dispatch(requestAllAdminSchools(99999, 1, "", filters));
      },
      name: intl.formatMessage({
        id: "CountryAdmin.selectAll",
        defaultMessage: "Select all",
      }),
      secondaryName: intl.formatMessage({
        id: "CountryAdmin.unselectAll",
        defaultMessage: "Unselect all",
      }),
      secondaryButtonState: {
        setSelectedAll: (e) => {
          setSelectedAll(e);
        },
        selectedAllState: selectedAll,
      },
    },
    // Currently the select all feature does not work.
    // TODO: Change the line below when new logic is discussed
    // showSecondaryButton: !checkboxIsDisabled && lengthOfDataEnabled !== 0,
    showSecondaryButton: false,
  };
  const getNewState = (state) => {
    return state === "active"
      ? "deactivateActive"
      : state === "new"
      ? "activate"
      : "activateInactive";
  };

  const setActionButton = ({ original }) => {
    const state = original["state"];
    const hasUnsubmittedReports = original["has_unsubmitted_reports"];
    const label =
      state === "active"
        ? CountryAdminLabel.find((e) => e.name === "countryAdmin.deactivate")
            .label
        : CountryAdminLabel.find((e) => e.name === "countryAdmin.activate")
            .label;

    if (state !== "new") {
      return (
        <Button
          className="active-button"
          onClick={() => {
            if (hasUnsubmittedReports && state === "active") {
              setSchoolToPerformAction({
                id: original["id"],
                state: original["state"],
              });
              setConfirmationModalState(true);
            } else {
              dispatch(
                actionSchool(
                  { schools: [original["id"]] },
                  getNewState(state),
                  history
                )
              );
            }
          }}
        >
          {label}
        </Button>
      );
    } else {
      return null;
    }
  };

  const stateCell = ({ row }) => {
    const state = row["original"]["state"];
    const stateOption = stateOptions.find((item) => item.name === state);
    return stateOption ? stateOption.label : "";
  };

  const columns = useMemo(() => {
    const allColumns = [
      schoolsCheckCol,
      {
        Header: intl.formatMessage({
          id: "CountryAdmin.name",
          defaultMessage: "Name",
        }),
        accessor: "name",
        Cell: ({ cell: { row, value } }) =>
          valueLinkCell(
            value,
            `/countryAdmin/schools/allSchools/${row["original"]["id"]}/`
          ),
      },
      {
        Header: intl.formatMessage({
          id: "CountryAdmin.profile",
          defaultMessage: "Profile",
        }),
        accessor: "profile.name",
      },
      {
        Header: intl.formatMessage({
          id: "CountryAdmin.adminFour",
          defaultMessage: "Admin 4",
        }),
        accessor: "adm_4.name",
      },
      {
        Header: intl.formatMessage({
          id: "CountryAdmin.adminThree",
          defaultMessage: "Admin 3",
        }),
        accessor: "adm_3.name",
        disableSortBy: true,
      },
      {
        Header: intl.formatMessage({
          id: "CountryAdmin.adminTwo",
          defaultMessage: "Admin 2",
        }),
        accessor: "adm_2.name",
        disableSortBy: true,
      },
      {
        Header: intl.formatMessage({
          id: "CountryAdmin.adminOne",
          defaultMessage: "Admin 1",
        }),
        accessor: "adm_1.name",
        disableSortBy: true,
      },
    ];
    if (currentUser.is_admin) {
      allColumns.push({
        Header: intl.formatMessage({
          id: "CountryAdmin.country",
          defaultMessage: "Country",
        }),
        accessor: "country.name",
      });
    }
    allColumns.push(
      {
        Header: intl.formatMessage({
          id: "countryAdmin.state",
          defaultMessage: "State",
        }),
        accessor: "state",
        Cell: stateCell,
        width: 80,
      },
      {
        Header: "",
        accessor: "action",
        Cell: ({ cell: { row } }) => setActionButton(row),
        disableSortBy: true,
      }
    );
    return allColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadPage = (size, idx, ordering, filters = filtersState) => {
    setPageSize(size);
    setPageNum(idx);
    dispatch(requestAllAdminSchools(size, idx, ordering, filters));
  };

  function openSetSchoolProfileModal(selectedRows) {
    setSelectedIds(selectedRows);
    const selectedRowObjectList = Object.keys(selectedRows).map(
      (idx) => data[parseInt(idx)]
    );
    const schoolCountryId = selectedRowObjectList[0].country.id;
    dispatch(requestSchoolProfilesNonPaginated(schoolCountryId));
    setSchoolProfileModalOpen(true);
  }
  function submitNewSchoolProfiles() {
    dispatch(
      requestMoveSchoolToNewProfile({
        schools: Object.keys(selectedIds).map((idx) => data[parseInt(idx)].id),
        profile: newSchoolProfileId,
      })
    );
  }

  function submitNewSchoolState() {
    dispatch(
      actionSchool(
        {
          schools: Object.keys(selectedIds).map(
            (idx) => data[parseInt(idx)].id
          ),
        },
        getNewState(selectedSchoolState),
        history
      )
    );
    setSchoolStateModalOpen(false);
  }

  const reportSubmittedCheckSelectedSchools = Object.keys(selectedIds)
    .map((idx) => data[parseInt(idx)])
    .map((level) => {
      return level?.has_unsubmitted_reports
        ? {
            schoolName: level.name,
            hasUnsubmittedReports: level.has_unsubmitted_reports,
          }
        : null;
    });
  function submitNewDistributionReportProfile(distributionReportId) {
    dispatch(
      setSchoolDistributionReport({
        schools: Object.keys(selectedIds).map((idx) => data[parseInt(idx)].id),
        distribution_report: distributionReportId,
      })
    );
  }

  function openSetSchoolStateModal(selectedRows) {
    setSelectedIds(selectedRows);
    setSelectedSchoolState(
      Object.keys(selectedRows).map((idx) => data[parseInt(idx)].state)[0]
    );
    setSchoolStateModalOpen(true);
  }

  function openSetDistributionReportModal(selectedRows) {
    setSelectedIds(selectedRows);
    const selectedRowObjectList = Object.keys(selectedRows).map(
      (idx) => data[parseInt(idx)]
    );
    const schoolCountryId = selectedRowObjectList[0].country.id;
    dispatch(requestPartnerships(schoolCountryId));
    setDistributionReportOpen(true);
  }

  const tableActions = [
    {
      key: "SetSchoolProfile",
      label: intl.formatMessage({
        id: "schools.setSchoolProfile",
        defaultMessage: "Set school profile",
      }),
      callback: openSetSchoolProfileModal,
    },
    {
      key: "SetSchoolState",
      label: intl.formatMessage({
        id: "schools.setSchoolState",
        defaultMessage: "Set school state",
      }),
      callback: openSetSchoolStateModal,
    },
    {
      key: "SetDistributionReport",
      label: intl.formatMessage({
        id: "schools.setDistributionReport",
        defaultMessage: "Set distribution report",
      }),
      callback: openSetDistributionReportModal,
    },
  ];

  const tableProps = {
    columns,
    data,
    pageNum,
    pageSize,
    totalItems: count,
    loadPage,
    actions: tableActions,
    initialState: {
      selectedRowIds:
        selectedAll && !checkboxIsDisabled && lengthOfDataEnabled !== 0
          ? ids
          : {},
    },
  };

  const setSchoolProfileModalProps = {
    schoolProfileOpen,
    submitNewSchoolProfiles,
    setSchoolProfileModalOpen,
    setNewSchoolProfileId,
  };

  const setSchoolStateModalProps = {
    schoolStateOpen,
    submitNewSchoolState,
    setSchoolStateModalOpen,
    selectedState: selectedSchoolState,
    reportSubmittedCheckSelectedSchools,
  };

  const setDistributionReportModalProps = {
    distributionReportOpen,
    submitNewDistributionReportProfile,
    setDistributionReportOpen,
  };

  const performAction = () => {
    dispatch(
      actionSchool(
        { schools: [schoolToPerformAction["id"]] },
        getNewState(schoolToPerformAction["state"]),
        history
      )
    );
    setSchoolToPerformAction(null);
    setConfirmationModalState(false);
  };

  const csvImportModalProps = {
    csvImportModalOpen,
    setCsvImportModalOpen,
  };
  return (
    <div className="country-admin-main-content schools-table all-schools">
      <Wrapper
        pageWidth="lg"
        spacing="md"
        background="lighter"
        className="table"
      >
        <Module>
          <ModuleHeader>
            {intl.formatMessage({
              id: "CountryAdmin.AllSchools",
              defaultMessage: "All Schools",
            })}
          </ModuleHeader>

          <ModuleBody>
            <div style={isFetching ? { display: "none" } : {}}>
              <Filtering {...filterProps} />

              <Table {...tableProps} showSort showActions />

              <SetSchoolProfileModal {...setSchoolProfileModalProps} />
              <SetSchoolStateModal {...setSchoolStateModalProps} />

              <ConfirmationModal
                confirmationModalState={confirmationModalState}
                modalType={"submit"}
                setConfirmationModalState={setConfirmationModalState}
                onConfirmationModalSubmit={performAction}
                submitMessage={
                  <FormattedMessage
                    id="countyProfile.unsubmittedReportsSchoolDeactivateWarning"
                    defaultMessage="The school still has unsubmitted reports. Are you sure you want to deactivate it?"
                  />
                }
              />

              <SetDistributionReportModal
                {...setDistributionReportModalProps}
              />

              <CsvImportModal {...csvImportModalProps} />
            </div>
            <div
              className="inlineLoading"
              style={!isFetching ? { display: "none" } : {}}
            >
              <InlineLoading description="Loading..." />
            </div>
          </ModuleBody>

          <ModuleFooter>
            <Link exact="true" to={`/countryAdmin/schools/create`}>
              <Button>
                {
                  <FormattedMessage
                    id="countryAdmin.createNewSchool"
                    defaultMessage="Create new school"
                  />
                }
              </Button>
            </Link>
            <Button
              className="wfp--btn--secondary"
              onClick={() => {
                setCsvImportModalOpen(true);
              }}
            >
              {
                <FormattedMessage
                  id="modal.importCsv"
                  defaultMessage="Import CSV"
                />
              }
            </Button>
            <Button
              className="wfp--btn--secondary generate-file-button"
              onClick={() => {
                initiateFileGeneration(
                  `${process.env.REACT_APP_API_URL}/schools/generate-update-template/?` +
                    (filtersState && filtersState.length
                      ? filtersState
                          .map((filter) => {
                            const id = filter.id;
                            const value = filter.value;
                            if (value && value !== "") {
                              return `&${id}=${value}`;
                            }
                            return "";
                          })
                          .join("")
                      : "")
                );
              }}
              disabled={fileGenerationStatus === "generating"}
            >
              {fileGenerationStatus === "generating" ? (
                <InlineLoading />
              ) : (
                <FormattedMessage
                  id="modal.generatefile"
                  defaultMessage="Generate file"
                />
              )}
            </Button>
          </ModuleFooter>
        </Module>
      </Wrapper>
    </div>
  );
};

export default AllSchools;
