import axios from "axios";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
import { IntlProvider } from "react-intl";
import * as Sentry from "@sentry/react";
import moment from "moment";
import "moment/locale/ar";
import "moment/locale/pt";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/uk";

import Content from "components/Content";
import ErrorFallback from "components/ErrorFallback";
import LocalLogin from "components/Login/LocalLogin";
import OnlineLogin from "components/Login/OnlineLogin";
import LoginSelect from "components/Login/SelectLogin";
import Logout from "components/Logout";
import LogoutNoSchoolProfile from "components/LogoutNoSchoolProfile";
import RequestPassword from "components/Login/RequestPassword";
import Registration from "./components/Registration";
import OpenIdAuthentication from "components/OpenIdAuthentication";
import PrivateRoute from "components/PrivateRoute";
import LoginRedirect from "components/Login/LoginRedirect";
import CountryAdminContent from "components/CountryAdmin/CountryAdminContent";
import Toast from "components/Toast/Toast";
import TermsAndConditionsModal from "components/UserNavigation/TermsAndConditionsModal";
import PrivacyStatementModal from "components/UserNavigation/PrivacyStatementModal";

import { changeLanguage, getLanguage } from "data-handler/ducks/language";
import { getCurrentUser, getViewLayout } from "data-handler/ducks/auth";
import { getDate } from "data-handler/ducks/date";

import messages from "locales";

import "./style.scss";

/**
 * Expose a different default login in e2e testing builds
 */
const LOGIN_COMPONENT = process.env.REACT_APP_E2E ? OnlineLogin : LoginSelect;

function App() {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const country = currentUser?.country;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const date = useSelector(getDate); // Only used to trigger a re-rendering
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const viewLayout = useSelector(getViewLayout); // Only used to trigger a re-rendering

  // TODO: move logic to `language` duck (clamping, default, side effects...)
  // Select locale (try using: 1. stored 2. browser 3. default)
  const storedLanguage = useSelector(getLanguage);
  const browserLanguage = (
    (navigator.languages && navigator.languages[0]) ||
    navigator.language
  ).slice(0, 2); // remove e.g. "-GB" from "en-GB"
  let locale = storedLanguage || browserLanguage || "en";
  locale = ["fr", "en", "pt", "es", "ar", "uk", "so"].includes(locale)
    ? locale
    : "en"; // (clamp or default to "en")

  useEffect(() => {
    // Store locale preference
    dispatch(changeLanguage(locale));
    // Set `Accept-Language` header for all future requests
    axios.defaults.headers.common["Accept-Language"] = locale;
    // Set global locale for moment.js
    if (locale === "ar") {
      moment.locale("ar", {
        // for arabic, customize locale to use digits as 0-9 instead of the equivalent in arabic
        preparse: (str: string) => str,
        postformat: (str: string) => str,
      });
    } else {
      moment.locale(locale);
    }
  }, [locale, dispatch]);

  return (
    <IntlProvider
      locale={locale}
      key={locale}
      defaultLocale={"en"} // Suppress warnings for missing english translations
      messages={(messages as any)[locale]}
    >
      <Sentry.ErrorBoundary fallback={ErrorFallback({ history })}>
        <div className="app-wrapper">
          <Toast />
          <Switch>
            {/*
            Public routes
            */}
            <Route path="/login" component={LOGIN_COMPONENT} />
            <Route path="/locallogin/:username" component={LocalLogin} />
            <Route path="/locallogin" component={LocalLogin} />
            <Route path="/registration" component={Registration} />
            <Route path="/ciam-redirect" component={LoginRedirect} />
            <Route path="/corporateLogin" component={OpenIdAuthentication} />
            <Route
              path="/logoutNoSchoolProfile"
              component={LogoutNoSchoolProfile}
            />
            <Route path="/logout" component={Logout} />
            <Route path="/requestpassword" component={RequestPassword} />
            <Route
              path="/termsAndConditions"
              component={TermsAndConditionsModal}
            />
            <Route path="/privacyStatement" component={PrivacyStatementModal} />
            {/*
            Private routes (redirect user to login unless logged in)
            */}
            {((currentUser?.is_country_admin && country) ||
              currentUser?.is_admin) && (
              <PrivateRoute
                path="/countryAdmin/"
                component={CountryAdminContent}
              />
            )}
            <PrivateRoute component={Content} />
          </Switch>
        </div>
      </Sentry.ErrorBoundary>
    </IntlProvider>
  );
}

export default App;
