import React from "react";
import { useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import cornBagImage from "images/corn-bag.svg";

import { Button } from "@wfp/ui";

import Empty from "components/Empty";
import { UserPermissionRoles } from "../../SCConstants";
import Gate from "../Gate";

import {
  getUnsyncedStores,
  getIsStoresFetching,
} from "data-handler/ducks/stores";

import "./_content.scss";

const StartASchoolYearFirst = () => {
  const { schoolId } = useParams();
  const unsyncedStores = useSelector(getUnsyncedStores);
  const isFetching = useSelector(getIsStoresFetching);

  return (
    <Empty
      className="start-school-year-first-container"
      button={
        <Gate forbidRoles={[UserPermissionRoles.is_viewer]}>
          <NavLink
            /* TODO: .../undefined/..., really?? */
            to={`/school/${schoolId}/info/undefined/new-year`}
          >
            <Button
              kind="accent"
              className="disabled-button"
              disabled={unsyncedStores.length > 0 || isFetching}
              iconReverse
              icon={<FontAwesomeIcon icon={faPlus} />}
            >
              <FormattedMessage
                id="StartASchoolYearFirst.subtitle"
                defaultMessage="Start new school year"
              />
              {isFetching ? (
                <span className="tooltiptext">
                  <FormattedMessage
                    id="sideBar.newSchoolYearDisabledFetchingData"
                    defaultMessage="You can only create/edit a new school year after all the data is loaded."
                  />
                </span>
              ) : unsyncedStores.length > 0 ? (
                <span className="tooltiptext">
                  <FormattedMessage
                    id="sideBar.newSchoolYearDisabledUnsyncedData"
                    defaultMessage="You can only create a new school year after all the data is synced."
                  />
                </span>
              ) : null}
            </Button>
          </NavLink>
        </Gate>
      }
      title={
        <FormattedMessage
          id="StartASchoolYearFirst.title"
          defaultMessage="Start a school year first"
        />
      }
      kind="large"
      icon={
        <img
          alt="Illustration of a bag of corn / rice"
          src={cornBagImage}
          style={{
            marginLeft: "1%",
            marginRight: "3%",
            marginBottom: "1%",
          }}
        />
      }
    >
      <FormattedMessage
        id="StartASchoolYearFirst.explanation"
        defaultMessage="You need to have a school year ongoing to visualize and provide data"
      />
    </Empty>
  );
};

export default StartASchoolYearFirst;
