import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const BATCH_NUMBERS_REQUEST =
  "schoolconnect/batchNumbers/BATCH_NUMBERS_REQUEST";
export const BATCH_NUMBERS_SUCCESS =
  "schoolconnect/batchNumbers/BATCH_NUMBERS_SUCCESS";
export const BATCH_NUMBERS_FAILURE =
  "schoolconnect/batchNumbers/BATCH_NUMBERS_FAILURE";
export const CLEAR_BATCH_NUMBERS =
  "schoolconnect/batchNumbers/CLEAR_BATCH_NUMBERS";

const initialState = {
  fetching: false,
  error: null,
  errorResponse: null,
  index: {},
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case BATCH_NUMBERS_REQUEST:
      return { ...state, fetching: true, error: null };
    case BATCH_NUMBERS_SUCCESS:
      const { results, commodityIds } = action.data;

      const batchNosPerCommodityId = commodityIds.reduce((acc, cur) => {
        return {
          ...acc,
          [cur]: results.filter((item) =>
            item.store_commodity_ids.includes(cur)
          ),
        };
      }, {});

      return {
        ...state,
        fetching: false,
        index: { ...state.index, ...batchNosPerCommodityId },
        error: null,
      };
    case BATCH_NUMBERS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error.message,
        errorResponse: action.error.response,
      };
    case CLEAR_BATCH_NUMBERS:
      return {
        ...state,
        index: {},
      };
    default:
      return state;
  }
}

const persistedReducer = persistReducer(
  {
    key: "batch-numbers",
    storage,
    blacklist: ["error", "errorResponse", "fetching"],
  },
  reducer
);

export default persistedReducer;

export const clearBatchNumberOptions = () => ({
  type: CLEAR_BATCH_NUMBERS,
});

export const requestBatchNumbers = (commodityIds, iso2, adm_1, date) => ({
  type: BATCH_NUMBERS_REQUEST,
  commodityIds,
  iso2,
  adm_1,
  date,
});

export function* batchNumbersSagaWatcher() {
  yield takeLatest(BATCH_NUMBERS_REQUEST, batchNumbersSagaWorker);
}

function fetchBatchNumbers(action) {
  const { commodityIds, iso2, date } = action;

  const url =
    `${process.env.REACT_APP_API_URL}/batch-numbers/` +
    (commodityIds ? `?core_commodity__in=${commodityIds.join(",")}` : "") +
    (iso2 ? `&destination_country_iso2=${iso2.toUpperCase()}` : "") +
    // Don't use adm_1 filtering for now
    // TODO: might be added in the future
    // (adm_1 && iso2
    //   ? `&destination_location=${adm_1.toUpperCase()}%2C${iso2.toUpperCase()}`
    //   : "") +
    (date ? `&dispatched_year_month=${date}` : "");
  return axios({
    method: "GET",
    url: url,
  });
}

function* batchNumbersSagaWorker(action) {
  try {
    const response = yield call(fetchBatchNumbers, action);
    const data = {
      results: response.data.results,
      commodityIds: action.commodityIds,
    };
    yield put({
      type: BATCH_NUMBERS_SUCCESS,
      data,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: BATCH_NUMBERS_FAILURE,
      error,
    });
  }
}

export const getBatchNumbers = (state) => state["batch-numbers"].index;
export const getBatchNumbersIsFetching = (state) =>
  state["batch-numbers"].fetching;
