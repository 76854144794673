import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Button,
  TextInput,
  InlineLoading,
  Select,
  SelectItem,
  RadioButton,
  RadioButtonGroup,
} from "@wfp/ui";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import { getLoginState } from "data-handler/ducks/auth";

import {
  requestCountryAdminLevels,
  requestSchoolAdmins,
  requestRegistrationSchools,
  getCountryAdminLevels,
  getSchoolAdmins,
  getRegistrationSchools,
} from "data-handler/ducks/adminLevels";

import { GetRoleLabel } from "helpers/roles";

import PageTitle from "components/PageTitle";

import styles from "components/Registration/registration.module.scss";
import "../_content.scss";
import {
  getRolesModule,
  requestCountryRoleMapping,
  requestRoles,
} from "data-handler/ducks/roles";

import { languagesOptions } from "SCConstants";

const SchoolStaffRegistrationForm = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { fetching } = useSelector((state) => getLoginState(state));
  const countryAdminLevels = useSelector(getCountryAdminLevels);
  const schoolAdmins = useSelector(getSchoolAdmins);
  const registrationSchools = useSelector(getRegistrationSchools);
  const { roles, fetching: roleFetching, countryRoles } = useSelector(
    getRolesModule
  );

  const {
    userData,
    onBackToRoleSelection,
    countries,
    onSubmit,
    submitErrorMessage,
    setSubmitErrorMessage,
  } = props;

  const {
    handleSubmit,
    errors,
    register,
    watch,
    setValue,
    getValues,
  } = useForm();

  const adminHighest = countryAdminLevels[0];
  const adminLowest = countryAdminLevels.slice(-1)[0];

  const selectedCountry = watch("country");
  const selectedAdminHighest = watch("adminHighest");
  const selectedAdminLowest = watch("adminLowest");
  const selectedSchool = watch("school");

  useEffect(() => {
    if (!roleFetching & (roles.length <= 0)) {
      dispatch(requestRoles());
    }

    if (selectedCountry && !roleFetching && countryRoles.length <= 0) {
      const country = countries.find(
        (country) => country.iso2 === selectedCountry
      );
      if (country) {
        dispatch(requestCountryRoleMapping(country.id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedCountry]);

  useEffect(() => {
    if (selectedCountry) {
      const country = countries.find(
        (country) => country.iso2 === selectedCountry
      );
      dispatch(requestCountryAdminLevels(country.id));
      dispatch(requestSchoolAdmins(1, country.id));
    }
    setValue("adminHighest", "");
    setValue("adminLowest", "");
    setValue("school", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry, dispatch]);

  useEffect(() => {
    if (selectedAdminHighest) {
      const country = countries.find(
        (country) => country.iso2 === selectedCountry
      );

      dispatch(
        requestSchoolAdmins(
          adminLowest.level,
          country.id,
          undefined,
          parseInt(selectedAdminHighest)
        )
      );
    }
    setValue("adminLowest", "");
    setValue("school", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAdminHighest, dispatch]);

  useEffect(() => {
    if (selectedAdminLowest) {
      dispatch(requestRegistrationSchools(parseInt(selectedAdminLowest)));
    }
    setValue("school", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAdminLowest, dispatch]);

  // Display the required fields message if there are errors
  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      setSubmitErrorMessage(
        intl.formatMessage({
          id: "Registration.allFieldsMustBeFilledMessage",
          defaultMessage:
            "Please fill all the fields marked with red before proceeding",
        })
      );
    }
  }, [errors, intl, setSubmitErrorMessage]);

  useEffect(() => {
    if (
      userData.adminHighest !== "" &&
      userData.adminHighest &&
      getValues("adminHighest") === ""
    ) {
      setValue("adminHighest", userData.adminHighest, { shouldDirty: true });
    }
    if (
      userData.adminLowest !== "" &&
      userData.adminLowest &&
      getValues("adminLowest") === ""
    ) {
      setValue("adminLowest", userData.adminLowest, { shouldDirty: true });
    }
    if (
      userData.school !== "" &&
      userData.school &&
      getValues("school") === ""
    ) {
      setValue("school", userData.school, { shouldDirty: true });
    }
  }, [
    getValues,
    setValue,
    userData,
    selectedAdminHighest,
    selectedAdminLowest,
    selectedSchool,
  ]);

  const invalidStyle = { borderColor: "#c5192d" };

  const labelText = (name) =>
    intl.formatMessage(
      {
        id: "User.RegistrationSelectLocation",
        defaultMessage: "Select {name}",
      },
      {
        name: name,
      }
    );

  const helperText = (name) =>
    intl.formatMessage(
      {
        id: "User.RegistrationSelectLocationHelper",
        defaultMessage: "Select your {name}",
      },
      {
        name: name,
      }
    );

  const userRoles = [
    {
      value: "teacher",
      label: GetRoleLabel({ user_role: "teacher" }),
      default: userData.role === "teacher",
    },
    {
      value: "principal",
      label: GetRoleLabel({ user_role: "principal" }),

      default: userData.role === "principal",
    },
    {
      value: "store_keeper",
      label: GetRoleLabel({ user_role: "store_keeper" }),
      default: userData.role === "store_keeper",
    },
  ];

  return (
    <div className="role-registration-form">
      <form>
        <Button
          icon={<FontAwesomeIcon icon={faChevronLeft} />}
          iconReverse
          kind="secondary"
          className={styles.backLink}
          onClick={() => onBackToRoleSelection()}
        >
          <FormattedMessage
            id="Registration.backToAccessTypeSelectionButton"
            defaultMessage="Back to access type selection"
          />
        </Button>
        <PageTitle>
          <FormattedMessage
            id="User.RegisterToSchoolConnectAsSchoolStaff"
            defaultMessage="Register to School Connect as a School Staff"
          />
        </PageTitle>

        <div className={styles.nameWrapper}>
          <div style={{ marginBottom: "24px" }}>
            <span className="wfp--label">
              <FormattedMessage
                id="User.RegistrationName"
                defaultMessage="Name"
              />
            </span>
            <br />
            {`${userData.other_names} ${userData.last_name}`}
          </div>
        </div>
        <div className={styles.contactWrapper}>
          <TextInput
            style={errors.email ? invalidStyle : {}}
            autocorrect="off"
            autoCapitalize="off"
            defaultValue={userData.email}
            disabled
            labelText={
              <FormattedMessage
                id="user.emailFieldLabel"
                defaultMessage="Email"
              />
            }
            helperText={intl.formatMessage({
              id: "User.RegistrationRequiredEmailHelper",
              defaultMessage: "Your email is required",
            })}
            inputRef={register({ required: true })}
            name="email"
            id="email"
          />
        </div>

        <div className={styles.locationWrapper}>
          <Select
            style={errors.country ? invalidStyle : {}}
            inputRef={register({ required: true })}
            className="country"
            name="country"
            id="country"
            labelText={intl.formatMessage({
              id: "User.RegistrationSelectCountry",
              defaultMessage: "Select country",
            })}
            helperText={intl.formatMessage({
              id: "User.RegistrationSelectCountryHelper",
              defaultMessage: "Select your country",
            })}
            defaultValue={userData.country}
          >
            <SelectItem
              value=""
              text={intl.formatMessage({
                id: "User.RegistrationSelectCountry",
                defaultMessage: "Select country",
              })}
            />
            {countries &&
              countries.map((country) => (
                <SelectItem
                  key={country.iso2}
                  value={country.iso2}
                  text={intl.formatMessage({
                    id: `User.RegistrationSelectCountry.${country.iso2}`,
                    defaultMessage: `${country.name}`,
                  })}
                />
              ))}
          </Select>

          <Select
            style={errors.language ? invalidStyle : {}}
            inputRef={register({ required: true })}
            className="custom_language"
            name="custom_language"
            id="custom_language"
            labelText={intl.formatMessage({
              id: "User.RegistrationSelectLanguage",
              defaultMessage: "Select language",
            })}
            helperText={intl.formatMessage({
              id: "User.RegistrationSelectLanguageHelper",
              defaultMessage: "Select your language",
            })}
            defaultValue={userData.country}
          >
            <SelectItem
              value=""
              text={intl.formatMessage({
                id: "User.RegistrationSelectLanguage",
                defaultMessage: "Select language",
              })}
            />
            {languagesOptions.map((custom_language) => (
              <SelectItem
                key={custom_language.value}
                value={custom_language.value}
                text={intl.formatMessage({
                  id: `User.RegistrationSelectLanguage.${custom_language.label}`,
                  defaultMessage: `${custom_language.label}`,
                })}
              />
            ))}
          </Select>

          {selectedCountry && adminHighest && (
            <Select
              style={errors.adminHighest ? invalidStyle : {}}
              inputRef={register({ required: true })}
              className="adminHighest"
              name="adminHighest"
              id="adminHighest"
              labelText={labelText(adminHighest.name)}
              helperText={helperText(adminHighest.name)}
              defaultValue={userData.adminHighest}
            >
              <SelectItem value="" text={labelText(adminHighest.name)} />
              {schoolAdmins[adminHighest.level] &&
                schoolAdmins[adminHighest.level].map((item) => (
                  <SelectItem
                    key={item.id}
                    value={item.id}
                    text={intl.formatMessage({
                      id: `User.RegistrationSelectCountry.${item.id}`,
                      defaultMessage: `${item.name}`,
                    })}
                  />
                ))}
            </Select>
          )}
        </div>
        <div className={styles.locationWrapper}>
          {selectedCountry && adminLowest && (
            <Select
              style={errors.adminLowest ? invalidStyle : {}}
              inputRef={register({ required: true })}
              className="adminLowest"
              name="adminLowest"
              id="adminLowest"
              labelText={labelText(adminLowest.name)}
              helperText={helperText(adminLowest.name)}
              disabled={!selectedAdminHighest}
              defaultValue={userData.adminLowest}
            >
              <SelectItem value="" text={labelText(adminLowest.name)} />
              {schoolAdmins[adminLowest.level] &&
                schoolAdmins[adminLowest.level].map((item) => (
                  <SelectItem
                    key={item.id}
                    value={item.id}
                    text={intl.formatMessage({
                      id: `User.RegistrationSelectSchool.${item.id}`,
                      defaultMessage: `${item.name}`,
                    })}
                  />
                ))}
            </Select>
          )}
          {selectedCountry && Object.keys(countryAdminLevels).length !== 0 && (
            <Select
              style={errors.school ? invalidStyle : {}}
              inputRef={register({ required: true })}
              className="school"
              name="school"
              id="school"
              labelText={intl.formatMessage({
                id: "User.RegistrationSelectSchool",
                defaultMessage: "Select school",
              })}
              helperText={intl.formatMessage({
                id: "User.RegistrationSelectSchoolHelper",
                defaultMessage: "Select school according to your country",
              })}
              disabled={!selectedAdminLowest}
              defaultValue={userData.school}
            >
              <SelectItem
                value=""
                text={intl.formatMessage({
                  id: "User.RegistrationSelectSchool",
                  defaultMessage: "Select school",
                })}
              />
              {registrationSchools &&
                registrationSchools.map((school) => (
                  <SelectItem
                    key={school.id}
                    value={school.id}
                    text={intl.formatMessage({
                      id: `User.RegistrationSelectSchool.${school.id}`,
                      defaultMessage: `${school.name}`,
                    })}
                  />
                ))}
            </Select>
          )}
        </div>
        {selectedCountry && (
          <RadioButtonGroup
            className={errors.role ? styles.invalidRoleStyle : ""}
            labelText={intl.formatMessage({
              id: "User.RegistrationSelectRole",
              defaultMessage: "Select role",
            })}
            name="role"
          >
            {userRoles.map((level) => (
              <RadioButton
                key={level.value}
                labelText={level.label}
                id={`role-${level.value}`}
                name="role"
                value={level.value}
                defaultChecked={level.default}
                ref={register({ required: true })}
              />
            ))}
          </RadioButtonGroup>
        )}
        {submitErrorMessage && (
          <p className={styles.submitErrorMessage}>{submitErrorMessage}</p>
        )}
        <div className={styles.submitWrapper}>
          <div className={styles.button}>
            <Button type="submit" onClick={handleSubmit(onSubmit)}>
              <FormattedMessage
                id="User.RegistrationSubmit"
                defaultMessage="Submit registration"
              />
            </Button>
            {fetching && <InlineLoading />}
          </div>
        </div>
      </form>
    </div>
  );
};

export default SchoolStaffRegistrationForm;
