import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";

import { Controller, FormProvider, useForm } from "react-hook-form";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import {
  Button,
  ModuleBody,
  ModuleHeader,
  Wrapper,
  Module,
  TextInput,
} from "@wfp/ui";

import { getSelectedCountry } from "data-handler/ducks/countryProfile";
import {
  getRolesModule,
  postCountryRoleMapping,
} from "data-handler/ducks/roles";
import { generateCustomRoleList } from "helpers/roles";

import { CountryAdminLabel } from "SCConstants";

import "./_content.scss";
import { getLanguage } from "data-handler/ducks/language";
import { getCurrentUser } from "data-handler/ducks/auth";

const CountryAdminRolesEdit = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedCountry = useSelector(getSelectedCountry);
  const language = useSelector(getLanguage);
  const { roles, countryRoles } = useSelector(getRolesModule);
  const currentUser = useSelector(getCurrentUser);

  const methods = useForm({
    defaultValues: { mappings: [] },
  });
  const { handleSubmit, control } = methods;

  const data = roles
    ? generateCustomRoleList(
        roles,
        countryRoles,
        language,
        currentUser?.is_admin
      )
    : [];

  const onSubmit = (values) => {
    /**
     * [
     *  {
     *    role (ID FK): String | Int,
     *    custom_name: string,
     *    country (ID FK): String | Int
     *  },
     * ]
     */
    const submitValues = Object.keys(values).reduce((acc, value) => {
      if (!values[value] && values[value] !== "") {
        return acc;
      }
      const id = parseInt(value);
      const found = acc.find((a) => a.role === id);

      const custom_name = values[value] || undefined;

      if (!found) {
        const object = {
          role: id,
          custom_name: custom_name,
          country: selectedCountry.id,
        };
        acc.push(object);
        return acc;
      }
      if (found.custom_name || found.custom_name === "") {
        found.custom_name = custom_name;
      }
      return acc;
    }, []);

    dispatch(
      postCountryRoleMapping({
        data: submitValues,
        country: selectedCountry.id,
      })
    );
    history.push("/countryAdmin/roles");
  };
  return (
    <>
      <Wrapper
        pageWidth="sm"
        spacing="md"
        mobilePageWidth="lg"
        background="lighter"
        className="table"
      >
        <Module>
          <ModuleHeader>
            <Link
              exact="true"
              to={"/countryAdmin/roles"}
              style={{ paddingRight: "10px" }}
            >
              <FontAwesomeIcon icon={faChevronLeft} size="lg" />
            </Link>
            <FormattedMessage
              id="rolesEdit.RolesEdit"
              defaultMessage="Roles edit"
            />
          </ModuleHeader>
          <ModuleBody>
            <FormProvider {...methods}>
              <form className="levels-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="header-wrapper">
                  <label className="header-groups" htmlFor={"header-groups"}>
                    {
                      CountryAdminLabel.find(
                        (item) => item.name === "countryAdmin.roles"
                      ).label
                    }
                  </label>
                  <label className="header-levels" htmlFor={"header-levels"}>
                    {
                      CountryAdminLabel.find(
                        (item) => item.name === "countryAdmin.customName"
                      ).label
                    }
                  </label>
                </div>
                {data.map(({ role, id, custom_name }) => (
                  <div className="levels-wrapping">
                    <div className="select-groups">{role}</div>
                    <div className="select-levels">
                      <Controller
                        as={<TextInput defaultValue={custom_name} />}
                        name={`${id}`}
                        control={control}
                      />
                    </div>
                  </div>
                ))}
                <div className="save-button-container">
                  <Button type="submit" disabled={false}>
                    <FormattedMessage
                      id="SchoolLevelsMappingEdit.submitButton"
                      defaultMessage="Save"
                    />
                  </Button>
                </div>
              </form>
            </FormProvider>
          </ModuleBody>
        </Module>
      </Wrapper>
    </>
  );
};

export default CountryAdminRolesEdit;
